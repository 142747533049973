const bm = {
    // Header
    'home': `laman utama`,
    'testimonial': `testimoni`,
    'products': `produk`,
    'workshops': `bengkel`,
    'team': `pasukan`,
    'contact_us': `hubungi kami`,

    // Footer
    'connect_with_us': `Berhubung dengan Kami`,
    'terms_conditions': `Terma & Syarat`,
    'refund_policy': `Polisi Bayaran Balik`,
    'privacy_policy': `Dasar Privasi`,

    /* Home */
    'xtenz': `XTENZ`,
    
    //  Section 1
    's1_title': `Apa Itu`,
    's1_quote': `Designed to Go Further`,
    's1_quote_ext': `XTENZ melindungi anda daripada pembaikan kereta yang tidak dijangka`,
    's1_desc': `Membeli kereta adalah peristiwa penting orang dewasa dan komitmen kewangan utama bagi kebanyakan keluarga. Jangan biarkan kerosakan yang tidak dijangka menjejaskan belanjawan anda. Dengan XTENZ, perlindungan mampu milik boleh dicapai. Diasaskan oleh usahawan muda yang bersemangat, XTENZ memenuhi keperluan pemilik kereta dan bengkel automotif. Liputan dan sokongan komprehensif kami mendekatkan pengguna dan bengkel. Sama ada anda mempunyai kereta baharu atau recon, kami mempunyai rancangan untuk anda. Tingkatkan jaminan kereta anda dengan XTENZ hari ini.`,
    
    //  Section 2
    's2_title': `Kenapa Memilih`,
    's2_why_title_01': `100 Panel Seluruh Negara`,
    's2_why_descr_01': `Di XTENZ, kami mempunyai lebih daripada 100 bengkel panel yang terletak di bandar-bandar seluruh Malaysia.`,
    's2_why_title_02': `Had Tuntutan sehingga RM 120,000*`,
    's2_why_descr_02': `Biarkan kereta anda dilindungi walaupun ia telah bersama anda selama lebih daripada satu dekad*. Enjin, transmisi, kegagalan penghawa dingin dan banyak lagi tidak lagi menjadi mimpi ngeri terbesar anda.`,
    's2_why_title_03': `Tuntutan Terjamin dalam 72 Jam`,
    's2_why_descr_03': `Jangkakan proses tuntutan yang mudah daripada kami untuk memastikan anda bebas daripada kerumitan.`,
    's2_why_title_04': `Operator Hotline 24 Jam`,
    's2_why_descr_04': `Bantuan kami dengan proses tuntutan kenderaan anda sentiasa bersedia. Biarkan talian hotline 24 jam kami menyelesaikan pertanyaan anda.`,
    's2_why_title_05': `Tiada Pemeriksaan Kenderaan Diperlukan*`,
    's2_why_descr_05': `Tanpa pemeriksaan kenderaan diperlukan, kereta anda layak menuntut sehingga RM120,000 tepat pada masa anda memerlukannya.`,
    's2_why_title_06': `Waranti Perlindungan 100% Berfungsi pada Barangan Kelas-Tinggi*`,
    's2_why_descr_06': `Semua jenis kenderaan, sehingga 15 tahun / 180,000 km penggunaan.`,
    
    //  Section 3
    's3_title_01': `Mengapa anda memerlukan`,
    's3_title_02': `Waranti Lanjutan`,
    's3_title_03': `untuk`,
    's3_title_04': `Kereta Anda ?`,
    's3_why_01': `Memudahkan Perancangan Belanjawan Individu atau Keluarga`,
    's3_why_02': `Pembaikan & Sokongan yang Lancar - Mempunyai Hubungan yang Tepat di Hujung Jari Anda`,
    's3_why_03': `Perlindungan Jangka Panjang untuk Penggunaan Kereta Jangka Panjang`,
    
    //  Section 4
    's4_title_01': `Bagaimana`,
    's4_title_02': `Berfungsi ?`,
    's4_step': `Langkah`,
    's4_step_title_01': `Hubungi Pejabat Tuntutan`,
    's4_step_descr_01': `+6 010-256 0706 or +6 010-265 0706`,
    's4_step_title_02': `Hantar Kenderaan Anda ke Bengkel Berdekatan`,
    's4_step_descr_02': `-`,
    's4_step_title_03': `Kenalpasti Pembaikan Diperlukan & Dapatkan Sebutharga`,
    's4_step_descr_03': `-`,
    's4_step_title_04': `Hubungi Pejabat Tuntutan untuk Menuntut Kos Pembaikan`,
    's4_step_descr_04': `Lampirkan petikan dan gambar kenderaan atau/dan bahagian kenderaan anda.`,
    's4_step_title_05': `Pengesahan oleh Pejabat Tuntutan`,
    's4_step_descr_05': `Pembayaran dalam 72 jam`,

    //  Section 5
    's5_title': `Apa yang Pelanggan Kami Katakan Tentang Kami`,
    's5_desc': `Baca apa yang pelanggan kami katakan tentang produk dan perkhidmatan kami.`,
    
    //  Section 6
    's6_title': `Pakej Waranti`,
    's6_learn_more': `Lebih Lanjut`,
    's6_buy': `Beli`,

    //  Section 7
    's7_title_01': `Temui Pasukan`,
    's7_title_02': `Kami`,
    's7_achievements': `Pencapaian`,
    's7_founder': `Pengasas`,
    's7_co_founder': `Pengasas Bersama`,
    's7_ambassador': `Duta`,

    //  Section 8
    's8_title_01': `Hubungi`,
    's8_title_02': `Hari Ini`,
    's8_desc': `Penyelesaian jaminan kereta yang komprehensif hanya beberapa langkah lagi.`,
    's8_name': `Nama`,
    's8_email': `E-mel`,
    's8_message': `Mesej`,
    's8_send': `Hantar`,
    's8_name_error': `Sila isikan nama anda`,
    's8_email_error': `Sila isikan e-mel yang sah`,
    's8_message_error': `Sila isi mesej anda`,
    /* Home */

    //  Workshops
    'find_workshops': `Cari Bengkel`,

    //  Product
    'product_title': `Pakej Waranti`,
    'product_buy': `Beli`,

    //  Promotion
    'promotion_title': `Promosi Terkini`,

}

export default bm