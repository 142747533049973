import React from 'react'
import { createStyles, Container, Text, Space, rem, Grid, Center, Button, Box, Divider, List} from '@mantine/core'
// import { Link } from 'react-router-dom'

import IconVehicleModal from '../assets/icons/IconVehicleModel.webp'
import IconEligibility from '../assets/icons/IconEligibility.webp'
import IconVehicleInspection from '../assets/icons/IconVehicleInspection.webp'
import IconPanelWorkshop from '../assets/icons/IconPanelWorkshop.webp'
import IconWarrantyPeriod from '../assets/icons/IconWarrantyPeriod.webp'

import IconEngine from '../assets/icons/IconEngine.webp'
import IconTransmission from '../assets/icons/IconTransmission.webp'
import IconECU from '../assets/icons/IconECU.webp'
import IconTCM from '../assets/icons/IconTCM.webp'
import IconAirCondSystem from '../assets/icons/IconAirCondSystem.webp'
import IconTurbo from '../assets/icons/IconTurbo.webp'

import { HEADER_HEIGHT } from '../utils/Contants';

import { TranslateText as t } from '../components/Localization/translations'

const useStyles = createStyles((theme) => ({

    root: {
        backgroundColor: '#E6E6EF',
        minHeight: `calc(100svh - ${rem(HEADER_HEIGHT)})`,
    },

    planNameContainer: {
        backgroundColor: 'white', padding: 10, borderRadius: 100, boxShadow: '0px 0px 4px 2px rgba(0, 0, 0, 0.25)'
    },

    icon: {
        width: 60,
        height: 60,
        objectFit: 'contain',
    },

    planWrapper: {
        display: 'flex', height: '100%', flexDirection: 'column', justifyContent: 'space-between'
    },

}));

export default function Product() {
    const { classes, } = useStyles();

    const onClickBuy = (plan) => {
        window.open(`/register-warranty?plan=${plan}`,'_blank')
    }

    return (
        <div className={classes.root}>
            <Container size={'xl'}>
                <Space h={50}/>
                <Text sx={{fontFamily: 'Magistral', fontWeight: 700, color: '#3547F8'}} size={40} align='center'>{t('product_title')}</Text>
                <Space h={50}/>
                <Grid>
                    <Grid.Col span={12} xs={12} sm={6} md={6} lg={3}>
                        <div className={classes.planWrapper}>
                            <div>
                                <div className={classes.planNameContainer}>
                                    <Text size={24} align='center' sx={{fontFamily: 'Magistral', fontWeight: 700, color: '#808081'}}>Silver Plan 249</Text>
                                </div>
                                <Space h={50}/>
                                <Center>
                                    <Box w={235}>
                                        <Text size={16} fw={700} c={'#00145B'} align='center'>Unbeatable Value to Stay Protected</Text>
                                    </Box>
                                </Center>
                                <Space h={30}/>
                                <Text size={40} fw={700} c={'#174AFF'} align='center'>RM249<span style={{fontSize: 16}}> / year</span></Text>
                                <Space h={30}/>
                                <Center>
                                    <Box w={120}>
                                        <Button onClick={()=>onClickBuy('silver')} color='brandLBlue.0' sx={{ '&:hover': { backgroundColor: '#174AFF', color: '#FFFFFF', transition: 'all 0.2s ease' }}} variant="outline" radius="xl" size="md" fullWidth>{t('product_buy')}</Button>
                                    </Box>
                                </Center>
                                <Space h={50}/>
                                <Text size={16} c={'#00145B'} align='center'>Warranty Period</Text>
                                <Text size={18} c={'#00145B'} fw={700} align='center'>1 Year or 15,000km*</Text>
                                <Divider my={50} color='#BEBFC7'/>
                                <Text size={18} c={'#00145B'} fw={700} align='center'>RM 2,500</Text>
                                <Text size={16} c={'#00145B'} align='center'>Per Claim</Text>
                                <Space h={50}/>
                                <Text size={18} c={'#00145B'} fw={700} align='center'>-</Text>
                                <Text size={16} c={'#00145B'} align='center'>Per Claim Labour Fee</Text>
                                <Space h={50}/>
                                <Text size={18} c={'#00145B'} fw={700} align='center'>RM 40,000</Text>
                                <Text size={16} c={'#00145B'} align='center'>Maximum Claim Limit</Text>
                                <Divider my={50} color='#BEBFC7'/>
                                <Center>
                                    <img src={IconVehicleModal} className={classes.icon} alt='Vehicle Modal'/>
                                </Center>
                                <Space h={20}/>
                                <Text size={16} c={'#00145B'} align='center'>Up to Max</Text>
                                <Text size={20} c={'#00145B'} fw={700} align='center'>15 Years</Text>
                                <Space h={30}/>
                                <Text size={12} c={'#00145B'} fw={700} align='center'>All Car Models</Text>
                                <Text size={12} c={'#00145B'} lh={'normal'} align='center'>(Except: Hyundai Starex, Ford Ranger, Renault, Peugeot, Citroen, Chevrolet, Jaguar & Super Car)</Text>
                                <Space h={50}/>
                                <Center>
                                    <img src={IconEligibility} className={classes.icon} alt='Eligibility'/>
                                </Center>
                                <Space h={20}/>
                                <Text size={12} c={'#00145B'} align='center'>Mileage Reading Below</Text>
                                <Text size={18} c={'#00145B'} fw={700} align='center'>180,000km</Text>
                                <Space h={50}/>
                                <Center>
                                    <img src={IconVehicleInspection} className={classes.icon} alt='Vehicle Inspection'/>
                                </Center>
                                <Space h={20}/>
                                <Text size={18} c={'#00145B'} fw={700} align='center'>Vehicle Inspection Needed</Text>
                                <Space h={50}/>
                                <Center>
                                    <img src={IconPanelWorkshop} className={classes.icon} alt='Panel / Workshop'/>
                                </Center>
                                <Space h={20}/>
                                <Text size={18} c={'#00145B'} fw={700} align='center'>Open / Panel Workshops</Text>
                                <Space h={50}/>
                                <Center>
                                    <img src={IconWarrantyPeriod} className={classes.icon} alt='Cooling-off Period'/>
                                </Center>
                                <Space h={20}/>
                                <Text size={18} c={'#00145B'} fw={700} align='center'>Cooling-off Period</Text>
                                <Text size={12} c={'#00145B'} align='center'>30 Days and 400km from the effective date of the warranty cover</Text>
                                <Divider my={50} color='#BEBFC7'/>
                                <div style={{paddingLeft: 40}}>
                                    <img src={IconEngine} className={classes.icon} alt='Engine'/>
                                    <Space h={20}/>
                                    <Text size={16} c={'#00145B'} fw={700}>Engine</Text>
                                    <List size={12} style={{color:'#00145B'}}>
                                        <List.Item>Engine Block</List.Item>
                                        <List.Item>Oil Pump</List.Item>
                                        <List.Item>Tappets (Valve Lifter)</List.Item>
                                        <List.Item>Cylinder Heads</List.Item>
                                        <List.Item>Rocker Arm</List.Item>
                                        <List.Item>Camshaft Intake</List.Item>
                                        <List.Item>Camshaft Exhaust</List.Item>
                                        <List.Item>Crankshaft</List.Item>
                                        <List.Item>Valve Tronic Motor</List.Item>
                                        <List.Item>Eccentric Shaft</List.Item>
                                        <List.Item>Piston</List.Item>
                                        <List.Item>Variable Cam Timing</List.Item>
                                        <List.Item>Engine Vacuum Pump</List.Item>
                                        <List.Item>Connectng Rod</List.Item>
                                    </List>
                                    <Space h={50}/>
                                    <img src={IconTransmission} className={classes.icon} alt='Transmission'/>
                                    <Space h={20}/>
                                    <Text size={16} c={'#00145B'} fw={700}>Transmission</Text>
                                    <List size={12} style={{color:'#00145B'}}>
                                        <List.Item>All Type Of Solenoid Valve</List.Item>
                                        <List.Item>Gear Oil Pump</List.Item>
                                        <List.Item>Torque Converter</List.Item>
                                        <List.Item>Gear Set</List.Item>
                                        <List.Item>Gear Shaft</List.Item>
                                        <List.Item>Mechatronic</List.Item>
                                        <List.Item>Valve Body</List.Item>
                                    </List>
                                    <Space h={50}/>
                                    <img src={IconECU} className={classes.icon} alt='ECU'/>
                                    <Space h={20}/>
                                    <Text size={16} c={'#00145B'} fw={700}>ECU</Text>
                                    <List size={12} style={{color:'#00145B'}}>
                                        <List.Item>Engine Control Unit</List.Item>
                                    </List>
                                    <Space h={50}/>
                                    <img src={IconTCM} className={classes.icon} alt='TCM'/>
                                    <Space h={20}/>
                                    <Text size={16} c={'#00145B'} fw={700}>TCM</Text>
                                    <List size={12} style={{color:'#00145B'}}>
                                        <List.Item>Transmission Control Module</List.Item>
                                    </List>
                                    <Space h={50}/>
                                    <img src={IconAirCondSystem} className={classes.icon} alt='Air-Cond System'/>
                                    <Space h={20}/>
                                    <Text size={16} c={'#00145B'} fw={700}>Air-Cond System</Text>
                                    <List size={12} style={{color:'#00145B'}}>
                                        <List.Item>Air Cond Compressor</List.Item>
                                        <List.Item>Blower Motor</List.Item>
                                        <List.Item>Air Cond Motor</List.Item>
                                    </List>
                                </div>
                            </div>
                            <div >
                                <Divider my={50} color='#BEBFC7'/>
                                <Center>
                                    <Box w={120}>
                                        <Button onClick={()=>onClickBuy('silver')} color='brandLBlue.0' sx={{ '&:hover': { backgroundColor: '#174AFF', color: '#FFFFFF', transition: 'all 0.2s ease' }}} variant="outline" radius="xl" size="md" fullWidth>{t('product_buy')}</Button>
                                    </Box>
                                </Center>
                                <Space h={50}/>
                            </div>
                        </div>
                    </Grid.Col>
                    <Grid.Col span={12} xs={12} sm={6} md={6} lg={3}>
                        <div className={classes.planWrapper}>
                            <div>
                                <div className={classes.planNameContainer}>
                                    <Text size={24} align='center' sx={{fontFamily: 'Magistral', fontWeight: 700, color: '#A38739'}}>Gold Plan 399</Text>
                                </div>
                                <Space h={50}/>
                                <Center>
                                    <Box w={235}>
                                        <Text size={16} fw={700} c={'#00145B'} align='center'>Enhanced Coverage at Great Value</Text>
                                    </Box>
                                </Center>
                                <Space h={30}/>
                                <Text size={40} fw={700} c={'#174AFF'} align='center'>RM399<span style={{fontSize: 16}}> / year</span></Text>
                                <Space h={30}/>
                                <Center>
                                    <Box w={120}>
                                        <Button onClick={()=>onClickBuy('gold')} color='brandLBlue.0' sx={{ '&:hover': { backgroundColor: '#174AFF', color: '#FFFFFF', transition: 'all 0.2s ease' }}} variant="outline" radius="xl" size="md" fullWidth>{t('product_buy')}</Button>
                                    </Box>
                                </Center>
                                <Space h={50}/>
                                <Text size={16} c={'#00145B'} align='center'>Warranty Period</Text>
                                <Text size={18} c={'#00145B'} fw={700} align='center'>1 Year or 15,000km*</Text>
                                <Divider my={50} color='#BEBFC7'/>
                                <Text size={18} c={'#00145B'} fw={700} align='center'>RM 4,000</Text>
                                <Text size={16} c={'#00145B'} align='center'>Per Claim</Text>
                                <Space h={50}/>
                                <Text size={18} c={'#00145B'} fw={700} align='center'>-</Text>
                                <Text size={16} c={'#00145B'} align='center'>Per Claim Labour Fee</Text>
                                <Space h={50}/>
                                <Text size={18} c={'#00145B'} fw={700} align='center'>RM 70,000</Text>
                                <Text size={16} c={'#00145B'} align='center'>Maximum Claim Limit</Text>
                                <Divider my={50} color='#BEBFC7'/>
                                <Center>
                                    <img src={IconVehicleModal} className={classes.icon} alt='Vehicle Modal'/>
                                </Center>
                                <Space h={20}/>
                                <Text size={16} c={'#00145B'} align='center'>Up to Max</Text>
                                <Text size={20} c={'#00145B'} fw={700} align='center'>15 Years</Text>
                                <Space h={30}/>
                                <Text size={12} c={'#00145B'} fw={700} align='center'>All Car Models</Text>
                                <Text size={12} c={'#00145B'} lh={'normal'} align='center'>(Except: Hyundai Starex, Ford Ranger, Renault, Peugeot, Citroen, Chevrolet, Jaguar & Super Car)</Text>
                                <Space h={50}/>
                                <Center>
                                    <img src={IconEligibility} className={classes.icon} alt='Eligibility'/>
                                </Center>
                                <Space h={20}/>
                                <Text size={12} c={'#00145B'} align='center'>Mileage Reading Below</Text>
                                <Text size={18} c={'#00145B'} fw={700} align='center'>180,000km</Text>
                                <Space h={50}/>
                                <Center>
                                    <img src={IconVehicleInspection} className={classes.icon} alt='Vehicle Inspection'/>
                                </Center>
                                <Space h={20}/>
                                <Text size={18} c={'#00145B'} fw={700} align='center'>Vehicle Inspection Needed</Text>
                                <Space h={50}/>
                                <Center>
                                    <img src={IconPanelWorkshop} className={classes.icon} alt='Panel / Workshop'/>
                                </Center>
                                <Space h={20}/>
                                <Text size={18} c={'#00145B'} fw={700} align='center'>Open / Panel Workshops</Text>
                                <Space h={50}/>
                                <Center>
                                    <img src={IconWarrantyPeriod} className={classes.icon} alt='Cooling-off Period'/>
                                </Center>
                                <Space h={20}/>
                                <Text size={18} c={'#00145B'} fw={700} align='center'>Cooling-off Period</Text>
                                <Text size={12} c={'#00145B'} align='center'>30 Days and 400km from the effective date of the warranty cover</Text>
                                <Divider my={50} color='#BEBFC7'/>
                                <div style={{paddingLeft: 40}}>
                                    <img src={IconEngine} className={classes.icon} alt='Engine'/>
                                    <Space h={20}/>
                                    <Text size={16} c={'#00145B'} fw={700}>Engine</Text>
                                    <List size={12} style={{color:'#00145B'}}>
                                        <List.Item>Engine Block</List.Item>
                                        <List.Item>Oil Pump</List.Item>
                                        <List.Item>Tappets (Valve Lifter)</List.Item>
                                        <List.Item>Cylinder Heads</List.Item>
                                        <List.Item>Rocker Arm</List.Item>
                                        <List.Item>Camshaft Intake</List.Item>
                                        <List.Item>Camshaft Exhaust</List.Item>
                                        <List.Item>Crankshaft</List.Item>
                                        <List.Item>Valve Tronic Motor</List.Item>
                                        <List.Item>Eccentric Shaft</List.Item>
                                        <List.Item>Piston</List.Item>
                                        <List.Item>Variable Cam Timing</List.Item>
                                        <List.Item>Engine Vacuum Pump</List.Item>
                                        <List.Item>Connectng Rod</List.Item>
                                    </List>
                                    <Space h={50}/>
                                    <img src={IconTransmission} className={classes.icon} alt='Transmission'/>
                                    <Space h={20}/>
                                    <Text size={16} c={'#00145B'} fw={700}>Transmission</Text>
                                    <List size={12} style={{color:'#00145B'}}>
                                        <List.Item>All Type Of Solenoid Valve</List.Item>
                                        <List.Item>Gear Oil Pump</List.Item>
                                        <List.Item>Torque Converter</List.Item>
                                        <List.Item>Gear Set</List.Item>
                                        <List.Item>Gear Shaft</List.Item>
                                        <List.Item>Mechatronic</List.Item>
                                        <List.Item>Valve Body</List.Item>
                                    </List>
                                    <Space h={50}/>
                                    <img src={IconECU} className={classes.icon} alt='ECU'/>
                                    <Space h={20}/>
                                    <Text size={16} c={'#00145B'} fw={700}>ECU</Text>
                                    <List size={12} style={{color:'#00145B'}}>
                                        <List.Item>Engine Control Unit</List.Item>
                                    </List>
                                    <Space h={50}/>
                                    <img src={IconTCM} className={classes.icon} alt='TCM'/>
                                    <Space h={20}/>
                                    <Text size={16} c={'#00145B'} fw={700}>TCM</Text>
                                    <List size={12} style={{color:'#00145B'}}>
                                        <List.Item>Transmission Control Module</List.Item>
                                    </List>
                                    <Space h={50}/>
                                    <img src={IconAirCondSystem} className={classes.icon} alt='Air-Cond System'/>
                                    <Space h={20}/>
                                    <Text size={16} c={'#00145B'} fw={700}>Air-Cond System</Text>
                                    <List size={12} style={{color:'#00145B'}}>
                                        <List.Item>Air Cond Compressor</List.Item>
                                        <List.Item>Blower Motor</List.Item>
                                        <List.Item>Air Cond Motor</List.Item>
                                    </List>
                                </div>
                            </div>
                            <div >
                                <Divider my={50} color='#BEBFC7'/>
                                <Center>
                                    <Box w={120}>
                                        <Button onClick={()=>onClickBuy('gold')} color='brandLBlue.0' sx={{ '&:hover': { backgroundColor: '#174AFF', color: '#FFFFFF', transition: 'all 0.2s ease' }}} variant="outline" radius="xl" size="md" fullWidth>{t('product_buy')}</Button>
                                    </Box>
                                </Center>
                                <Space h={50}/>
                            </div>
                        </div>
                    </Grid.Col>
                    <Grid.Col span={12} xs={12} sm={6} md={6} lg={3}>
                        <div className={classes.planWrapper}>
                            <div>
                                <div className={classes.planNameContainer}>
                                    <Text size={24} align='center' sx={{fontFamily: 'Magistral', fontWeight: 700, color: '#7E4FD5'}}>Platinum Plan 699</Text>
                                </div>
                                <Space h={50}/>
                                <Center>
                                    <Box w={235}>
                                        <Text size={16} fw={700} c={'#00145B'} align='center'>Higher Assurance for Better Peace of Mind</Text>
                                    </Box>
                                </Center>
                                <Space h={30}/>
                                <Text size={40} fw={700} c={'#174AFF'} align='center'>RM699<span style={{fontSize: 16}}> / year</span></Text>
                                <Space h={30}/>
                                <Center>
                                    <Box w={120}>
                                        <Button onClick={()=>onClickBuy('platinum')} color='brandLBlue.0' sx={{ '&:hover': { backgroundColor: '#174AFF', color: '#FFFFFF', transition: 'all 0.2s ease' }}} variant="outline" radius="xl" size="md" fullWidth>{t('product_buy')}</Button>
                                    </Box>
                                </Center>
                                <Space h={50}/>
                                <Text size={16} c={'#00145B'} align='center'>Warranty Period</Text>
                                <Text size={18} c={'#00145B'} fw={700} align='center'>1 Year or 15,000km*</Text>
                                <Divider my={50} color='#BEBFC7'/>
                                <Text size={18} c={'#00145B'} fw={700} align='center'>RM 5,000</Text>
                                <Text size={16} c={'#00145B'} align='center'>Per Claim</Text>
                                <Space h={50}/>
                                <Text size={18} c={'#00145B'} fw={700} align='center'>RM 500</Text>
                                <Text size={16} c={'#00145B'} align='center'>Per Claim Labour Fee</Text>
                                <Space h={50}/>
                                <Text size={18} c={'#00145B'} fw={700} align='center'>RM 80,000</Text>
                                <Text size={16} c={'#00145B'} align='center'>Maximum Claim Limit</Text>
                                <Divider my={50} color='#BEBFC7'/>
                                <Center>
                                    <img src={IconVehicleModal} className={classes.icon} alt='Vehicle Modal'/>
                                </Center>
                                <Space h={20}/>
                                <Text size={16} c={'#00145B'} align='center'>Up to Max</Text>
                                <Text size={20} c={'#00145B'} fw={700} align='center'>12 Years</Text>
                                <Space h={30}/>
                                <Text size={12} c={'#00145B'} fw={700} align='center'>All Local, Japanese & Korean Cars</Text>
                                <Text size={12} c={'#00145B'} lh={'normal'} align='center'>(Except: Hyundai Starex, Ford Ranger)<br/><span style={{color:'#E6E6EF'}}>-</span></Text>
                                <Space h={50}/>
                                <Center>
                                    <img src={IconEligibility} className={classes.icon} alt='Eligibility'/>
                                </Center>
                                <Space h={20}/>
                                <Text size={12} c={'#00145B'} align='center'>Mileage Reading Below</Text>
                                <Text size={18} c={'#00145B'} fw={700} align='center'>180,000km</Text>
                                <Space h={50}/>
                                <Center>
                                    <img src={IconVehicleInspection} className={classes.icon} alt='Vehicle Inspection'/>
                                </Center>
                                <Space h={20}/>
                                <Text size={18} c={'#00145B'} fw={700} align='center'>Vehicle Inspection Needed</Text>
                                <Space h={50}/>
                                <Center>
                                    <img src={IconPanelWorkshop} className={classes.icon} alt='Panel / Workshop'/>
                                </Center>
                                <Space h={20}/>
                                <Text size={18} c={'#00145B'} fw={700} align='center'>Open / Panel Workshops</Text>
                                <Space h={50}/>
                                <Center>
                                    <img src={IconWarrantyPeriod} className={classes.icon} alt='Cooling-off Period'/>
                                </Center>
                                <Space h={20}/>
                                <Text size={18} c={'#00145B'} fw={700} align='center'>Cooling-off Period</Text>
                                <Text size={12} c={'#00145B'} align='center'>30 Days and 400km from the effective date of the warranty cover</Text>
                                <Divider my={50} color='#BEBFC7'/>
                                <div style={{paddingLeft: 40}}>
                                    <img src={IconEngine} className={classes.icon} alt='Engine'/>
                                    <Space h={20}/>
                                    <Text size={16} c={'#00145B'} fw={700}>Engine</Text>
                                    <List size={12} style={{color:'#00145B'}}>
                                        <List.Item>Engine Block</List.Item>
                                        <List.Item>Oil Pump</List.Item>
                                        <List.Item>Tappets (Valve Lifter)</List.Item>
                                        <List.Item>Cylinder Heads</List.Item>
                                        <List.Item>Rocker Arm</List.Item>
                                        <List.Item>Camshaft Intake</List.Item>
                                        <List.Item>Camshaft Exhaust</List.Item>
                                        <List.Item>Crankshaft</List.Item>
                                        <List.Item>Valve Tronic Motor</List.Item>
                                        <List.Item>Eccentric Shaft</List.Item>
                                        <List.Item>Piston</List.Item>
                                        <List.Item>Variable Cam Timing</List.Item>
                                        <List.Item>Engine Vacuum Pump</List.Item>
                                        <List.Item>Connectng Rod</List.Item>
                                    </List>
                                    <Space h={50}/>
                                    <img src={IconTransmission} className={classes.icon} alt='Transmission'/>
                                    <Space h={20}/>
                                    <Text size={16} c={'#00145B'} fw={700}>Transmission</Text>
                                    <List size={12} style={{color:'#00145B'}}>
                                        <List.Item>All Type Of Solenoid Valve</List.Item>
                                        <List.Item>Gear Oil Pump</List.Item>
                                        <List.Item>Torque Converter</List.Item>
                                        <List.Item>Gear Set</List.Item>
                                        <List.Item>Gear Shaft</List.Item>
                                        <List.Item>Mechatronic</List.Item>
                                        <List.Item>Valve Body</List.Item>
                                    </List>
                                    <Space h={50}/>
                                    <img src={IconECU} className={classes.icon} alt='ECU'/>
                                    <Space h={20}/>
                                    <Text size={16} c={'#00145B'} fw={700}>ECU</Text>
                                    <List size={12} style={{color:'#00145B'}}>
                                        <List.Item>Engine Control Unit</List.Item>
                                    </List>
                                    <Space h={50}/>
                                    <img src={IconTCM} className={classes.icon} alt='TCM'/>
                                    <Space h={20}/>
                                    <Text size={16} c={'#00145B'} fw={700}>TCM</Text>
                                    <List size={12} style={{color:'#00145B'}}>
                                        <List.Item>Transmission Control Module</List.Item>
                                    </List>
                                    <Space h={50}/>
                                    <img src={IconAirCondSystem} className={classes.icon} alt='Air-Cond System'/>
                                    <Space h={20}/>
                                    <Text size={16} c={'#00145B'} fw={700}>Air-Cond System</Text>
                                    <List size={12} style={{color:'#00145B'}}>
                                        <List.Item>Air Cond Compressor</List.Item>
                                        <List.Item>Blower Motor</List.Item>
                                        <List.Item>Air Cond Motor</List.Item>
                                    </List>
                                    <Space h={50}/>
                                    <img src={IconTurbo} className={classes.icon} alt='Turbo'/>
                                    <Space h={20}/>
                                    <Text size={16} c={'#00145B'} fw={700}>Turbo</Text>
                                    <List size={12} style={{color:'#00145B'}}>
                                        <List.Item>Control Air</List.Item>
                                        <List.Item>Bearing Shaft</List.Item>
                                        <List.Item>Turbine</List.Item>
                                        <List.Item>Pneumatic Control Cylinder</List.Item>
                                    </List>
                                </div>
                            </div>
                            <div>
                                <Divider my={50} color='#BEBFC7'/>
                                <Center>
                                    <Box w={120}>
                                        <Button onClick={()=>onClickBuy('platinum')} color='brandLBlue.0' sx={{ '&:hover': { backgroundColor: '#174AFF', color: '#FFFFFF', transition: 'all 0.2s ease' }}} variant="outline" radius="xl" size="md" fullWidth>{t('product_buy')}</Button>
                                    </Box>
                                </Center>
                                <Space h={50}/>
                            </div>
                        </div>
                    </Grid.Col>
                    <Grid.Col span={12} xs={12} sm={6} md={6} lg={3}>
                        <div className={classes.planWrapper}>
                            <div>
                                <div className={classes.planNameContainer}>
                                    <Text size={24} align='center' sx={{fontFamily: 'Magistral', fontWeight: 700, color: '#78297E'}}>Diamond Plan 899</Text>
                                </div>
                                <Space h={50}/>
                                <Center>
                                    <Box w={235}>
                                        <Text size={16} fw={700} c={'#00145B'} align='center'>Superior Coverage for Total Security</Text>
                                    </Box>
                                </Center>
                                <Space h={30}/>
                                <Text size={40} fw={700} c={'#174AFF'} align='center'>RM899<span style={{fontSize: 16}}> / year</span></Text>
                                <Space h={30}/>
                                <Center>
                                    <Box w={120}>
                                        <Button onClick={()=>onClickBuy('diamond')} color='brandLBlue.0' sx={{ '&:hover': { backgroundColor: '#174AFF', color: '#FFFFFF', transition: 'all 0.2s ease' }}} variant="outline" radius="xl" size="md" fullWidth>{t('product_buy')}</Button>
                                    </Box>
                                </Center>
                                <Space h={50}/>
                                <Text size={16} c={'#00145B'} align='center'>Warranty Period</Text>
                                <Text size={18} c={'#00145B'} fw={700} align='center'>1 Year or 15,000km*</Text>
                                <Divider my={50} color='#BEBFC7'/>
                                <Text size={18} c={'#00145B'} fw={700} align='center'>RM 5,000</Text>
                                <Text size={16} c={'#00145B'} align='center'>Per Claim</Text>
                                <Space h={50}/>
                                <Text size={18} c={'#00145B'} fw={700} align='center'>RM 500</Text>
                                <Text size={16} c={'#00145B'} align='center'>Per Claim Labour Fee</Text>
                                <Space h={50}/>
                                <Text size={18} c={'#00145B'} fw={700} align='center'>RM 90,000</Text>
                                <Text size={16} c={'#00145B'} align='center'>Maximum Claim Limit</Text>
                                <Divider my={50} color='#BEBFC7'/>
                                <Center>
                                    <img src={IconVehicleModal} className={classes.icon} alt='Vehicle Modal'/>
                                </Center>
                                <Space h={20}/>
                                <Text size={16} c={'#00145B'} align='center'>Up to Max</Text>
                                <Text size={20} c={'#00145B'} fw={700} align='center'>12 Years</Text>
                                <Space h={30}/>
                                <Text size={12} c={'#00145B'} fw={700} align='center'>All Continental, Asia Car Models</Text>
                                <Text size={12} c={'#00145B'} lh={'normal'} align='center'>(Except: Ford Ranger, Renault, Peugeot, Citroen, Chevrolet, Jaguar & Super Car)</Text>
                                <Space h={50}/>
                                <Center>
                                    <img src={IconEligibility} className={classes.icon} alt='Eligibility'/>
                                </Center>
                                <Space h={20}/>
                                <Text size={12} c={'#00145B'} align='center'>Mileage Reading Below</Text>
                                <Text size={18} c={'#00145B'} fw={700} align='center'>180,000km</Text>
                                <Space h={50}/>
                                <Center>
                                    <img src={IconVehicleInspection} className={classes.icon} alt='Vehicle Inspection'/>
                                </Center>
                                <Space h={20}/>
                                <Text size={18} c={'#00145B'} fw={700} align='center'>Vehicle Inspection Needed</Text>
                                <Space h={50}/>
                                <Center>
                                    <img src={IconPanelWorkshop} className={classes.icon} alt='Panel / Workshop'/>
                                </Center>
                                <Space h={20}/>
                                <Text size={18} c={'#00145B'} fw={700} align='center'>Open / Panel Workshops</Text>
                                <Space h={50}/>
                                <Center>
                                    <img src={IconWarrantyPeriod} className={classes.icon} alt='Cooling-off Period'/>
                                </Center>
                                <Space h={20}/>
                                <Text size={18} c={'#00145B'} fw={700} align='center'>Cooling-off Period</Text>
                                <Text size={12} c={'#00145B'} align='center'>30 Days and 400km from the effective date of the warranty cover</Text>
                                <Divider my={50} color='#BEBFC7'/>
                                <div style={{paddingLeft: 40}}>
                                    <img src={IconEngine} className={classes.icon} alt='Engine'/>
                                    <Space h={20}/>
                                    <Text size={16} c={'#00145B'} fw={700}>Engine</Text>
                                    <List size={12} style={{color:'#00145B'}}>
                                        <List.Item>Engine Block</List.Item>
                                        <List.Item>Oil Pump</List.Item>
                                        <List.Item>Tappets (Valve Lifter)</List.Item>
                                        <List.Item>Cylinder Heads</List.Item>
                                        <List.Item>Rocker Arm</List.Item>
                                        <List.Item>Camshaft Intake</List.Item>
                                        <List.Item>Camshaft Exhaust</List.Item>
                                        <List.Item>Crankshaft</List.Item>
                                        <List.Item>Valve Tronic Motor</List.Item>
                                        <List.Item>Eccentric Shaft</List.Item>
                                        <List.Item>Piston</List.Item>
                                        <List.Item>Variable Cam Timing</List.Item>
                                        <List.Item>Engine Vacuum Pump</List.Item>
                                        <List.Item>Connectng Rod</List.Item>
                                    </List>
                                    <Space h={50}/>
                                    <img src={IconTransmission} className={classes.icon} alt='Transmission'/>
                                    <Space h={20}/>
                                    <Text size={16} c={'#00145B'} fw={700}>Transmission</Text>
                                    <List size={12} style={{color:'#00145B'}}>
                                        <List.Item>All Type Of Solenoid Valve</List.Item>
                                        <List.Item>Gear Oil Pump</List.Item>
                                        <List.Item>Torque Converter</List.Item>
                                        <List.Item>Gear Set</List.Item>
                                        <List.Item>Gear Shaft</List.Item>
                                        <List.Item>Mechatronic</List.Item>
                                        <List.Item>Valve Body</List.Item>
                                    </List>
                                    <Space h={50}/>
                                    <img src={IconECU} className={classes.icon} alt='ECU'/>
                                    <Space h={20}/>
                                    <Text size={16} c={'#00145B'} fw={700}>ECU</Text>
                                    <List size={12} style={{color:'#00145B'}}>
                                        <List.Item>Engine Control Unit</List.Item>
                                    </List>
                                    <Space h={50}/>
                                    <img src={IconTCM} className={classes.icon} alt='TCM'/>
                                    <Space h={20}/>
                                    <Text size={16} c={'#00145B'} fw={700}>TCM</Text>
                                    <List size={12} style={{color:'#00145B'}}>
                                        <List.Item>Transmission Control Module</List.Item>
                                    </List>
                                    <Space h={50}/>
                                    <img src={IconAirCondSystem} className={classes.icon} alt='Air-Cond System'/>
                                    <Space h={20}/>
                                    <Text size={16} c={'#00145B'} fw={700}>Air-Cond System</Text>
                                    <List size={12} style={{color:'#00145B'}}>
                                        <List.Item>Air Cond Compressor</List.Item>
                                        <List.Item>Blower Motor</List.Item>
                                        <List.Item>Air Cond Motor</List.Item>
                                    </List>
                                    <Space h={50}/>
                                    <img src={IconTurbo} className={classes.icon} alt='Turbo'/>
                                    <Space h={20}/>
                                    <Text size={16} c={'#00145B'} fw={700}>Turbo</Text>
                                    <List size={12} style={{color:'#00145B'}}>
                                        <List.Item>Control Air</List.Item>
                                        <List.Item>Bearing Shaft</List.Item>
                                        <List.Item>Turbine</List.Item>
                                        <List.Item>Pneumatic Control Cylinder</List.Item>
                                    </List>
                                </div>
                            </div>
                            <div>
                                <Divider my={50} color='#BEBFC7'/>
                                <Center>
                                    <Box w={120}>
                                        <Button onClick={()=>onClickBuy('diamond')} color='brandLBlue.0' sx={{ '&:hover': { backgroundColor: '#174AFF', color: '#FFFFFF', transition: 'all 0.2s ease' }}} variant="outline" radius="xl" size="md" fullWidth>{t('product_buy')}</Button>
                                    </Box>
                                </Center>
                                <Space h={50}/>
                            </div>
                        </div>
                    </Grid.Col>
                </Grid>
                <div style={{backgroundColor: '#F0F0F9', padding: 14, paddingLeft: 24, paddingRight: 24, borderRadius: 10}}>
                    <Text size={14} c={'#00145B'} fw={700}>Remarks</Text>
                    <List size={12} style={{color:'#00145B'}}>
                        <List.Item>Whichever shall come first.*</List.Item>
                        <List.Item>T&C applied.</List.Item>
                        <List.Item>Promotion package</List.Item>
                        <List.Item>All warranty coverage are subject to cooling-off period started days from date issued before claim process.</List.Item>
                    </List>
                </div>
                <Space h={50}/>
            </Container>
        </div>
    )
}
