import { createStyles, Title, Text, Button, Container, Center, Box, Space } from '@mantine/core';
import { useNavigate } from 'react-router-dom';

const useStyles = createStyles((theme) => ({
    root: {
        display: 'table',
        position: 'absolute',
        top: 0,
        left: 0,
        height: '100%',
        width: '100%',
        backgroundColor: '#E6E6EF',
    },

    wrapper: {
        display: 'table-cell', verticalAlign: 'middle'
    },

    label: {
        textAlign: 'center',
        fontWeight: 900,
        fontSize: 220,
        lineHeight: 1,
        marginBottom: theme.spacing.xl * 1.5,
        color: '#80FB41',

        [theme.fn.smallerThan('sm')]: {
        fontSize: 120,
        },
    },

    title: {
        fontFamily: `Greycliff CF, ${theme.fontFamily}`,
        textAlign: 'center',
        fontWeight: 900,
        fontSize: 38,
        color: '#001458',

        [theme.fn.smallerThan('sm')]: {
        fontSize: 32,
        },
    },

    description: {
        maxWidth: 500,
        margin: 'auto',
        marginTop: theme.spacing.xl,
        marginBottom: theme.spacing.xl * 1.5,
    },
}));

export default function NotFound() {
    const { classes } = useStyles();
    const navigate = useNavigate();

    return (
        <div className={classes.root}>
            <div className={classes.wrapper}>
                <Container>
                    <div className={classes.label}>404</div>
                    <Title className={classes.title}>You have found a secret place.</Title>
                    <Text color="dimmed" size="lg" align="center" className={classes.description}>
                        Unfortunately, this is only a 404 page. You may have mistyped the address, or the page has been moved to another URL.
                    </Text>
                    <Space h={50}/>
                    <Center>
                        <Box w={170}>
                            <Button onClick={()=>navigate('/')} sx={{ backgroundColor: '#001458', color: '#FFFFFF', '&:hover': { backgroundColor: '#001458',color: '#80FB41', transition: 'all 0.2s ease' }}} radius="xl" size="md" fullWidth>Back to Home</Button>
                        </Box>
                    </Center>
                </Container>
            </div>
        </div>
    );
}