import React from 'react'
import { useOutletContext } from "react-router-dom";

import Section01 from '../components/Home/Section01';
import Section02 from '../components/Home/Section02';
import Section03 from '../components/Home/Section03';
import Section04 from '../components/Home/Section04';
import Section05 from '../components/Home/Section05';
import Section06 from '../components/Home/Section06';
import Section07 from '../components/Home/Section07';
import Section08 from '../components/Home/Section08';

export default function Home() {
    const [homeScrollIntoView, reviewScrollIntoView, productsScrollIntoView, teamScrollIntoView, contactScrollIntoView] = useOutletContext();

  return (
    <div>
        {/* Who is XTENZ */}
        <Section01 targetRef={homeScrollIntoView.targetRef}/>
        {/* Why Choose XTENZ */}
        <Section02 />
        {/* Carousel */}
        <Section03 />
        {/* How XTENZ Works */}
        <Section04 />
        {/* Review */}
        <Section05 targetRef={reviewScrollIntoView.targetRef}/>
        {/* Warranty Packages */}
        <Section06 targetRef={productsScrollIntoView.targetRef}/>
        {/* Meet Our Team */}
        <Section07 targetRef={teamScrollIntoView.targetRef}/>
        {/* Contact Us - NOT MOBILE RESPONSIVE YET */}
        <Section08 targetRef={contactScrollIntoView.targetRef}/>
    </div>
  )
}
