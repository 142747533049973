import { useState } from 'react'
import {
    TextInput,
    Paper,
    Container,
    Group,
    Button,
    Stepper,
    Flex,
    Text,
    Box,
    Loader,
    Center,
    Alert,
    Image,
    Select,
    SegmentedControl,
    Grid
} from '@mantine/core';
import { useForm } from '@mantine/form'
// import Logo from '../assets/Logo.png';
import { IconCrown, IconAlertCircle, IconBrandWhatsapp } from '@tabler/icons-react';
import axios from '../../api/axios';

import { useNavigate } from 'react-router-dom';
import { useQuery, useMutation } from 'react-query';

import ConfirmationModal from '../../components/General/ConfirmationModal';
import usePaymentIDs from '../../components/Hooks/usePaymentIDs';
import TermsConditionsModal from '../../components/Register/TermsConditionsModal';

import LogoTagline from '../../assets/logo/LogoTagline.png';
import BSNPackage from '../../assets/images/BSNPackage.png'

import { WA_URL, BSN_PAYMENT } from '../../utils/Contants';

export default function RegisterBSN() {

    const { setPaymentIDs } = usePaymentIDs();



    const [openCModal, setOpenCModal] = useState(false);
    const [openDModal, setOpenDModal] = useState(false);
    const [active, setActive] = useState(0);
    const nextStep = () => setActive((current) => (current < 3 ? current + 1 : current));
    const prevStep = () => setActive((current) => (current > 0 ? current - 1 : current));

    const [plan, setPlan] = useState({
        name: 'BSN Special Promotion 299',
        plan_id: '0ec7921e-731f-48b1-8a06-4d1a675b0f11',
        plan_color: '#00a5ad',
        plan_color_light: '#00a5ad',
    });

    const [userDetails, setUserDetails] = useState(null);

    const [message, setMessage] = useState(null);

    let navigate = useNavigate();

    // const planDetails = useQuery(
    //     "planDetails",
    //     async ({signal}) => (await axios.get("/plan/get-all-plans", {signal})).data.plans,
    //     {
    //         initialData: [],
    //     }
    // );


    const registerMutation = useMutation(
        (data) => axios.post(`/web-be/register`, data),
        {
            onSuccess: (res) => {
                // console.log(res.data)
                //set local storage
                //setPaymentIds(res.data.uid, res.data.vehicle_id)
                setPaymentIDs({uid: res.data.uid, vehicle_id: res.data.vehicle_id, type: BSN_PAYMENT})
                navigate("/payment-bsn")
            },
            onError: (error) => {
                // console.log(error)
                if (error.response) {
                    // prevStep()
                    // The request was made and the server responded with a status code
                    // that falls out of the range of 2xx
                    if (error.response.status !== 0) {
                        if(error.response.data.message !== undefined){
                            setMessage(error.response.data.message);
                        }
                        else{
                            setMessage('Something went wrong while connecting to the server');
                        }
                    }
                    else {
                        setMessage('Something went wrong while connecting to the server');
                    }
                } else {
                    setMessage('Something went wrong while connecting to the server');
                }
            },
        }
    );

    const onNextClick = async (value) => {
        if(active === 0){
            nextStep()
        }
        if(active === 1){
            setUserDetails(value)
            setOpenCModal(true)
        }
        if(active === 2){
            setOpenCModal(true)
        }
    };

    const onDisagreeClick = () => {
        setOpenDModal(true)
    }

    const onSubmit = async () => {
        setOpenCModal(false)
        // console.log(userDetails)
        const formData = new FormData();
        formData.append('fullname', userDetails.fullname.trim());
        formData.append('nric', userDetails.nric)
        formData.append('email', userDetails.email.trim())
        formData.append('phone_number', userDetails.phone_number.trim())
        formData.append('address_line_1', userDetails.address_line_1.trim())
        formData.append('address_line_2', userDetails.address_line_2.trim())
        formData.append('postal_code', userDetails.postal_code.trim())
        formData.append('city', userDetails.city.trim())
        formData.append('state', userDetails.state.trim())
        
        formData.append('plateNumber', userDetails.plateNumber.trim())
        formData.append('plan_id', plan.plan_id)
        formData.append('isMyVehicle', userDetails.isMyVehicle === 'yes' ? 1 : 0)

        // console.log(submitData)
        registerMutation.mutate(formData)
    }

    const form = useForm({
        initialValues: { fullname: '', nric: '',  phone_number: '', email: '', plateNumber: '', address_line_1: '', address_line_2: '', postal_code: '', city: '', state: '', isMyVehicle: 'yes'},
    
        validate: {
            fullname: (value) => (value.trim().length < 1 ? 'Please fill in the empty field' : null),
            nric: (value) => (value.trim().length < 1 ? 'Please fill in the empty field' : value.trim().length < 12 ? 'Invalid Identification Card Number' : !/^\d*$/.test(value.trim()) ? 'Invalid Identification Card Number' : null),
            email: (value) => (value.trim().length < 1 ? 'Please fill in the empty field' : /^\S+@\S+$/.test(value.trim()) ? null : 'Invalid Email Address'),
            phone_number: (value) => (value.trim().length < 1 ? 'Please fill in the empty field' : value.trim().length < 9 ? 'Invalid Phone Number' : !/^\d*$/.test(value.trim()) ? 'Invalid Phone Number' : null),
            plateNumber: (value) => (value.trim().length < 1 ? 'Please fill in the empty field' : null),
            address_line_1: (value) => (value.trim().length < 1 ? 'Please fill in the empty field' : null),
            postal_code: (value) => (value.trim().length < 1 ? 'Please fill in the empty field' : null),
            city: (value) => (value.trim().length < 1 ? 'Please fill in the empty field' : null),
            state: (value) => (value.trim().length < 1 ? 'Please fill in the empty field' : null),
        },
    });

  return (
    <>
    {/*Delete Confirmation Modal */}
    <TermsConditionsModal opened={openCModal} onClose={() => setOpenCModal(false)} approveBtnText={'Proceed'} onClick={onSubmit} />
    {/*Disagree Confirmation Modal */}
    <ConfirmationModal opened={openDModal} onClose={() => setOpenDModal(false)} text="Are you sure? You cannot purchase warranty without agreeing to our terms and conditions" approveBtnText={'Yes'}  onClick={() => setOpenDModal(false)} />
    <a href={WA_URL} target="_blank" rel="noreferrer" style={{
        position: 'fixed',
        width: '50px',
        height: '50px',
        bottom: 15,
        right: 15,
        backgroundColor: '#1ad03f',
        borderRadius: '50px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        boxShadow: '2px 2px 3px #999',
        zIndex: 9999,
    }}>
        <IconBrandWhatsapp size={32} color={'#fff'}/>
    </a>
    <div style={{display: 'table',
                position: 'absolute',
                top: 0,
                left: 0,
                height: '100%',
                width: '100%'}}>
        <div style={{display: 'table-cell', verticalAlign: 'middle'}}>
            <Container size={700} my={40}>
                <div align="center">
                    <img src={LogoTagline} alt="Logo" width={350}/>
                </div>
                <Paper withBorder shadow="md" p={30} mt={30} >
                    <Stepper color="#54FF00" active={active} onStepClick={setActive} allowNextStepsSelect={false} breakpoint="sm" size="sm">
                        <Stepper.Step label="Warranty" description="Choose a Plan" allowStepSelect={false}>

                                <Text fw={500} pt={10}> Choose a plan </Text>
                                <Box
                                    // key={data?.id}
                                    sx={(theme) => ({
                                        // backgroundColor: data?.plan === 'silver' ? "#A7B3BF" : data?.plan === 'gold' ? '#FDB83F' : data?.plan === 'platinum' ? '#3C317F' : '#A7B3BF',
                                        // padding: theme.spacing.xl,
                                        // cursor: 'pointer',
                                        marginTop: 10,
                                        boxShadow: theme.shadows.xs,
                                        borderStyle: 'solid',
                                        borderRadius: 10,
                                        borderWidth:  4,
                                        borderColor: '#54FF00',
                                        overflow: 'hidden'
                                        // maxWidth: 500,
                                    })} 
                                    // onClick={() => setPlan(data?.plan)}
                                >
                                    <Image src={BSNPackage}/>
                                </Box>
                                <Group position="center">
                                    <Button onClick={nextStep} mt="xl" color='XTENZBlue'
                                    // disabled={planDetails.isError || planDetails.isFetching}
                                    > Next </Button>
                                </Group>
                        </Stepper.Step>
                        
                        <Stepper.Step loading={registerMutation.isLoading} label="Registration" description="Enter Details" allowStepSelect={false}>
                            <form onSubmit={form.onSubmit((values) => onNextClick(values))}> 
                                <Box sx={(theme) => ({
                                    position: 'relative',
                                    backgroundColor: plan.plan_color,
                                    padding: theme.spacing.xs,
                                    paddingLeft: theme.spacing.md,
                                    paddingRight: theme.spacing.md,
                                    borderRadius: theme.radius.sm,
                                    marginBottom: 10,
                                    marginTop: 10,
                                })}>
                                    <Flex justify="space-between">
                                        <Text fw={500} color="#FFFFFF" tt="capitalize" fz="lg">Selected Plan</Text>
                                        <Text fw={500} color="#FFFFFF" tt="capitalize" fz="lg">{plan.name}</Text>
                                    </Flex>
                                </Box>
                                {message && 
                                    <Alert mt={10} mb={10} icon={<IconAlertCircle size={16} />} title="Error" color="red" withCloseButton onClose={() => setMessage('')}>
                                        {message}
                                    </Alert>
                                }
                                <Text fw={500} pt={10}> Personal Details </Text>
                                <Text fw={500} pt={10} size='sm'> Did you purchase this warranty for your own vehicle? </Text>
                                <Grid>
                                    <Grid.Col xs={6} sm={6} md={4} lg={4} xl={4} pb={0}>
                                        <SegmentedControl
                                        color='XTENZBlue'
                                        // size='sm'
                                        data={[
                                            { label: 'Yes', value: 'yes' },
                                            { label: 'No', value: 'no' },
                                        ]}
                                        {...form.getInputProps('isMyVehicle')}
                                        fullWidth
                                        />
                                    </Grid.Col>
                                    <Grid.Col sm={6} md={6} lg={6} xl={6} pt={0}></Grid.Col>
                                </Grid>
                                
                                <TextInput mt={10} label="Full Name" description="As Per NRIC"  {...form.getInputProps('fullname')} />
                                <TextInput mt="xs" label="NRIC" description="Eg: 990101141919" maxLength={12} {...form.getInputProps('nric')} />
                                <TextInput mt="xs" label="Email"  {...form.getInputProps('email')} />
                                <TextInput mt="xs" label="Phone Number" description="Eg: +60 193328617" maxLength={10} {...form.getInputProps('phone_number')} icon={<Text size={14} sx={(theme) => ({color: theme.colorScheme === 'light' ? '#000' : '#FFF'})}>+60</Text>}/>
                                <TextInput mt="xs" label="Car Plate Number" description="Eg: WVW1234" {...form.getInputProps('plateNumber')} />
                                <Text fw={500} pt={20}> Shipping Address </Text>
                                <TextInput mt={10} label="Address Line 1"  {...form.getInputProps('address_line_1')} />
                                <TextInput mt="xs" label="Address Line 2" placeholder="Optional" {...form.getInputProps('address_line_2')} />
                                <Grid>
                                    <Grid.Col sm={6} md={6} lg={6} xl={6} pb={0}>
                                        <TextInput mt="xs" label="Post Code" maxLength={5} {...form.getInputProps('postal_code')} />
                                    </Grid.Col>
                                    <Grid.Col sm={6} md={6} lg={6} xl={6} >
                                        <TextInput mt="xs" label="City" {...form.getInputProps('city')} />
                                    </Grid.Col>
                                </Grid>
                                <Select
                                    mt="xs"
                                    label="State"
                                    placeholder="Pick one"
                                    data={[
                                        { value: 'Johor', label: 'Johor' },
                                        { value: 'Kedah', label: 'Kedah' },
                                        { value: 'Kelantan', label: 'Kelantan' },
                                        { value: 'Kuala Lumpur', label: 'Kuala Lumpur' },
                                        { value: 'Labuan', label: 'Labuan' },
                                        { value: 'Malacca', label: 'Malacca' },
                                        { value: 'Negeri Sembilan', label: 'Negeri Sembilan' },
                                        { value: 'Pahang', label: 'Pahang' },
                                        { value: 'Penang', label: 'Penang' },
                                        { value: 'Perak', label: 'Perak' },
                                        { value: 'Perlis', label: 'Perlis' },
                                        { value: 'Putrajaya', label: 'Putrajaya' },
                                        { value: 'Sabah', label: 'Sabah' },
                                        { value: 'Sarawak', label: 'Sarawak' },
                                        { value: 'Selangor', label: 'Selangor' },
                                        { value: 'Terengganu', label: 'Terengganu' },
                                    ]} {...form.getInputProps('state')}
                                />
                                 
                                {/* <PasswordInput mt={40} label="Password"  {...form.getInputProps('password')} />
                                <PasswordInput mt="xs" mb="sm" label="Re-enter Password"  {...form.getInputProps('confirm_password')} />  */}
                                <Group position="center">
                                    <Button onClick={prevStep} mt="xl" color='gray' disabled={registerMutation.isLoading}> Back </Button>
                                    <Button type="submit" mt="xl" disabled={registerMutation.isLoading} color='XTENZBlue'> Submit Details </Button>
                                </Group>
                            </form>
                        </Stepper.Step>
                        {/*
                        <Stepper.Step loading={registerMutation.isLoading} label="Agreement" description="Terms & Condition" allowStepSelect={false}>
                            <TypographyStylesProvider>
                                <div dangerouslySetInnerHTML={{__html: OVERVIEW + "</br>" + TERMS_AND_CONDITIONS}}/>
                            </TypographyStylesProvider>
                            <Group position="center">
                                <Button onClick={prevStep} mt="xl" disabled={registerMutation.isLoading} color='gray'> Back </Button>
                                <Button onClick={onDisagreeClick} mt="xl" disabled={registerMutation.isLoading} color='dark'> Disagree </Button>
                                <Button onClick={onNextClick} mt="xl" loading={registerMutation.isLoading}> Agree & Proceed </Button>
                            </Group>
                        </Stepper.Step>
                        */}
                        <Stepper.Step label="Payment" description="Payment Method" allowStepSelect={false}></Stepper.Step>
                    </Stepper>    
                </Paper>
            </Container>
        </div>
    </div>
    </>
  );
}
