import { useState, useEffect } from 'react'
import {
    Paper,
    Container,
    Stepper,
    Text,
    Center,
    Box,
    Grid,
    Flex,
    Alert,
    LoadingOverlay,
    Button,
    Select,
    Group,
    Image
  } from '@mantine/core';
import LogoTagline from '../../assets/logo/LogoTagline.png'
import { useSearchParams } from "react-router-dom"
import { IconAlertCircle, IconExclamationMark, IconBrandWhatsapp, IconCheck, IconInfoCircle } from "@tabler/icons-react";

import axios from '../../api/axios';
import usePaymentIDs from '../../components/Hooks/usePaymentIDs';
import { PaymentSelectItem } from '../../components/Register/PaymentSelectItem';

import { WA_URL, APP_STORE_URL, PLAY_STORE_URL } from '../../utils/Contants';

import IconGooglePlay from '../../assets/icons/IconGooglePlay.png'
import IconAppStore from '../../assets/icons/IconAppStore.png'

// const PAYMENT_METHOD = [
//     {
//         label: 'Card Payment',
//         image: require('../../assets/payment/payment-credit.jpg'),
//         channel: 'credit',
//     },
//     {
//         label: 'M2U',
//         image: require('../../assets/payment/payment-m2u.jpg'),
//         channel: 'maybank2u',
//     },
//     {
//         label: 'Hong Leong',
//         image: require('../../assets/payment/payment-hlb.jpg'),
//         channel: 'hlb',
//     },
//     {
//         label: 'CIMB',
//         image: require('../../assets/payment/payment-cimb.jpg'),
//         channel: 'cimbclicks',
//     },
//     {
//         label: 'RHB',
//         image: require('../../assets/payment/payment-rhb.jpg'),
//         channel: 'rhb',
//     },
//     {
//         label: 'Affin',
//         image: require('../../assets/payment/payment-affin.jpg'),
//         channel: 'affinonline',
//     },
//     {
//         label: 'AmOnline',
//         image: require('../../assets/payment/payment-amonline.jpg'),
//         channel: 'amb',
//     },
//     {
//         label: 'Bank Islam',
//         image: require('../../assets/payment/payment-bank-islam.jpg'),
//         channel: 'bankislam',
//     },
//     {
//         label: 'OCBC',
//         image: require('../../assets/payment/payment-ocbc.jpg'),
//         channel: 'FPX_OCBC',
//     },
//     {
//         label: 'Standard Chartered',
//         image: require('../../assets/payment/payment-standard-chartered.jpg'),
//         channel: 'FPX_SCB',
//     },
//     {
//         label: '7 Eleven',
//         image: require('../../assets/payment/payment-7e.jpg'),
//         channel: 'cash-711',
//     },
// ]

const FPX_BANK = [
    {
        label: 'Maybank2U',
        value: 'fpx_mb2u',
    },
    {
        label: 'CIMB Clicks',
        value: 'fpx_cimbclicks',
    },
    {
        label: 'Public Bank',
        value: 'fpx_pbb',
    },
    {
        label: 'RHB Now',
        value: 'fpx_rhb',
    },
    {
        label: 'AmBank',
        value: 'fpx_amb',
    },
    {
        label: 'myBSN',
        value: 'fpx_bsn',
    },
    {
        label: 'Bank Rakyat',
        value: 'fpx_bkrm',
    },
    {
        label: 'UOB',
        value: 'fpx_uob',
    },
    {
        label: 'Affin Bank',
        value: 'fpx_abb',
    },
    {
        label: 'Bank Islam',
        value: 'fpx_bimb',
    },
    {
        label: 'HSBC Online',
        value: 'fpx_hsbc',
    },
    {
        label: 'Standard Chartered Bank',
        value: 'fpx_scb',
    },
    {
        label: 'Kuwait Finance House',
        value: 'fpx_kfh',
    },
    {
        label: 'Bank Mualamat',
        value: 'fpx_bmmb',
    },
    {
        label: 'OCBC Online',
        value: 'fpx_ocbc',
    },
    {
        label: 'Alliance Bank',
        value: 'fpx_abmb',
    },
    {
        label: 'Hong Leong',
        value: 'fpx_hlb',
    },
]

export default function PaymentPazerPay() {
    const [searchParams] = useSearchParams();
    const { paymentIDs } = usePaymentIDs();
    const [active, setActive] = useState(2);
    const [paymentDetails, setPaymentDetails] = useState(null);
    const [message, setMessage] = useState(null);
    const [isError, setIsError] = useState(false)
    const [showRedirect, setShowRedirect] = useState(searchParams.get("redirect"))

    const [paymentMethod, setPaymentMethod] = useState('creditAN')
    const [paymentChannel, setPaymentChannel] = useState('creditAN')

    const uid = paymentIDs?.uid;
    const vehicle_id = paymentIDs?.vehicle_id

    useEffect(() => {
        if(paymentMethod !== 'fpx'){
            setPaymentChannel(paymentMethod)
        }
        else{
            setPaymentChannel('')
        }
    }, [paymentMethod])

    useEffect(() => {
        let isMounted = true;
        
        const getPaymentDetails = async () => {
            try{
                const response = await axios.post("/payment/get-payment-details",{uid, vehicle_id})
                
                setPaymentDetails(response.data.paymentDetails)
                setMessage(null)
            }
            catch(error){
                console.error(error);
                setIsError(true);
                if (error.response) {
                    // The request was made and the server responded with a status code
                    // that falls out of the range of 2xx
                    if (error.response.status !== 0) {
                        if(error.response.data.message !== undefined){
                            setMessage(error.response.data.message);
                        }
                        else{
                            setMessage('Something went wrong while connecting to the server');
                        }
                    }
                    else {
                        setMessage('Something went wrong while connecting to the server');
                    }
                } else {
                    setMessage('Something went wrong while connecting to the server');
                }
            }
        }
        if(isMounted && uid && vehicle_id)
            getPaymentDetails()

        return () => isMounted = false
    }, [uid,vehicle_id])

    useEffect(() => {
        if(!isError && paymentDetails){
            if(paymentDetails?.isPaid){
                setMessage('Payment has been made, if you want renew your car warranty please renew in XTENZ Warranty App.')
            }
        }
    }, [isError, paymentDetails, uid, vehicle_id])

  return (
    <>
        <a href={WA_URL} target="_blank" rel="noreferrer" style={{
            position: 'fixed',
            width: '50px',
            height: '50px',
            bottom: 15,
            right: 15,
            backgroundColor: '#1ad03f',
            borderRadius: '50px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            boxShadow: '2px 2px 3px #999',
            zIndex: 9999,
        }}>
            <IconBrandWhatsapp size={32} color={'#fff'}/>
        </a>
        <div style={{display: 'table',
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    height: '100%',
                    width: '100%'}}>
            <div style={{display: 'table-cell', verticalAlign: 'middle'}}>
                <Container size={700} my={40}>
                    <div align="center">
                        <img src={LogoTagline} alt="Logo" width={350}/>
                    </div>
                    <Paper withBorder shadow="md" p={30} mt={30} >
                        <Stepper color="#54FF00" active={active} onStepClick={setActive} allowNextStepsSelect={false} breakpoint="sm" size="sm">
                            <Stepper.Step label="Warranty" description="Choose a Plan" allowStepSelect={false}> </Stepper.Step>
                            <Stepper.Step label="Registration" description="Enter Details" allowStepSelect={false}> </Stepper.Step>
                            {/*<Stepper.Step label="Agreement" description="Terms & Condition" allowStepSelect={false}></Stepper.Step>*/}
                            <Stepper.Step label="Payment" description="Payment Method" allowStepSelect={false}>
                                {showRedirect && !paymentDetails?.isPaid ?
                                    <Alert mt={10} mb={10} icon={<IconInfoCircle size={22} />} title="Info" color="yellow" withCloseButton onClose={() => setShowRedirect(false)}>
                                        If you have paid, we are currently processing your payment. 
                                        Please contact us if your payment status is not updated within 30 minutes after payment. 
                                        You can refresh this page from time to time to check your payment status.
                                        If you have not made the payment, please ignore this message.
                                    </Alert>
                                : null
                                }
                                {message && isError ?
                                    <Alert mt={10} mb={10} icon={<IconAlertCircle size={22} />} title="Error" color="red" withCloseButton onClose={() => setMessage('')}>
                                        {message}
                                    </Alert>
                                : message && !isError ?
                                    <Alert mt={10} mb={10} icon={<IconCheck size={22} />} title="Paid" color="green" withCloseButton onClose={() => setMessage('')}>
                                        {message}
                                    </Alert>
                                :   null
                                }
                                {!paymentDetails?.isPaid && !isError ? <>
                                <Text fw={500} mb={10}> Payment Details </Text>
                                <div style={{ position: 'relative' }}>
                                    <LoadingOverlay visible={!paymentDetails} overlayBlur={2} loader={isError && <IconExclamationMark size={32} color="red"/>}/>
                                    <Box sx={(theme) => ({
                                        position: 'relative',
                                        backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.gray[0],
                                        padding: theme.spacing.md,
                                        borderRadius: theme.radius.sm,
                                        marginBottom: 20,
                                    })}>
                                        <Grid gutter="xs">
                                            <Grid.Col span={4}><Text fw={500} >Full Name:</Text></Grid.Col>
                                            <Grid.Col span={8}><Text fw={500} >{paymentDetails?.fullname}</Text></Grid.Col>
                                        </Grid>
                                    </Box>
                                    <Box sx={(theme) => ({
                                        position: 'relative',
                                        backgroundColor: paymentDetails?.planColor,
                                        padding: theme.spacing.xs,
                                        paddingLeft: theme.spacing.md,
                                        paddingRight: theme.spacing.md,
                                        borderRadius: theme.radius.sm,
                                        marginBottom: 20,
                                    })}>
                                        <Flex justify="space-between" pb='xs'>
                                            <Text fw={500} color="#FFFFFF" tt="capitalize" fz="lg">Plan</Text>
                                            <Text fw={500} color="#FFFFFF" tt="capitalize" fz="lg">{paymentDetails?.plan}</Text>
                                        </Flex>
                                        <Flex justify="space-between">
                                            <Text fw={500} color="#FFFFFF" tt="capitalize" fz="lg">Price</Text>
                                            <Text fw={500} color="#FFFFFF" fz="lg">RM {paymentDetails?.grossPrice.toFixed(2)}</Text>
                                        </Flex>
                                        <Flex justify="space-between">
                                            <Text fw={500} color="#FFFFFF" tt="capitalize" fz="lg">SST ({paymentDetails?.SST*100}%)</Text>
                                            <Text fw={500} color="#FFFFFF" fz="lg">RM {paymentDetails?.SSTPrice.toFixed(2)}</Text>
                                        </Flex>
                                        <Flex justify="space-between">
                                            <Text fw={500} color="#FFFFFF" tt="capitalize" fz="lg">Total Price</Text>
                                            <Text fw={500} color="#FFFFFF" fz="lg">RM {paymentDetails?.netPrice.toFixed(2)}</Text>
                                        </Flex>
                                    </Box>
                                </div>
                                <Text fw={500} mb={20}> Choose a Payment Method </Text>
                                <Select
                                    label="Payment Method"
                                    placeholder="Select Payment Method"
                                    itemComponent={PaymentSelectItem}
                                    value={paymentMethod} onChange={setPaymentMethod}
                                    data={[
                                        { value: 'creditAN', label: 'Card Payment', image: require('../../assets/payment/payment-credit.jpg') },
                                        { value: 'fpx', label: 'Online Banking', image: require('../../assets/payment/logo-fpx.png') },
                                        { value: 'cash-711', label: '7 Eleven', image: require('../../assets/payment/payment-7e.jpg'),
                                        },
                                    ]}
                                    mb={8}
                                />
                                {paymentMethod === 'fpx' ? <Select
                                    label="Bank"
                                    placeholder="Select Bank"
                                    data={FPX_BANK}
                                    value={paymentChannel} onChange={setPaymentChannel}
                                    mb={8}
                                /> : null}
                                {/* <Grid>
                                    {
                                        PAYMENT_METHOD.map((item, index)=>(
                                            <Grid.Col key={index} span={8} xs={6} sm={3}>
                                                <div onClick={()=>setPaymentChannel(item?.channel)} style={{cursor: 'pointer', display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                                                    <Image src={item?.image}/>
                                                    <div style={{display: 'flex', marginTop: 10, width: 18, height: 18, borderRadius: 18, border: '1.5px solid #001659', justifyContent: 'center', alignItems: 'center'}}>
                                                        {item?.channel === paymentChannel &&
                                                            <div style={{width: 10, height: 10, borderRadius: 10, backgroundColor: '#001659'}}/>
                                                        }
                                                    </div>
                                                </div>
                                            </Grid.Col>
                                        ))
                                    }
                                </Grid> */}
                                <Button mt={12} type="button" id="myPay" data-toggle="molpayseamless"
                                    data-mpsmerchantid="CSxtenz"
                                    data-mpschannel={paymentChannel}
                                    data-mpsamount={paymentDetails?.razerPayDetails?.mp_amount}
                                    data-mpsorderid={paymentDetails?.razerPayDetails?.mp_order_ID}
                                    data-mpsbill_name={paymentDetails?.razerPayDetails?.mp_bill_name}
                                    data-mpsbill_email={paymentDetails?.razerPayDetails?.mp_bill_email}
                                    data-mpsbill_mobile={paymentDetails?.razerPayDetails?.mp_bill_mobile}
                                    data-mpscurrency={paymentDetails?.razerPayDetails?.mp_currency}
                                    data-mpsbill_desc={paymentDetails?.razerPayDetails?.mp_bill_description}
                                    data-mpsreturnurl={`${window.location.origin}/payment?redirect=true`}
                                    data-mpscancelurl={`${window.location.origin}/payment`}
                                    data-mpsvcode={paymentDetails?.vcode}
                                    disabled={!paymentChannel}
                                >
                                    Pay Now
                                </Button>
                                </>
                                : paymentDetails?.isPaid ?
                                    <Center style={{ padding: '20px 20px', flexDirection: 'column', backgroundColor: '#FAFAFA' }}>
                                        <Text fz="xl" fw={500}>Payment Successful</Text>
                                        <Text fz="md" fw={500} color='gray' mt={12}> Please download our app and continue with your vehicle registation. </Text>
                                        {/* <Button component={Link} to="/download" mt={20} > Download App </Button> */}
                                        <Group position='center' mt={20}>
                                            <a href={PLAY_STORE_URL} target="_blank" rel="noreferrer">
                                                <Image src={IconGooglePlay} width={150}/>
                                            </a>
                                            <a href={APP_STORE_URL} target="_blank" rel="noreferrer">
                                                <Image src={IconAppStore} width={150}/>
                                            </a>
                                        </Group>
                                    </Center>
                                :
                                    <Center style={{ height: 200 }}>
                                        <IconExclamationMark size={32} color="red"/>
                                    </Center>
                                }
                                {isError && (
                                    <Center style={{ height: 200 }}>
                                        <IconExclamationMark size={32} color="red"/>
                                    </Center>
                                )}                
                            </Stepper.Step>
                        </Stepper>    
                    </Paper>
                </Container>
            </div>
        </div>
    </>
  );
}
