import React from 'react'
import {
    Container,
    Text,
    Paper,
    Button,
    Image,
    Group
} from '@mantine/core';
import {
  IconCircleCheck,
  IconCircleX,
} from '@tabler/icons-react'
import { Link, useSearchParams } from "react-router-dom"

import IconGooglePlay from '../../assets/icons/IconGooglePlay.png'
import IconAppStore from '../../assets/icons/IconAppStore.png'

import { APP_STORE_URL, PLAY_STORE_URL } from '../../utils/Contants';

export default function CompletionBSN() {
  
  const [searchParams] = useSearchParams();
  const checkStatus = searchParams.get("redirect_status")

  return (
    <div style={{display: 'table',
                position: 'absolute',
                top: 0,
                left: 0,
                height: '100%',
                width: '100%'}}>
        <div style={{display: 'table-cell', verticalAlign: 'middle'}}>
            {checkStatus === "succeeded" ?
                <Container size={450} my={40}>
                    <Paper withBorder shadow="md" p={30} mt={30} align="center">
                    <IconCircleCheck size={80} color="#2F9E44" mb={100}/>
                    <Text fz="xl" fw={500}> Payment Successful </Text>
                    <Text fz="md" fw={500} color='gray' mt={12}> Please download our app and continue with your vehicle registation. </Text>
                    {/* <Button component={Link} to="/download" mt={20} > Download App </Button> */}
                    <Group position='center' mt={20}>
                        <a href={PLAY_STORE_URL} target="_blank" rel="noreferrer">
                            <Image src={IconGooglePlay} width={150}/>
                        </a>
                        <a href={APP_STORE_URL} target="_blank" rel="noreferrer">
                            <Image src={IconAppStore} width={150}/>
                        </a>
                    </Group>
                    </Paper>
                </Container>
              : 
              <Container size={450} my={40}>
                <Paper withBorder shadow="md" p={30} mt={30} align="center">
                  <IconCircleX size={80} color="#E03131" mb={100}/>
                  <Text fz="xl" fw={500}> Payment Failed </Text>
                  <Text fz="md" fw={500} color='gray' mt={12}> Please try again by clicking 'Back to Payment'. </Text>
                  <Button component={Link} to="/payment-bsn" mt={20} > Back to Payment </Button>
                </Paper>
              </Container>
            } 
        </div>
    </div>
  );
}
