import React from 'react'
import { createStyles, Container, Text, Space, Grid, Image } from '@mantine/core'

// import IconBuyWarranty from '../../assets/icons/IconBuyWarranty.png'
// import IconCheckWarrantyPackage from '../../assets/icons/IconCheckWarrantyPackage.png'
// import IconCheckPanelWorkshops from '../../assets/icons/IconCheckPanelWorkshops.png'
// import IconDLBrochure from '../../assets/icons/IconDLBrochure.png'

import IconWhyO1 from '../../assets/icons/IconWhy01.png'
import IconWhyO2 from '../../assets/icons/IconWhy02.png'
import IconWhyO3 from '../../assets/icons/IconWhy03.png'
import IconWhyO4 from '../../assets/icons/IconWhy04.png'
import IconWhyO5 from '../../assets/icons/IconWhy05.png'
import IconWhyO6 from '../../assets/icons/IconWhy06.png'

import { TranslateText as t } from '../Localization/translations'

const useStyles = createStyles((theme) => ({
    
    container: {
        padding: 100,

        [theme.fn.smallerThan('sm')]: {
            paddingLeft: 50,
            paddingRight: 50,
        },
        [theme.fn.smallerThan('xs')]: {
            paddingLeft: 25,
            paddingRight: 25,
        },
    }

  
}));

export default function Section02() {
    const { classes, } = useStyles();

    return (
        <Container size={'xl'} className={classes.container}>
            {/* <div style={{display: 'flex', backgroundColor: '#E6E6EF', borderRadius: 30, overflow: 'none', paddingTop: 40, paddingBottom: 40, justifyContent: 'space-evenly'}}>
                <UnstyledButton>
                    <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                        <Image width={108} height={90} fit='contain' src={IconBuyWarranty} alt="With default placeholder" withPlaceholder />
                        <Space h={20}/>
                        <Text size={16} fw={600} c={'#174AFF'}>Buy Warranty</Text>
                    </div>
                </UnstyledButton>
                <UnstyledButton>
                    <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                        <Image width={108} height={90} fit='contain' src={IconCheckWarrantyPackage} alt="With default placeholder" withPlaceholder />
                        <Space h={20}/>
                        <Text size={16} fw={600} c={'#174AFF'}>Warranty Package</Text>
                    </div>
                </UnstyledButton>
                <UnstyledButton>
                    <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                        <Image width={108} height={90} fit='contain' src={IconCheckPanelWorkshops} alt="With default placeholder" withPlaceholder />
                        <Space h={20}/>
                        <Text size={16} fw={600} c={'#174AFF'}>Panel Workshops</Text>
                    </div>
                </UnstyledButton>
                <UnstyledButton>
                    <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                        <Image width={108} height={90} fit='contain' src={IconDLBrochure} alt="With default placeholder" withPlaceholder />
                        <Space h={20}/>
                        <Text size={16} fw={600} c={'#174AFF'}>Download Brochure</Text>
                    </div>
                </UnstyledButton>
            </div>
            <Space h={100}/> */}
            <div>
                <Text align='center' sx={{fontFamily: 'Magistral', fontWeight: 400, color: '#3547F8'}} size={40}>{t('s2_title')} <span style={{fontWeight: 700, color: '#80FB41'}}>XTENZ</span> ?</Text>
                <Space h={50}/>
                <Grid gutter={'xl'}>
                    <Grid.Col span={12} xs={12} sm={6} md={6} lg={4}>
                        <div style={{display: 'flex', height: '100%', flexDirection: 'column', alignItems: 'center', borderRadius: 10, boxShadow: '0px 0px 4px 2px rgba(0, 0, 0, 0.15)', padding: 45}}>
                            <Image width={105} height={105} fit='contain' src={IconWhyO1} alt="With default placeholder" withPlaceholder />
                            <Space h={45}/>
                            <Text size={20} fw={700} c={'#00145B'} align='center'>{t('s2_why_title_01')}</Text>
                            <Space h={13}/>
                            <Text size={15} fw={500} c={'#00145B'} align='center'>{t('s2_why_descr_01')}</Text>
                        </div>
                    </Grid.Col>
                    <Grid.Col span={12} xs={12} sm={6} md={6} lg={4}>
                        <div style={{display: 'flex', height: '100%', flexDirection: 'column', alignItems: 'center', borderRadius: 10, boxShadow: '0px 0px 4px 2px rgba(0, 0, 0, 0.15)', padding: 45}}>
                            <Image width={105} height={105} fit='contain' src={IconWhyO2} alt="With default placeholder" withPlaceholder />
                            <Space h={45}/>
                            <Text size={20} fw={700} c={'#00145B'} align='center'>{t('s2_why_title_02')}</Text>
                            <Space h={13}/>
                            <Text size={15} fw={500} c={'#00145B'} align='center'>{t('s2_why_descr_02')}</Text>
                        </div>
                    </Grid.Col>
                    <Grid.Col span={12} xs={12} sm={6} md={6} lg={4}>
                        <div style={{display: 'flex', height: '100%', flexDirection: 'column', alignItems: 'center', borderRadius: 10, boxShadow: '0px 0px 4px 2px rgba(0, 0, 0, 0.15)', padding: 45}}>
                            <Image width={105} height={105} fit='contain' src={IconWhyO3} alt="With default placeholder" withPlaceholder />
                            <Space h={45}/>
                            <Text size={20} fw={700} c={'#00145B'} align='center'>{t('s2_why_title_03')}</Text>
                            <Space h={13}/>
                            <Text size={15} fw={500} c={'#00145B'} align='center'>{t('s2_why_descr_03')}</Text>
                        </div>
                    </Grid.Col>
                    <Grid.Col span={12} xs={12} sm={6} md={6} lg={4}>
                        <div style={{display: 'flex', height: '100%', flexDirection: 'column', alignItems: 'center', borderRadius: 10, boxShadow: '0px 0px 4px 2px rgba(0, 0, 0, 0.15)', padding: 45}}>
                            <Image width={105} height={105} fit='contain' src={IconWhyO4} alt="With default placeholder" withPlaceholder />
                            <Space h={45}/>
                            <Text size={20} fw={700} c={'#00145B'} align='center'>{t('s2_why_title_04')}</Text>
                            <Space h={13}/>
                            <Text size={15} fw={500} c={'#00145B'} align='center'>{t('s2_why_descr_04')}</Text>
                        </div>
                    </Grid.Col>
                    <Grid.Col span={12} xs={12} sm={6} md={6} lg={4}>
                        <div style={{display: 'flex', height: '100%', flexDirection: 'column', alignItems: 'center', borderRadius: 10, boxShadow: '0px 0px 4px 2px rgba(0, 0, 0, 0.15)', padding: 45}}>
                            <Image width={105} height={105} fit='contain' src={IconWhyO5} alt="With default placeholder" withPlaceholder />
                            <Space h={45}/>
                            <Text size={20} fw={700} c={'#00145B'} align='center'>{t('s2_why_title_05')}</Text>
                            <Space h={13}/>
                            <Text size={15} fw={500} c={'#00145B'} align='center'>{t('s2_why_descr_05')}</Text>
                        </div>
                    </Grid.Col>
                    <Grid.Col span={12} xs={12} sm={6} md={6} lg={4}>
                        <div style={{display: 'flex', height: '100%', flexDirection: 'column', alignItems: 'center', borderRadius: 10, boxShadow: '0px 0px 4px 2px rgba(0, 0, 0, 0.15)', padding: 45}}>
                            <Image width={105} height={105} fit='contain' src={IconWhyO6} alt="With default placeholder" withPlaceholder />
                            <Space h={45}/>
                            <Text size={20} fw={700} c={'#00145B'} align='center'>{t('s2_why_title_06')}</Text>
                            <Space h={13}/>
                            <Text size={15} fw={500} c={'#00145B'} align='center'>{t('s2_why_descr_06')}</Text>
                        </div>
                    </Grid.Col>
                </Grid>
            </div>
        </Container>
    )
}
