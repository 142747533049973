import {
    useMantineTheme, Button, Flex, Center, Text, Modal, LoadingOverlay
} from '@mantine/core';

import { WEBSITE_URL } from '../../utils/Contants';

export default function TermsConditionsModal({opened, onClose, onClick, approveBtnText = 'Confirm' , isLoading = false}) {
    const theme = useMantineTheme();

  return (
        <Modal
            centered
            opened={opened}
            onClose={onClose}
            withCloseButton={false}
            //p='xs'
            zIndex={999}
        >
            <LoadingOverlay visible={isLoading} overlayBlur={2} radius='lg'/>
            <Center>
                <Text size='lg' ta="center" weight={600} color={theme.colors.gray[6]} p='sm'>
                    <Text span>{`By clicking on the Proceed button, you agree to our `}</Text>
                    <Text color={theme.colors.red[6]} span component="a" href={`${window.location.origin}/terms-conditions`} target="_blank">{`Terms & Condition`}</Text>
                    <Text span>{`.`}</Text>
                </Text>
            </Center>
            <Flex justify="flex-start"
                        align="center"
                        direction="row"
                        wrap="wrap" 
                        gap="md" p='sm'>
                <Button color='XTENZBlue' sx={{flex: 1}} onClick={onClick}>
                    {approveBtnText}
                </Button>
                <Button color='gray' sx={{flex: 1}} onClick={onClose}>
                    Cancel
                </Button>
            </Flex>
        </Modal>
  )
}
