import React from 'react'
import { createStyles, Container, Text, Space, Button, Grid } from '@mantine/core'
import { IconCircleCheckFilled } from '@tabler/icons-react';
import { useNavigate } from 'react-router-dom';

import { TranslateText as t } from '../Localization/translations';

const useStyles = createStyles((theme) => ({

    container: {
        paddingTop: 100,
        paddingBottom: 100,

        [theme.fn.smallerThan('sm')]: {
            paddingLeft: 50,
            paddingRight: 50,
        },
        [theme.fn.smallerThan('xs')]: {
            paddingLeft: 25,
            paddingRight: 25,
        },
    },

}));

export default function Section06({targetRef}) {
    const { classes, } = useStyles();
    const navigate = useNavigate()

    const onClickLearnMore = () => {
        navigate('/product')
    }

    const onClickBuy = (plan) => {
        window.open(`/register-warranty?plan=${plan}`,'_blank')
    }

    return (
        <Container ref={targetRef} size={'xl'} className={classes.container}>
            <Text align='center' sx={{fontFamily: 'Magistral', fontWeight: 400, color: '#3547F8'}} size={40}><span style={{fontWeight: 700, color: '#80FB41'}}>XTENZ</span> {t('s6_title')}</Text>
            <Space h={50}/>
            <Grid gutter={'xl'}>
                <Grid.Col span={12} xs={12} sm={6} md={6} lg={3}>
                    <div style={{display: 'flex', height: '100%', flexDirection: 'column', borderRadius: 10, boxShadow: '0px 0px 4px 2px rgba(0, 0, 0, 0.15)', padding: 30}}>
                        <Text size={28} align='center' sx={{fontFamily: 'Magistral', fontWeight: 700, color: '#808081'}}>Silver Plan</Text>
                        <Space h={10}/>
                        <Text size={16} fw={700} c={'#00145B'} align='center'>Unbeatable Value to Stay Protected</Text>
                        <Space h={30}/>
                        <Text size={40} fw={700} c={'#00145B'} align='center'>RM<span style={{fontSize: 16}}> / year</span></Text>
                        <Space h={30}/>
                        <div style={{display: 'flex',}}>
                            <div>
                                <Space h={2}/>
                                <IconCircleCheckFilled size={20} style={{color: '#174AFF',}}/>
                            </div>
                            <Space w={10}/>
                            <Text size={15} fw={400} c={'#00145B'}>RM2,500 per claim.</Text>
                        </div>
                        <Space h={10}/>
                        <div style={{display: 'flex', flex: 1,}}>
                            <div>
                                <Space h={2}/>
                                <IconCircleCheckFilled size={20} style={{color: '#174AFF',}}/>
                            </div>
                            <Space w={10}/>
                            <Text size={15} fw={400} c={'#00145B'}>RM40,000 claim limit.</Text>
                        </div>
                        <Space h={10}/>
                        <div style={{display: 'flex'}}>
                            <div>
                                <Space h={2}/>  
                                <IconCircleCheckFilled size={20} style={{color: '#174AFF',}}/>
                            </div>
                            <Space w={10}/>
                            <Text size={15} fw={400} c={'#00145B'}>1 year or 15,000km warranty period.</Text>
                        </div>
                        <Space h={10}/>
                        <div style={{display: 'flex'}}>
                            <div>
                                <Space h={2}/>
                                <IconCircleCheckFilled size={20} style={{color: '#174AFF',}}/>
                            </div>
                            <Space w={10}/>
                            <Text size={15} fw={400} c={'#00145B'}>Eligible for vehicles below 15 years old and 180,000km.</Text>
                        </div>
                        <Space h={10}/>
                        <div style={{display: 'flex',}}>
                            <div>
                                <Space h={2}/>
                                <IconCircleCheckFilled size={20} style={{color: '#174AFF',}}/>
                            </div>
                            <Space w={10}/>
                            <Text size={15} fw={400} c={'#00145B'}>Warranty Covered Engine, Transmission, ECU, TCM & Air-cond System.</Text>
                        </div>
                        <Space h={40}/>
                        <Button onClick={()=>onClickBuy('silver')} color='brandLBlue.0' radius={'md'}>{t('s6_buy')}</Button>
                        <Space h={10}/>
                        <Button onClick={onClickLearnMore} color='brandLBlue.0' radius={'md'} variant='outline'>{t('s6_learn_more')}</Button>
                    </div>
                </Grid.Col>
                <Grid.Col span={12} xs={12} sm={6} md={6} lg={3}>
                    <div style={{display: 'flex', height: '100%', flexDirection: 'column', borderRadius: 10, boxShadow: '0px 0px 4px 2px rgba(0, 0, 0, 0.15)', padding: 30}}>
                        <Text size={28} align='center' sx={{fontFamily: 'Magistral', fontWeight: 700, color: '#A38739'}}>Gold Plan </Text>
                        <Space h={10}/>
                        <Text size={16} fw={700} c={'#00145B'} align='center'>Enhanced Coverage at Great Value</Text>
                        <Space h={30}/>
                        <Text size={40} fw={700} c={'#00145B'} align='center'>RM<span style={{fontSize: 16}}> / year</span></Text>
                        <Space h={30}/>
                        <div style={{display: 'flex',}}>
                            <div>
                                <Space h={2}/>
                                <IconCircleCheckFilled size={20} style={{color: '#174AFF',}}/>
                            </div>
                            <Space w={10}/>
                            <Text size={15} fw={400} c={'#00145B'}>RM4,000 per claim.</Text>
                        </div>
                        <Space h={10}/>
                        <div style={{display: 'flex', flex: 1,}}>
                            <div>
                                <Space h={2}/>
                                <IconCircleCheckFilled size={20} style={{color: '#174AFF',}}/>
                            </div>
                            <Space w={10}/>
                            <Text size={15} fw={400} c={'#00145B'}>RM70,000 claim limit.</Text>
                        </div>
                        <Space h={10}/>
                        <div style={{display: 'flex'}}>
                            <div>
                                <Space h={2}/>  
                                <IconCircleCheckFilled size={20} style={{color: '#174AFF',}}/>
                            </div>
                            <Space w={10}/>
                            <Text size={15} fw={400} c={'#00145B'}>1 year or 15,000km warranty period.</Text>
                        </div>
                        <Space h={10}/>
                        <div style={{display: 'flex'}}>
                            <div>
                                <Space h={2}/>
                                <IconCircleCheckFilled size={20} style={{color: '#174AFF',}}/>
                            </div>
                            <Space w={10}/>
                            <Text size={15} fw={400} c={'#00145B'}>Eligible for vehicles below 15 years old and 180,000km.</Text>
                        </div>
                        <Space h={10}/>
                        <div style={{display: 'flex',}}>
                            <div>
                                <Space h={2}/>
                                <IconCircleCheckFilled size={20} style={{color: '#174AFF',}}/>
                            </div>
                            <Space w={10}/>
                            <Text size={15} fw={400} c={'#00145B'}>Warranty Covered Engine, Transmission, ECU, TCM & Air-cond System.</Text>
                        </div>
                        <Space h={40}/>
                        <Button onClick={()=>onClickBuy('gold')} color='brandLBlue.0' radius={'md'}>{t('s6_buy')}</Button>
                        <Space h={10}/>
                        <Button onClick={onClickLearnMore} color='brandLBlue.0' radius={'md'} variant='outline'>{t('s6_learn_more')}</Button>
                    </div>
                </Grid.Col>
                <Grid.Col span={12} xs={12} sm={6} md={6} lg={3}>
                    <div style={{display: 'flex', height: '100%', flexDirection: 'column', borderRadius: 10, boxShadow: '0px 0px 4px 2px rgba(0, 0, 0, 0.15)', padding: 30}}>
                        <Text size={28} align='center' sx={{fontFamily: 'Magistral', fontWeight: 700, color: '#7E4FD5'}}>Platinum Plan </Text>
                        <Space h={10}/>
                        <Text size={16} fw={700} c={'#00145B'} align='center'>Higher Assurance for Better Peace of Mind</Text>
                        <Space h={30}/>
                        <Text size={40} fw={700} c={'#00145B'} align='center'>RM<span style={{fontSize: 16}}> / year</span></Text>
                        <Space h={30}/>
                        <div style={{display: 'flex',}}>
                            <div>
                                <Space h={2}/>
                                <IconCircleCheckFilled size={20} style={{color: '#174AFF',}}/>
                            </div>
                            <Space w={10}/>
                            <Text size={15} fw={400} c={'#00145B'}>RM5,000 per claim.</Text>
                        </div>
                        <Space h={10}/>
                        <div style={{display: 'flex', flex: 1,}}>
                            <div>
                                <Space h={2}/>
                                <IconCircleCheckFilled size={20} style={{color: '#174AFF',}}/>
                            </div>
                            <Space w={10}/>
                            <Text size={15} fw={400} c={'#00145B'}>RM80,000 claim limit.</Text>
                        </div>
                        <Space h={10}/>
                        <div style={{display: 'flex'}}>
                            <div>
                                <Space h={2}/>  
                                <IconCircleCheckFilled size={20} style={{color: '#174AFF',}}/>
                            </div>
                            <Space w={10}/>
                            <Text size={15} fw={400} c={'#00145B'}>1 year or 15,000km warranty period.</Text>
                        </div>
                        <Space h={10}/>
                        <div style={{display: 'flex'}}>
                            <div>
                                <Space h={2}/>
                                <IconCircleCheckFilled size={20} style={{color: '#174AFF',}}/>
                            </div>
                            <Space w={10}/>
                            <Text size={15} fw={400} c={'#00145B'}>Eligible for vehicles below 12 years old and 180,000km.</Text>
                        </div>
                        <Space h={10}/>
                        <div style={{display: 'flex',}}>
                            <div>
                                <Space h={2}/>
                                <IconCircleCheckFilled size={20} style={{color: '#174AFF',}}/>
                            </div>
                            <Space w={10}/>
                            <Text size={15} fw={400} c={'#00145B'}>Warranty Covered Engine, Transmission, ECU, TCM, Turbo & Air-cond System.</Text>
                        </div>
                        <Space h={40}/>
                        <Button onClick={()=>onClickBuy('platinum')} color='brandLBlue.0' radius={'md'}>{t('s6_buy')}</Button>
                        <Space h={10}/>
                        <Button onClick={onClickLearnMore} color='brandLBlue.0' radius={'md'} variant='outline'>{t('s6_learn_more')}</Button>
                    </div>
                </Grid.Col>
                <Grid.Col span={12} xs={12} sm={6} md={6} lg={3}>
                    <div style={{display: 'flex', height: '100%', flexDirection: 'column', borderRadius: 10, boxShadow: '0px 0px 4px 2px rgba(0, 0, 0, 0.15)', padding: 30}}>
                        <Text size={28} align='center' sx={{fontFamily: 'Magistral', fontWeight: 700, color: '#78297E'}}>Diamond Plan</Text>
                        <Space h={10}/>
                        <Text size={16} fw={700} c={'#00145B'} align='center'>Superior Coverage for Total Security</Text>
                        <Space h={30}/>
                        <Text size={40} fw={700} c={'#00145B'} align='center'>RM<span style={{fontSize: 16}}> / year</span></Text>
                        <Space h={30}/>
                        <div style={{display: 'flex',}}>
                            <div>
                                <Space h={2}/>
                                <IconCircleCheckFilled size={20} style={{color: '#174AFF',}}/>
                            </div>
                            <Space w={10}/>
                            <Text size={15} fw={400} c={'#00145B'}>RM5,000 per claim.</Text>
                        </div>
                        <Space h={10}/>
                        <div style={{display: 'flex', flex: 1,}}>
                            <div>
                                <Space h={2}/>
                                <IconCircleCheckFilled size={20} style={{color: '#174AFF',}}/>
                            </div>
                            <Space w={10}/>
                            <Text size={15} fw={400} c={'#00145B'}>RM90,000 claim limit.</Text>
                        </div>
                        <Space h={10}/>
                        <div style={{display: 'flex'}}>
                            <div>
                                <Space h={2}/>  
                                <IconCircleCheckFilled size={20} style={{color: '#174AFF',}}/>
                            </div>
                            <Space w={10}/>
                            <Text size={15} fw={400} c={'#00145B'}>1 year or 15,000km warranty period.</Text>
                        </div>
                        <Space h={10}/>
                        <div style={{display: 'flex'}}>
                            <div>
                                <Space h={2}/>
                                <IconCircleCheckFilled size={20} style={{color: '#174AFF',}}/>
                            </div>
                            <Space w={10}/>
                            <Text size={15} fw={400} c={'#00145B'}>Eligible for vehicles below 12 years old and 180,000km.</Text>
                        </div>
                        <Space h={10}/>
                        <div style={{display: 'flex',}}>
                            <div>
                                <Space h={2}/>
                                <IconCircleCheckFilled size={20} style={{color: '#174AFF',}}/>
                            </div>
                            <Space w={10}/>
                            <Text size={15} fw={400} c={'#00145B'}>Warranty Covered Engine, Transmission, ECU, TCM, Turbo & Air-cond System.</Text>
                        </div>
                        <Space h={40}/>
                        <Button onClick={()=>onClickBuy('diamond')} color='brandLBlue.0' radius={'md'}>{t('s6_buy')}</Button>
                        <Space h={10}/>
                        <Button onClick={onClickLearnMore} color='brandLBlue.0' radius={'md'} variant='outline'>{t('s6_learn_more')}</Button>
                    </div>
                </Grid.Col>
            </Grid>
            {/* <Space h={50}/>
            <Center>
                <Button color='brandLBlue.0' variant="outline" radius="xl" size="lg">View Promotions</Button>
            </Center> */}
        </Container>
    )
}
