import {
    useMantineTheme, Button, Flex, Center, Text, Modal, LoadingOverlay
} from '@mantine/core';

export default function ConfirmationModal({opened, onClose, text, onClick, approveBtnText = 'Confirm' , isLoading = false}) {
    const theme = useMantineTheme();

  return (
        <Modal
            centered
            opened={opened}
            onClose={onClose}
            withCloseButton={false}
            p='xl'
            zIndex={999}
        >
            <LoadingOverlay visible={isLoading} overlayBlur={2} radius='lg'/>
            <Center>
                <Text size='lg' ta="center" weight={600} color={theme.colors.gray[6]} p='md'>{text}</Text>
            </Center>
            <Flex justify="flex-start"
                        align="center"
                        direction="row"
                        wrap="wrap" 
                        gap="md" p='md'>
                <Button sx={{flex: 1}} onClick={onClick}>
                    {approveBtnText}
                </Button>
                <Button color='gray' sx={{flex: 1}} onClick={onClose}>
                    Cancel
                </Button>
            </Flex>
        </Modal>
  )
}
