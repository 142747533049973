import { Outlet } from "react-router-dom";
import { useState, useEffect } from "react";
import { Loader, Text } from '@mantine/core';
//import useRefreshToken from "../../hooks/useRefreshToken";
//import useAuth from "../../hooks/useAuth";
import usePaymentIDs from "../Hooks/usePaymentIDs";

export default function PersistPaymentIDs({allowedType}) {
    const [isLoading, setIsLoading] = useState(true)
    const [isUnauthorized, setUnauthorized] = useState(false)
    //const refresh = useRefreshToken();
    //const { auth, persist } = useAuth();
    const { paymentIDs } = usePaymentIDs()

    useEffect(() => {
        let isMounted = true;

        const verifyIDs = async () => {
            try {
                if(paymentIDs?.type !== allowedType){
                    setUnauthorized(true)
                }
                else{
                    setUnauthorized(false)
                }
            }
            catch(err){
                console.error(err)
            }
            finally{
                isMounted && setIsLoading(false)
            }
        }

        !paymentIDs?.uid ? verifyIDs() : setIsLoading(false)

        return () => isMounted = false
    }, [])

  return (
    <>
        {paymentIDs && paymentIDs.type === allowedType
            ? <Outlet />
            : isLoading
                ? <Loader size="xl" variant="dots" sx={{position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',}}/>
                : isUnauthorized
                    ? <Text>Unauthorized</Text>
                    : <Outlet />
        }
    </>
  )
}