import React, { useRef } from 'react'
import { createStyles, Container, Text, rem, Image, Space, BackgroundImage, Divider } from '@mantine/core'
import Autoplay from 'embla-carousel-autoplay';
import { Carousel } from '@mantine/carousel';
import { IconStarFilled } from '@tabler/icons-react';

import HomeReviewBG from '../../assets/images/HomeReviewBG.png'
import IconQuote from '../../assets/icons/IconQuote.png'

import { TranslateText as t } from '../Localization/translations';

const useStyles = createStyles((theme) => ({

    container: {
        flex: 1,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',

        padding: 120,

        [theme.fn.smallerThan('md')]: {
            paddingLeft: 50,
            paddingRight: 50,
            flexDirection: 'column',
        },
        [theme.fn.smallerThan('xs')]: {
            paddingLeft: 25,
            paddingRight: 25,
            flexDirection: 'column',
        },
    },

    reviewBG: {
        backgroundSize: 'contain', 
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'left',
        [theme.fn.smallerThan('md')]: {
            backgroundSize: 'cover', 
        },
    },

    contextLeft: {
        width: '28%',
        [theme.fn.smallerThan('md')]: {
            width: '100%',
        },
    },

    space: {
        width: '10%',
        [theme.fn.smallerThan('md')]: {
            height: 50,
        },
    },

    contentRight: {
        width: '68%',
        [theme.fn.smallerThan('md')]: {
            width: '100%',
        },
    },
    
}));

export default function Section05({ targetRef }) {
    const { classes, } = useStyles();

    const autoplay = useRef(Autoplay({ delay: 4000 }));

    return (
        <BackgroundImage
            ref={targetRef}
            src={HomeReviewBG}
            className={classes.reviewBG}
        >
            <div style={{backgroundColor: 'rgba(230, 230, 239, 0.80)'}}>
            <Container size={'xl'} className={classes.container}>
                <div className={classes.contextLeft}>
                    <Text sx={{fontFamily: 'Magistral', fontWeight: 700, color: '#3547F8'}} size={40}>{t('s5_title')}</Text>
                    <Space h={15}/>
                    <Text size={16} fw={500} c={'#00145B'}>{t('s5_desc')}</Text>
                </div>
                <div className={classes.space}/>
                <div className={classes.contentRight}>
                    <Carousel
                    // maw={320}
                    mx="auto"
                    withIndicators
                    height={400}
                    slideGap="md"
                    align="start"
                    styles={{
                        indicator: {
                            backgroundColor: '#CBCEF9',
                            width: rem(10),
                            height: rem(10),
                            borderRadius: rem(10),
                            // transition: 'width 250ms ease',
                
                            '&[data-active]': {
                                backgroundColor: '#3547F8',
                            },
                        },
                    }}
                    withControls={false}
                    loop={true}
                    plugins={[autoplay.current]}
                    onMouseEnter={autoplay.current.stop}
                    onMouseLeave={autoplay.current.reset}
                    >
                        <Carousel.Slide>
                            <div style={{backgroundColor: '#F0F0F9', display: 'flex', flexDirection: 'column', height: '100%', borderRadius: 20, border: '2px solid rgba(0, 0, 0, 0.1)', padding: 40, paddingTop: 28}}>
                                <Image width={60} height={60} fit='contain' src={IconQuote} alt="With default placeholder" withPlaceholder />
                                <Space h={28}/>
                                <div style={{display: 'flex'}}>
                                    <IconStarFilled size={15} style={{color: '#D8B540'}}/>
                                    <Space w={8}/>
                                    <IconStarFilled size={15} style={{color: '#D8B540'}}/>
                                    <Space w={8}/>
                                    <IconStarFilled size={15} style={{color: '#D8B540'}}/>
                                    <Space w={8}/>
                                    <IconStarFilled size={15} style={{color: '#D8B540'}}/>
                                    <Space w={8}/>
                                    <IconStarFilled size={15} style={{color: '#D8B540'}}/>
                                </div>
                                <Space h={15}/>
                                <Text size={16} fw={500} c={'#00145B'}>This warranty really helps me cost-wise. I don’t have to spend much money for the repair. This warranty really is worth it.</Text>
                                <div style={{display: 'flex', flex: 1, flexDirection: 'column', justifyContent: 'flex-end', }}>
                                    <Divider size="xs" color={'#BEBFC7'}/>
                                    <Space h={22}/>
                                    <Text size={16} fw={500} c={'#00145B'}>Tan Chong Wei</Text>
                                </div>
                            </div>
                        </Carousel.Slide>
                        <Carousel.Slide>
                            <div style={{backgroundColor: '#F0F0F9', display: 'flex', flexDirection: 'column', height: '100%', borderRadius: 20, border: '2px solid rgba(0, 0, 0, 0.1)', padding: 40, paddingTop: 28}}>
                                <Image width={60} height={60} fit='contain' src={IconQuote} alt="With default placeholder" withPlaceholder />
                                <Space h={28}/>
                                <div style={{display: 'flex'}}>
                                    <IconStarFilled size={15} style={{color: '#D8B540'}}/>
                                    <Space w={8}/>
                                    <IconStarFilled size={15} style={{color: '#D8B540'}}/>
                                    <Space w={8}/>
                                    <IconStarFilled size={15} style={{color: '#D8B540'}}/>
                                    <Space w={8}/>
                                    <IconStarFilled size={15} style={{color: '#D8B540'}}/>
                                    <Space w={8}/>
                                    <IconStarFilled size={15} style={{color: '#D8B540'}}/>
                                </div>
                                <Space h={15}/>
                                <Text size={16} fw={500} c={'#00145B'}>This warranty really helps me cost-wise. I don’t have to spend much money for the repair. This warranty really is worth it.</Text>
                                <div style={{display: 'flex', flex: 1, flexDirection: 'column', justifyContent: 'flex-end', }}>
                                    <Divider size="xs" color={'#BEBFC7'}/>
                                    <Space h={22}/>
                                    <Text size={16} fw={500} c={'#00145B'}>Tan Chong Wei</Text>
                                </div>
                            </div>
                        </Carousel.Slide>
                        <Carousel.Slide>
                            <div style={{backgroundColor: '#F0F0F9', display: 'flex', flexDirection: 'column', height: '100%', borderRadius: 20, border: '2px solid rgba(0, 0, 0, 0.1)', padding: 40, paddingTop: 28}}>
                                <Image width={60} height={60} fit='contain' src={IconQuote} alt="With default placeholder" withPlaceholder />
                                <Space h={28}/>
                                <div style={{display: 'flex'}}>
                                    <IconStarFilled size={15} style={{color: '#D8B540'}}/>
                                    <Space w={8}/>
                                    <IconStarFilled size={15} style={{color: '#D8B540'}}/>
                                    <Space w={8}/>
                                    <IconStarFilled size={15} style={{color: '#D8B540'}}/>
                                    <Space w={8}/>
                                    <IconStarFilled size={15} style={{color: '#D8B540'}}/>
                                    <Space w={8}/>
                                    <IconStarFilled size={15} style={{color: '#D8B540'}}/>
                                </div>
                                <Space h={15}/>
                                <Text size={16} fw={500} c={'#00145B'}>This warranty really helps me cost-wise. I don’t have to spend much money for the repair. This warranty really is worth it.</Text>
                                <div style={{display: 'flex', flex: 1, flexDirection: 'column', justifyContent: 'flex-end', }}>
                                    <Divider size="xs" color={'#BEBFC7'}/>
                                    <Space h={22}/>
                                    <Text size={16} fw={500} c={'#00145B'}>Tan Chong Wei</Text>
                                </div>
                            </div>
                        </Carousel.Slide>
                    </Carousel>
                </div>
            </Container>
            </div>
        </BackgroundImage>
    )
}
