import React, { useState } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { useLocalStorage } from '@mantine/hooks';

import XTENZAppShell from './components/General/XTENZAppShell';
import RegisterAppShell from './components/General/RegisterAppShell';

import Home from './pages/Home';
import Product from './pages/Product';
import Workshop from './pages/Workshop';
import TermsConditions from './pages/TermsConditions';
import RefundPolicy from './pages/RefundPolicy';
import PrivacyPolicy from './pages/PrivacyPolicy';

import Promotion from './pages/Promotion';
import PromotionView from './pages/PromotionView';

import NotFound from './pages/NotFound';

import RegisterWarranty from './pages/Registration/RegisterWarranty';
import RegisterBSN from './pages/Registration/RegisterBSN';
import PersistPaymentIDs from './components/General/PersistPaymentIDs';
import PaymentPazerPay from './pages/Registration/PaymentRazerPay';
import PaymentPazerPayBSN from './pages/Registration/PaymentRazerPayBSN';
import Completion from './pages/Registration/Completion';
import CompletionBSN from './pages/Registration/CompletionBSN';

import { AppContext } from './components/Context/AppContext';

import { PaymentIDsProvider } from './components/Context/PaymentIDsProvider';

import { MAIN_PAYMENT, BSN_PAYMENT } from './utils/Contants';

function App() {
    const [locale, setLocale] = useState(localStorage.getItem("locale") || 'en')

    const appContext = React.useMemo(
        () => ({
            changeLanguage: async (locale) => {
                localStorage.setItem('locale', locale );
                setLocale(locale)
            },
        }),
        []
    );

    const [paymentIDs, setPaymentIDs] = useLocalStorage({
        key: 'payment-ids',
        defaultValue: null,
        getInitialValueInEffect: true,
    });

  return (
    <PaymentIDsProvider paymentIDs={paymentIDs} setPaymentIDs={setPaymentIDs}>
        <AppContext.Provider value={{ locale, ...appContext }}>
            <Routes>
                {/* XTENZ Landing Website */}
                <Route element={<XTENZAppShell />}>
                    <Route path="/" element={<Home />} />
                    <Route path="/product" element={<Product />} />
                    <Route path="/workshop" element={<Workshop />} />
                    <Route path="/terms-conditions" element={<TermsConditions />} />
                    <Route path="/refund-policy" element={<RefundPolicy />} />
                    <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                    <Route path="/promotion" element={<Promotion />} />
                    <Route path="/promotion/:id" element={<PromotionView />} />
                </Route>

                <Route element={<RegisterAppShell />}>
                    <Route path='/register-warranty' element={<RegisterWarranty />} />
                    <Route path='/register' element={<RegisterBSN />}/>

                    <Route element={<PersistPaymentIDs allowedType={MAIN_PAYMENT} />}>
                        {/* Test Payment */}
                        <Route path='/payment' element={<PaymentPazerPay />}/>
                    </Route>
                    <Route path='/completion' element={<Completion />} />

                    <Route element={<PersistPaymentIDs allowedType={BSN_PAYMENT} />}>
                        {/* Test Payment */}
                        <Route path='/payment-bsn' element={<PaymentPazerPayBSN />}/>
                    </Route>
                    <Route path='/completion-bsn' element={<CompletionBSN />} />

                </Route>

                <Route path="/404" element={<NotFound />} />
                <Route path="*" element={<Navigate to="/404" replace />} />
            </Routes>
        </AppContext.Provider>
    </PaymentIDsProvider>
  );
}

export default App;
