import { useContext } from 'react'
import { AppContext } from '../Context/AppContext'
import en from './en'
import bm from './bm'

const translations = {
    en,
    bm,
}

const TranslateText = (
    key
) => {
    const { locale } = useContext(AppContext)
    const currTranslation = translations[locale] ? translations[locale] : en;
    let TranslatedText = currTranslation[key]
        ? currTranslation[key]
        : en[key]
            ? en[key]
            : key;
    return TranslatedText
}

export { TranslateText }