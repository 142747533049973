import Moment from 'moment';
import momenttz from 'moment-timezone';


//Format eg. SEPTEMBER 1, 2023
export const formatMMMM_D_yyyy = (date) => {
    if(!date) return '-- --- ----';
    let datetz = Moment(date).tz("Asia/Kuala_Lumpur")
    return Moment(datetz, "YYYY-MM-DD HH:mm:ss").format('MMMM D, yyyy')
}


