import React from 'react'
import {
    Container,
    Text,
    Paper,
    Button,
    Image,
    Group,
    Center,
} from '@mantine/core';
import {
  IconProgress
} from '@tabler/icons-react'
import { Link } from "react-router-dom"

import IconGooglePlay from '../../assets/icons/IconGooglePlay.png'
import IconAppStore from '../../assets/icons/IconAppStore.png'

import { APP_STORE_URL, PLAY_STORE_URL } from '../../utils/Contants';

export default function Completion() {
  
//   const [searchParams] = useSearchParams();
//   const checkStatus = searchParams.get("redirect_status")

  return (
    <div style={{display: 'table',
                position: 'absolute',
                top: 0,
                left: 0,
                height: '100%',
                width: '100%'}}>
        <div style={{display: 'table-cell', verticalAlign: 'middle'}}>
                <Container size={450} my={40}>
                    <Paper withBorder shadow="md" p={30} mt={30}>
                        <Center mb={20}>
                            <IconProgress size={60} color="#2F9E44"/>
                        </Center>
                        <Text fz="xl" fw={500} align='center'> Payment Processing </Text>
                        <Text fz="sm" align='justify' fw={500} color='gray' mt={12}>
                            If you have paid, we are currently processing your payment. 
                            Please contact us if your payment status is not updated within 30 minutes after payment. 
                        </Text>
                        <Text fz="sm" align='justify' fw={500} color='gray' mt={12}>
                            If your payment did not go through, please try again by clicking 'Back to Payment'.
                        </Text>
                        <Center mb={20}>
                        <Button component={Link} to="/payment" mt={20} > Back to Payment </Button>
                        </Center>
                        <Text fz="md" fw={500} color='gray' mt={12} align='center'> Please download our app and continue with your vehicle registation. </Text>
                        {/* <Button component={Link} to="/download" mt={20} > Download App </Button> */}
                        <Group position='center' mt={20}>
                            <a href={PLAY_STORE_URL} target="_blank" rel="noreferrer">
                                <Image src={IconGooglePlay} width={150}/>
                            </a>
                            <a href={APP_STORE_URL} target="_blank" rel="noreferrer">
                                <Image src={IconAppStore} width={150}/>
                            </a>
                        </Group>
                    </Paper>
                </Container>

              {/* <Container size={450} my={40}>
                <Paper withBorder shadow="md" p={30} mt={30} align="center">
                  <IconCircleX size={80} color="#E03131" mb={100}/>
                  <Text fz="xl" fw={500}> Payment Failed </Text>
                  <Text fz="md" fw={500} color='gray' mt={12}> Please try again by clicking 'Back to Payment'. </Text>
                  <Button component={Link} to="/payment" mt={20} > Back to Payment </Button>
                </Paper>
              </Container> */}

        </div>
    </div>
  );
}
