import React from 'react'
import { Outlet } from 'react-router-dom'
import { MantineProvider, AppShell } from '@mantine/core';

import MagistralFonts from '../../assets/fonts/MagistralFonts';

export default function RegisterAppShell() {

  return (
    <MantineProvider 
        theme={{ 
        colorScheme: 'light',
        colors: {
            // brand: ['#00145B'],
            // brandSecondary: ['#80FB41'],
            // brandLBlue: ['#174AFF'],
            // black: ['#000000'],
            white: ['#FFFFFF'],
            // primaryText: ['#FFFFFF'],
            // secondaryText: ['#DEDEDE'],
            brand: ['#FFF5F5', '#FFE3E3', '#FFC9C9', '#FFA8A8', '#FF8787', '#FF6B6B', '#FA5252', '#F03E3E', '#E03131', '#C92A2A'],
            XTENZBlue: ['#E6F2FF', '#CEE0FF', '#9BBFFF', '#649CFF', '#397DFE', '#1D6AFE', '#0961FF', '#0051E4', '#0048CC', '#003DB5'],
        },
        primaryColor: 'XTENZBlue',
        activeStyles: { transform: 'scale(0.98)' }}} withGlobalStyles withNormalizeCSS>
            <MagistralFonts />
                <AppShell
                    padding={0}
                    styles={(theme) => ({
                        main: { backgroundColor: theme.colors.white[0] },
                    })}
                >
                <Outlet/>
            </AppShell>
    </MantineProvider>
  )
}
