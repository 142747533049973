const en = {
    // Header
    'home': `home`,
    'testimonial': `testimonial`,
    'products': `products`,
    'workshops': `workshops`,
    'team': `team`,
    'contact_us': `contact us`,

    // Footer
    'connect_with_us': `Connect with Us`,
    'terms_conditions': `Terms & Conditions`,
    'refund_policy': `Refund Policy`,
    'privacy_policy': `Privacy Policy`,

    /* Home */
    'xtenz': `XTENZ`,

    //  Section 1
    's1_title': `Who is`,
    's1_quote': `Designed to Go Further`,
    's1_quote_ext': `XTENZ protects you from unexpected car repairs`,
    's1_desc': `Purchasing a car is a significant adult milestone and a major financial commitment for most families. Don't let unexpected breakdowns derail your budget. With XTENZ, affordable protection is within reach. Founded by passionate young entrepreneurs, XTENZ addresses the needs of both car owners and automotive workshops. Our comprehensive coverage and support bring consumers and workshops closer. Whether you have a new or recon car, we have a plan for you. Upgrade your car warranty with XTENZ today.`,
    
    //  Section 2
    's2_title': `Why Choose`,
    's2_why_title_01': `100 Panel Nationwide`,
    's2_why_descr_01': `At XTENZ, we have more than 100 panel workshops located in cities throughout Malaysia.`,
    's2_why_title_02': `Claims Limit up to RM 120,000*`,
    's2_why_descr_02': `Let your car be protected even it has been with you for more than a decade*. Engine, transmission, air conditioning failure and more will no longer be your biggest nightmare.`,
    's2_why_title_03': `Guaranteed Claims in 72 Hours`,
    's2_why_descr_03': `Expect straightforward claim process from us to keep you hassle-free.`,
    's2_why_title_04': `24-Hour Hotline Operator`,
    's2_why_descr_04': `Our assistance with your vehicle’s claim process is always ready. Let our 24-hour hotline solve your queries.`,
    's2_why_title_05': `No Vehicle Inspection Needed*`,
    's2_why_descr_05': `With no vehicle inspection needed, your car is eligible to claim up to RM120,000 right when you need it.`,
    's2_why_title_06': `100% Safeguard Warranty Works on Big-Ticket Items`,
    's2_why_descr_06': `All types of vehicle, up to 15 years / 180,000 miles of usage.`,
    
    //  Section 3
    's3_title_01': `Why you need`,
    's3_title_02': `Extended Warranty`,
    's3_title_03': `for`,
    's3_title_04': `Your Car ?`,
    's3_why_01': `Ease Individual or Family Budgetary Planning`,
    's3_why_02': `Seamless Repair & Support - Having the Right Contact at Your Fingertips`,
    's3_why_03': `Long Term Coverage for Long Term Car Usage`,
    
    //  Section 4
    's4_title_01': `How`,
    's4_title_02': `Works ?`,
    's4_step': `Step`,
    's4_step_title_01': `Contact Claim Office`,
    's4_step_descr_01': `+6 010-256 0706 or +6 010-265 0706`,
    's4_step_title_02': `Send Your Vehicle to Nearby Workshop`,
    's4_step_descr_02': `-`,
    's4_step_title_03': `Identify Repair Needed & Get Quotation`,
    's4_step_descr_03': `-`,
    's4_step_title_04': `Contact Claim Office to Claim Repair Cost`,
    's4_step_descr_04': `Attach quotation and photos of your vehicle or/and vehicle parts.`,
    's4_step_title_05': `Verification by Claim Office`,
    's4_step_descr_05': `Payment in 72 hours`,

    //  Section 5
    's5_title': `What Our Customer Say About Us`,
    's5_desc': `Read what our customers have to say about our products and services.`,
    
    //  Section 6
    's6_title': `Warranty Packages`,
    's6_learn_more': `Learn More`,
    's6_buy': `Buy`,

    //  Section 7
    's7_title_01': `Meet Our`,
    's7_title_02': `Team`,
    's7_achievements': `Achievements`,
    's7_founder': `Founder`,
    's7_co_founder': `Co-Founder`,
    's7_ambassador': `Ambassador`,

    //  Section 8
    's8_title_01': `Contact`,
    's8_title_02': `Today`,
    's8_desc': `Comprehensive car warranty solutions are just a few steps away.`,
    's8_name': `Name`,
    's8_email': `Email`,
    's8_message': `Message`,
    's8_send': `Send`,
    's8_name_error': `Please fill in your name`,
    's8_email_error': `Please fill in a valid email`,
    's8_message_error': `Please fill in your message`,
    /* Home */

    //  Workshops
    'find_workshops': `Find Workshops`,

    //  Product
    'product_title': `Warranty Packages`,
    'product_buy': `Buy`,

    //  Promotion
    'promotion_title': `Latest Promotions`,

    '': ``,
}

export default en