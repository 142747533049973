import React from 'react'
import { createStyles, Container, rem , Space, Text, List, Center, Box, Button, Divider } from '@mantine/core'
import { IconCheck } from '@tabler/icons-react';
import { Link } from 'react-router-dom';

import { HEADER_HEIGHT } from '../utils/Contants';

import BSNBanner from '../assets/images/BSNBanner.webp'
import BSNPromoTable from '../assets/images/BSNPromoTable.webp'

import IconVehicleModal from '../assets/icons/IconVehicleModel.webp'
import IconEligibility from '../assets/icons/IconEligibility.webp'
// import IconVehicleInspection from '../assets/icons/IconVehicleInspection.webp'
// import IconPanelWorkshop from '../assets/icons/IconPanelWorkshop.webp'
import IconWarrantyPeriod from '../assets/icons/IconWarrantyPeriod.webp'

import IconEngine from '../assets/icons/IconEngine.webp'
import IconTransmission from '../assets/icons/IconTransmission.webp'
import IconECU from '../assets/icons/IconECU.webp'
import IconTCM from '../assets/icons/IconTCM.webp'
import IconAirCondSystem from '../assets/icons/IconAirCondSystem.webp'
// import IconTurbo from '../assets/icons/IconTurbo.webp'

const useStyles = createStyles((theme) => ({
  
    root: {
        backgroundColor: '#E6E6EF',
        minHeight: `calc(100vh - ${rem(HEADER_HEIGHT)})`,
    },

    promotionBtn: {
        display: 'flex', height: '100%', flexDirection: 'column', backgroundColor: '#FFFFFF', borderRadius: 20, boxShadow: '0px 0px 4px 2px rgba(0, 0, 0, 0.15)', overflow: 'hidden', cursor: 'pointer'
    },

    promotionContentContainer: {
        display: 'flex', flexDirection: 'column', justifyContent: 'space-between', flex: 1,padding: 20,
    },

    claimLimitContainer: {
        display: 'flex', overflow: 'hidden', borderRadius: 10,
        [theme.fn.smallerThan('xs')]: {
            flexDirection: 'column'
        },
    },

    itemCoverContainer: {
        display: 'flex', 
        [theme.fn.smallerThan('xs')]: {
            flexDirection: 'column'
        },
    },

    itemCoverContainerLeft: {
        flex: 1,
    },

    itemCoverContainerRight: {
        flex: 1,
        [theme.fn.smallerThan('xs')]: {
            paddingTop: 50
        },
    },

}));

export default function PromotionView() {
    const { classes } = useStyles();

    return (
        <Container size={'sm'} py={50}>
            <img src={BSNBanner} style={{width: '100%'}} alt={'Promotion'}/>
            <Space h={50}/>
            <Text size={12} fw={400} c={'#00145B'} align='center'>PROMO • 1 September 2023 - 29 February 2024</Text>
            <Space h={25}/>
            <Text sx={{fontFamily: 'Magistral', fontWeight: 700, color: '#00145B'}} size={28} align='center' lh={'normal'}>BSN Cardmembers Special Promo RM299 Car Warranty Plan</Text>
            <Space h={25}/>
            <Center>
                <Box w={120}>
                    <Button component={Link} to='/register' color='brandLBlue.0' sx={{ '&:hover': { backgroundColor: '#174AFF', color: '#FFFFFF', transition: 'all 0.2s ease' }}} variant="outline" radius="xl" size="md" fullWidth>Buy</Button>
                </Box>
            </Center>
            <Space h={50}/>
            <img src={BSNPromoTable} style={{width: '100%'}} alt={'Promotion'}/>
            <Divider my={50} color='#BEBFC7'/>
            <Text size={12} fw={400} c={'#00145B'} align='center'>Warranty Period</Text>
            <Text sx={{fontFamily: 'Magistral', fontWeight: 700, color: '#00145B'}} size={28} align='center' >1 Year or 15,000km*</Text>
            <Text size={12} fw={400} c={'#00145B'} align='center'>whichever shall come first</Text>
            <Space h={25}/>
            <div style={{display: 'flex', }}>
                <div style={{flex: 1, display: 'flex', justifyContent: 'space-between', alignItems: 'center', backgroundColor: '#00145B', padding: 12, borderRadius: 10}}>
                    <Text size={14} c={'#FFFFFF'}>Open / Panel Workshop</Text>
                    <IconCheck size={20} stroke={3} color='#80FB41'/>
                </div>
                <Space w={20}/>
                <div style={{flex: 1, display: 'flex', justifyContent: 'space-between', alignItems: 'center', backgroundColor: '#00145B', padding: 12, borderRadius: 10}}>
                    <Text size={14} c={'#FFFFFF'}>Vehicle Inspection</Text>
                    <IconCheck size={20} stroke={3} color='#80FB41'/>
                </div>
            </div>
            <Space h={50}/>
            <div className={classes.claimLimitContainer}>
                <div style={{flex: 1, display: 'flex', flexDirection: 'column', alignItems: 'center', backgroundColor: '#4F7EF9', padding: 20, paddingLeft: 10, paddingRight: 10}}>
                    <Text size={24} fw={800} c={'#FFFFFF'}>RM3,000</Text>
                    <Text size={12} fw={400} c={'#FFFFFF'} align='center'>Per Claim</Text>
                </div>
                <div style={{flex: 1, display: 'flex', flexDirection: 'column', alignItems: 'center', backgroundColor: '#3548F8', padding: 20, paddingLeft: 10, paddingRight: 10}}>
                    <Text size={24} fw={800} c={'#FFFFFF'}>RM500</Text>
                    <Text size={12} fw={400} c={'#FFFFFF'} align='center'>Labour Fee</Text>
                </div>
                <div style={{flex: 1, display: 'flex', flexDirection: 'column', alignItems: 'center', backgroundColor: '#8180B9', padding: 20, paddingLeft: 10, paddingRight: 10}}>
                    <Text size={24} fw={800} c={'#FFFFFF'}>RM70,000</Text>
                    <Text size={12} fw={400} c={'#FFFFFF'} align='center'>Maximum Claim Limit</Text>
                </div>
            </div>
            <Space h={50}/>
                <img src={IconVehicleModal} className={classes.icon} alt='Vehicle Modal'/>
            <Space h={20}/>
            <Text size={18} c={'#00145B'} fw={700}>Vehicle Models</Text>
            <Space h={10}/>
            <Text size={12} c={'#00145B'} fw={700}>All Car Models</Text>
            <Text size={12} c={'#00145B'}>(Except: Hyundai Starex, Ford Ranger, Renault, Peugeot, Citroen, Chevrolet, Jaguar & Super Car)</Text>
            <Text size={12} c={'#00145B'} fw={700}>Up to Max 15 years old</Text>
            <Space h={50}/>
            <img src={IconEligibility} className={classes.icon} alt='Eligibility'/>
            <Space h={20}/>
            <Text size={18} c={'#00145B'} fw={700}>Eligibility</Text>
            <Text size={12} c={'#00145B'}>Odometer mileage reading below 180,000km</Text>
            <Space h={50}/>
            <img src={IconWarrantyPeriod} className={classes.icon} alt='Cooling-off Period'/>
            <Space h={20}/>
            <Text size={18} c={'#00145B'} fw={700}>Cooling-off Period</Text>
            <Text size={12} c={'#00145B'}>30 Days and 400km from the effective date of the warranty cover</Text>
            <Divider my={50} color='#BEBFC7'/>
            <div className={classes.itemCoverContainer}>
                <div className={classes.itemCoverContainerLeft}>
                    <img src={IconEngine} className={classes.icon} alt='Engine'/>
                    <Space h={20}/>
                    <Text size={16} c={'#00145B'} fw={700}>Engine</Text>
                    <List size={12} style={{color:'#00145B'}}>
                        <List.Item>Engine Block</List.Item>
                        <List.Item>Oil Pump</List.Item>
                        <List.Item>Tappets (Valve Lifter)</List.Item>
                        <List.Item>Cylinder Heads</List.Item>
                        <List.Item>Rocker Arm</List.Item>
                        <List.Item>Camshaft Intake</List.Item>
                        <List.Item>Camshaft Exhaust</List.Item>
                        <List.Item>Crankshaft</List.Item>
                        <List.Item>Valve Tronic Motor</List.Item>
                        <List.Item>Eccentric Shaft</List.Item>
                        <List.Item>Piston</List.Item>
                        <List.Item>Variable Cam Timing</List.Item>
                        <List.Item>Engine Vacuum Pump</List.Item>
                        <List.Item>Connectng Rod</List.Item>
                    </List>
                    <Space h={50}/>
                    <img src={IconTransmission} className={classes.icon} alt='Transmission'/>
                    <Space h={20}/>
                    <Text size={16} c={'#00145B'} fw={700}>Transmission</Text>
                    <List size={12} style={{color:'#00145B'}}>
                        <List.Item>All Type Of Solenoid Valve</List.Item>
                        <List.Item>Gear Oil Pump</List.Item>
                        <List.Item>Torque Converter</List.Item>
                        <List.Item>Gear Set</List.Item>
                        <List.Item>Gear Shaft</List.Item>
                        <List.Item>Mechatronic</List.Item>
                        <List.Item>Valve Body</List.Item>
                    </List>
                </div>
                <div className={classes.itemCoverContainerRight}>
                    <img src={IconECU} className={classes.icon} alt='ECU'/>
                    <Space h={20}/>
                    <Text size={16} c={'#00145B'} fw={700}>ECU</Text>
                    <List size={12} style={{color:'#00145B'}}>
                        <List.Item>Engine Control Unit</List.Item>
                    </List>
                    <Space h={50}/>
                    <img src={IconTCM} className={classes.icon} alt='TCM'/>
                    <Space h={20}/>
                    <Text size={16} c={'#00145B'} fw={700}>TCM</Text>
                    <List size={12} style={{color:'#00145B'}}>
                        <List.Item>Transmission Control Module</List.Item>
                    </List>
                    <Space h={50}/>
                    <img src={IconAirCondSystem} className={classes.icon} alt='Air-Cond System'/>
                    <Space h={20}/>
                    <Text size={16} c={'#00145B'} fw={700}>Air-Cond System</Text>
                    <List size={12} style={{color:'#00145B'}}>
                        <List.Item>Air Cond Compressor</List.Item>
                        <List.Item>Blower Motor</List.Item>
                        <List.Item>Air Cond Motor</List.Item>
                    </List>
                </div>
            </div>
            <Space h={50}/>
            <div style={{backgroundColor: '#F0F0F9', padding: 14, paddingLeft: 24, paddingRight: 24, borderRadius: 10}}>
                <Text size={14} c={'#00145B'} fw={700}>Remarks</Text>
                <List size={12} style={{color:'#00145B'}} type="ordered">
                    <List.Item>Whichever shall come first.*</List.Item>
                    <List.Item>T&C applied.</List.Item>
                    <List.Item>Promotion package</List.Item>
                    <List.Item>All warranty coverage are subject to cooling-off period started days from date issued before claim process.</List.Item>
                </List>
            </div>
        </Container>
    )
}
