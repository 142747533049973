import { Global } from '@mantine/core';

import MagistralBold from './Magistral-Bold.otf'
import MagistralRegular from './Magistral-Regular.otf'

export default function MagistralFonts() {
  return (
    <Global
      styles={[
        {
            '@font-face': {
                fontFamily: 'Magistral',
                src: `url('${MagistralRegular}') format("opentype")`,
                fontWeight: 400,
                fontStyle: 'normal',
            },
        },
        {
            '@font-face': {
                fontFamily: 'Magistral',
                src: `url('${MagistralBold}') format("opentype")`,
                fontWeight: 700,
                fontStyle: 'normal',
            },
        },
      ]}
    />
  );
}