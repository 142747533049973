import React from 'react'
import { Outlet } from 'react-router-dom'
import { MantineProvider, AppShell } from '@mantine/core';
import { useScrollIntoView } from '@mantine/hooks';

import XTENZHeader from './XTENZHeader';
import XTENZFooter from './XTENZFooter';

import MagistralFonts from '../../assets/fonts/MagistralFonts';

export default function XTENZAppShell() {

    const homeScrollIntoView = useScrollIntoView({offset: 70,});
    const reviewScrollIntoView = useScrollIntoView({offset: 70,});
    const productsScrollIntoView = useScrollIntoView({offset: 70,});
    const teamScrollIntoView = useScrollIntoView({offset: 70,});
    const contactScrollIntoView = useScrollIntoView({offset: 70,});

  return (
    <MantineProvider 
        theme={{ 
        // fontFamily: 'Poppins, sans-serif',
        colorScheme: 'light',
        colors: {
            /*brand: ["#F9FAFD", "#D3DAF5", "#A9BAF5", "#7A97FF", "#6583EE", "#5774DA", "#4D67C6", "#495FAE", "#4B5B94", "#4A557E"],
            lightGray: ['#FAFAFA', '#F9F9F9', '#F8F8F8', '#F7F7F7', '#F6F6F6', '#F5F5F5', '#F4F4F4', '#F3F3F3', '#F2F2F2', '#F1F1F1'],
            primary: ['#8FC1E3'],*/
            brand: ['#00145B'],
            brandSecondary: ['#80FB41'],
            brandLBlue: ['#174AFF'],
            black: ['#000000'],
            white: ['#FFFFFF'],
            primaryText: ['#FFFFFF'],
            secondaryText: ['#DEDEDE']
        },
        activeStyles: { transform: 'scale(0.98)' }}} withGlobalStyles withNormalizeCSS>
            <MagistralFonts />
                <AppShell
                    padding={0}
                    header={<XTENZHeader 
                                homeScrollIntoView={homeScrollIntoView}
                                reviewScrollIntoView={reviewScrollIntoView}
                                productsScrollIntoView={productsScrollIntoView}
                                teamScrollIntoView={teamScrollIntoView}
                                contactScrollIntoView={contactScrollIntoView}
                            />}
                    footer={<XTENZFooter />}
                    styles={(theme) => ({
                        main: { backgroundColor: theme.colors.white[0] },
                    })}
                >
                <Outlet context={[homeScrollIntoView, reviewScrollIntoView, productsScrollIntoView, teamScrollIntoView, contactScrollIntoView]}/>
            </AppShell>
    </MantineProvider>
  )
}
