import React from 'react'
import { createStyles, Container, Text, Image, Space, Button, TextInput, Textarea } from '@mantine/core'
import { IconBrandWhatsapp } from '@tabler/icons-react';
import { useForm } from '@mantine/form';

import IconArrowGreenTR from '../../assets/icons/IconArrowGreenTR.png'
import IconPhone from '../../assets/icons/IconPhone.png'
import IconMap from '../../assets/icons/IconMap.png'

import { TranslateText as t } from '../Localization/translations';

const useStyles = createStyles((theme) => ({

    container: {
        padding: 120,

        [theme.fn.smallerThan('md')]: {
            paddingLeft: 50,
            paddingRight: 50,
            flexDirection: 'column',
        },
        [theme.fn.smallerThan('xs')]: {
            paddingLeft: 25,
            paddingRight: 25,
            flexDirection: 'column',
        },
    },

    textInput: {
        input:{
            borderBottom: '1px solid #BEBFC7',
            "::placeholder": {

                color: '#BEBFC7',
                fontWeight: 400,
            },

            '&:focus': {
                borderBottom: '1px solid #00145B',
            },
            color: '#00145B'
        },
        label: {
            color: '#00145B',
            fontWeight: 500,
        },
    },

    lableContainer: {
        display: 'flex', justifyContent: 'space-between', alignItems: 'center',
        [theme.fn.smallerThan('xs')]: {
            alignItems: 'flex-start',
        },
    },

    arrowImg: {
        width: 122,
        height: 122,
        [theme.fn.smallerThan('xs')]: {
            width: 61,
            height: 61,
        },
    },

    contentContainer: {
        display: 'flex', justifyContent: 'space-between',
        [theme.fn.smallerThan('xs')]: {
            flexDirection: 'column',
        },
    },

    contactContainer: {
        width: '32%',
        [theme.fn.smallerThan('xs')]: {
            width: '100%',
            
            // display: 'flex',
            // flexDirection: 'column',
            // alignItems: 'center'
        },
    },

    space: {
        width: '10%',
        [theme.fn.smallerThan('xs')]: {
            height: 50
        },
    },

    messageContainer: {
        width: '58%',
        [theme.fn.smallerThan('xs')]: {
            width: '100%',
        },
    },


}));

export default function Section08({targetRef}) {
    const { classes } = useStyles();

    
    let s8_name_error = t('s8_name_error')
    let s8_email_error = t('s8_email_error')
    let s8_message_error = t('s8_message_error')
    const form = useForm({
        initialValues: {
            name: '',
            email: '',
            message: '',
        },
    
        validate: {
            name: (value) => (value.length <= 0 ? s8_name_error : null),
            email: (value) => (/^\S+@\S+$/.test(value) ? null : s8_email_error),
            message: (value) => (value.length <= 0 ? s8_message_error : null),
        },
    });

    const onSubmitMessage = (data) => {
        let message = `Hi, I am ${data?.name}, I would like to inquire about ${data?.message}. This is my email address: ${data?.email}`
        let url = `https://wa.me/60165298622?text=${encodeURIComponent(message)}`
        window.open(url)
    }
    

    return (
        <div ref={targetRef} style={{backgroundColor: '#E6E6EF'}}>
            <Container size={'xl'} className={classes.container}>
                <div className={classes.lableContainer}>
                    <div>
                        <Text sx={{fontFamily: 'Magistral', fontWeight: 400, color: '#3547F8', lineHeight: '40px'}} size={40}>{t('s8_title_01')} <br/><span style={{fontWeight: 700, color: '#80FB41'}}>XTENZ</span> {t('s8_title_02')}</Text>
                        <Space h={15}/>
                        <Text size={16} fw={500} c={'#00145B'}>{t('s8_desc')}</Text>
                    </div>
                    <img src={IconArrowGreenTR} alt='Icon Arrow' className={classes.arrowImg}/>
                </div>
                <Space h={60}/>
                <div className={classes.contentContainer}>
                    <div className={classes.contactContainer}>
                        <Image width={55} height={55} fit='contain' src={IconPhone} alt="With default placeholder" withPlaceholder />
                        <Space h={15}/>
                        <div style={{display: 'flex', alignItems: 'center'}}>
                            <IconBrandWhatsapp size={28} color='#25d366' stroke={2}/>
                            <Space w={10}/>
                            <Text size={13} c={'#00145B'}>Carlson Chia<br/>+6 016-555 8622</Text>
                        </div>
                        <Space h={15}/>
                        <div style={{display: 'flex', alignItems: 'center'}}>
                            <IconBrandWhatsapp size={28} color='#25d366' stroke={2}/>
                            <Space w={10}/>
                            <Text size={13} c={'#00145B'}>Johnson (Claim Officer)<br/>+6 010 256 0706</Text>
                        </div>
                        <Space h={15}/>
                        <div style={{display: 'flex', alignItems: 'center'}}>
                            <IconBrandWhatsapp size={28} color='#25d366' stroke={2}/>
                            <Space w={10}/>
                            <Text size={13} c={'#00145B'}>Hao (Claim Officer)<br/>+6 010 265 0706</Text>
                        </div>
                        <Space h={40}/>
                        <Image width={55} height={55} fit='contain' src={IconMap} alt="With default placeholder" withPlaceholder />
                        <Space h={15}/>
                        <Text size={13} c={'#00145B'} lh={'normal'}><span style={{fontWeight: 700}}>XTENZ WARRANTY SDN BHD</span><br/>201801008511 (1270525-K)<br/>C-02-19 Persiaran Kewajipan, USJ 1, 47600 Subang Jaya,<br/>Selangor Darul Ehsan, MALAYSIA.</Text>
                    </div>
                    <div className={classes.space}/>
                    <div className={classes.messageContainer}>
                        <div style={{backgroundColor: '#F0F0F9', display: 'flex', flexDirection: 'column', borderRadius: 20, border: '2px solid rgba(0, 0, 0, 0.1)', padding: 40, paddingTop: 28}}>
                            <form onSubmit={form.onSubmit((values) => onSubmitMessage(values))}>
                            <TextInput
                                placeholder={t('s8_name')}
                                variant='unstyled'
                                className={classes.textInput}
                                mb='md'
                                label={t('s8_name')}

                                {...form.getInputProps('name')}
                            />
                            <TextInput
                                placeholder={t('s8_email')}
                                variant='unstyled'
                                className={classes.textInput}
                                mb='md'
                                label={t('s8_email')}

                                {...form.getInputProps('email')}
                            />
                            <Textarea
                                placeholder={`${t('s8_message')}...`}
                                
                                variant="unstyled"
                                label={t('s8_message')}
                                minRows={3}
                                maxRows={3}
                                styles={{
                                    input:{
                                        borderBottom: '1px solid #BEBFC7',
                            
                                        '&:focus': {
                                            borderBottom: '1px solid #00145B',
                                        },
                                        color: '#00145B',

                                        "::placeholder": {

                                            color: '#BEBFC7',
                                            fontWeight: 400,
                                        },
                                    },
                                    label: {
                                        color: '#00145B'
                                    }
                                }}

                                mb='xl'

                                {...form.getInputProps('message')}
                            />
                            <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                                <Button type='submit' color='brandLBlue.0' sx={{ '&:hover': { backgroundColor: '#174AFF', color: '#FFFFFF', transition: 'all 0.2s ease' }}} variant="outline" radius="md" size="md">{t('s8_send')}</Button>
                            </div>
                            </form>
                        </div>
                    </div>
                </div>
            </Container>
        </div>
    )
}
