import React from 'react'
import { createStyles, BackgroundImage, Container, Text, Space, rem } from '@mantine/core'

import HomeHeroBG from '../../assets/images/HomeHeroBG.png'
import IconArrowGreenTL from '../../assets/icons/IconArrowGreenTL.png'

import { HEADER_HEIGHT } from '../../utils/Contants'

import { TranslateText as t } from '../Localization/translations'

const useStyles = createStyles((theme) => ({
    root: {
      //position: 'relative',
      zIndex: 999,
    //   backgroundColor: 'rgba(0,0,0,0.2)',
      backdropFilter: 'blur(8px)',
  
      transition: 'background-color 0.25s ease',
    },
  
    heroSection: {
        minHeight: `calc(100svh - ${rem(HEADER_HEIGHT)})`,
        display: 'flex',
        // overflow: 'none'
    },

    heroBG: {
        backgroundSize: 'cover', 
    },

    heroContainer: {
        // backgroundColor: 'rgba(230, 230, 239, 0.80)',
        flex: 1,
        zIndex: 1,
        flexDirection: 'column',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'flex-end',
        
    },

    heroTiltedBG: {
        clipPath: 'polygon(30% 0%, 100% 0%, 100% 100%, 0% 100%)',
        position: 'absolute', backgroundColor: 'rgba(230, 230, 239, 0.5)', zIndex: 0, top: 0, right: 0, width: '65%', height: '100%',

        [theme.fn.smallerThan('sm')]: {
            clipPath: 'polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)',
            width: '100%',
        },
    },

    heroArrowImg: {
        width: 122,
        height: 122,
        position: 'absolute',
        bottom: 50,
        right: 50,

        [theme.fn.smallerThan('sm')]: {
            width: 61,
            height: 61,
            position: 'absolute',
            bottom: 25,
            right: 25,
        },
    },

    contentContainer: {
        width: '50%',

        [theme.fn.smallerThan('sm')]: {
            width: '100%',
        },
    },
  
}));

export default function Section01({targetRef}) {
    const { classes, } = useStyles();

    return (
        <BackgroundImage
            ref={targetRef}
            src={HomeHeroBG}
            className={classes.heroBG}
        >
                <div className={classes.heroSection}>
                    <div className={classes.heroTiltedBG}/>
                    <img src={IconArrowGreenTL} alt='Icon Arrow' className={classes.heroArrowImg}/>
                    <Container size={'xl'} className={classes.heroContainer}>
                        <div className={classes.contentContainer}>
                            <Text sx={{fontFamily: 'Magistral', fontWeight: 400, color: '#3547F8'}} size={40}>{t('s1_title')} <span style={{fontWeight: 700, color: '#80FB41'}}>XTENZ</span></Text>
                            <Space h={40}/>
                            <Text size={16} fw={500} c={'#3547F8'}>{t('s1_quote')} - {t('s1_quote_ext')}</Text>
                            <Space h={16}/>
                            <Text size={14} fw={500} c={'#001458'}>{t('s1_desc')}</Text>
                        </div>
                    </Container>
                </div>
        </BackgroundImage>
    )
}
