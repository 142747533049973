import React, {useRef} from 'react'
import { createStyles, Container, Text, rem, } from '@mantine/core'
import Autoplay from 'embla-carousel-autoplay';
import { Carousel } from '@mantine/carousel';

import WhySilder01 from '../../assets/images/WhySlider01.jpg'
import WhySilder02 from '../../assets/images/WhySlider02.jpg'
import WhySilder03 from '../../assets/images/WhySlider03.jpg'

import { TranslateText as t } from '../Localization/translations'; 

const useStyles = createStyles((theme) => ({
    
    container: {
        padding: 100,
        position: 'relative',

        [theme.fn.smallerThan('sm')]: {
            paddingLeft: 50,
            paddingRight: 50,
        },
        [theme.fn.smallerThan('xs')]: {
            paddingLeft: 25,
            paddingRight: 25,
        },
    },

    titleWrapper: {
        position: 'absolute', top: 110, right: 200,


        [theme.fn.smallerThan('lg')]: {
            // flexDirection: 'column',
            top: 110, right: 120,
        },
        [theme.fn.smallerThan('md')]: {
            position: 'relative',
            top: 0, right: 0,
            paddingBottom: 50,
        },
    },

    contentContainer: {
        display: 'flex', padding: 10, paddingLeft: 100, paddingRight: 100,
        // height: '100%',
        [theme.fn.smallerThan('lg')]: {
            // flexDirection: 'column',
            paddingLeft: 20, paddingRight: 20,
        },
        [theme.fn.smallerThan('md')]: {
            flexDirection: 'column',
            paddingLeft: 100, paddingRight: 100,
        },
        [theme.fn.smallerThan('sm')]: {
            flexDirection: 'column',
            paddingLeft: 75, paddingRight: 75,
        },
        [theme.fn.smallerThan('xs')]: {
            flexDirection: 'column',
            padding: 10,
        },
        // backgroundColor: 'blue'
    },

    imgContainer: {
        flex: 1, overflow: 'hidden', borderRadius: 10, boxShadow: '0px 0px 4px 2px rgba(0, 0, 0, 0.15)',
        [theme.fn.smallerThan('md')]: {
            borderBottomLeftRadius: 0,
            borderBottomRightRadius: 0,
            // flex: 1,
            // zIndex: 100,
        },
       
    },

    img: {
        width: '100%', objectFit: 'cover',

        [theme.fn.smallerThan('md')]: {
            width: '100%',
            height: 300,
        },
    },

    textWrapper: {
        flex: 2, display: 'flex', alignItems: 'flex-end',
        [theme.fn.smallerThan('md')]: {
            flex: 1,
            alignItems: 'flex-start',
        },
    },

    textContainer: {
        width: '100%', backgroundColor: '#F0F0F9', borderTopRightRadius: 30, borderBottomRightRadius: 30, padding: 60, boxShadow: '0px 0px 4px 2px rgba(0, 0, 0, 0.15)', marginBottom: 50, 

        [theme.fn.smallerThan('lg')]: {
            padding: 30,
        },
        [theme.fn.smallerThan('md')]: {
            padding: 30,
            borderRadius: 0,
            borderBottomLeftRadius: 10,
            borderBottomRightRadius: 10,
            marginBottom: 0
        },
    },

    sectionTitle: {
        fontFamily: 'Magistral', fontWeight: 700, color: '#3547F8', textAlign: 'right',
        [theme.fn.smallerThan('md')]: {
            textAlign: 'center',
        },
    }

}));

export default function Section03() {
    const { classes } = useStyles();

    const autoplay = useRef(Autoplay({ delay: 4000 }));

    return (
        <div style={{backgroundColor: '#E6E6EF', position: 'relative'}}>
            <Container size={'xl'} className={classes.container}>
                <div className={classes.titleWrapper}>
                    <Text className={classes.sectionTitle} size={30}>{t('s3_title_01')}<br/><span style={{fontWeight: 700, color: '#80FB41'}}>{t('s3_title_02')}</span> {t('s3_title_03')}<br/>{t('s3_title_04')}</Text>
                </div>
                <Carousel
                // maw={320}
                mx="auto"
                withIndicators
                // height={400}
                slideGap="md"
                align="start"
                styles={{
                    indicator: {
                        backgroundColor: '#CBCEF9',
                        width: rem(10),
                        height: rem(10),
                        borderRadius: rem(10),
                        // transition: 'width 250ms ease',
            
                        '&[data-active]': {
                            backgroundColor: '#3547F8',
                        },
                    },
                    control: {
                        backgroundColor: '#174AFF',
                        color: '#FFF',
                        width: rem(20),
                        height: rem(40),
                        borderRadius: rem(8),
                        // scale: 2,
                        // fontSize: 18
                    }
                  }}
                  loop={true}
                  plugins={[autoplay.current]}
                  onMouseEnter={autoplay.current.stop}
                  onMouseLeave={autoplay.current.reset}
                  containScroll='trimSnaps'
                >
                    <Carousel.Slide>
                        <div className={classes.contentContainer}>
                            <div className={classes.imgContainer}>
                                <img src={WhySilder01} className={classes.img} alt='WhySilder01'/>
                            </div>
                            <div className={classes.textWrapper}>
                                <div className={classes.textContainer}>
                                    <Text size={24} fw={700} color='#00145B'>{t('s3_why_01')}</Text>
                                </div>
                            </div>
                        </div>
                    </Carousel.Slide>
                    <Carousel.Slide>
                        <div className={classes.contentContainer}>
                            <div className={classes.imgContainer}>
                                <img src={WhySilder02} className={classes.img} alt='WhySilder02'/>
                            </div>
                            <div className={classes.textWrapper}>
                                <div className={classes.textContainer}>
                                    <Text size={24} fw={700} color='#00145B'>{t('s3_why_02')}</Text>
                                </div>
                            </div>
                        </div>
                    </Carousel.Slide>
                    <Carousel.Slide>
                        <div className={classes.contentContainer}>
                            <div className={classes.imgContainer}>
                                <img src={WhySilder03} className={classes.img} alt='WhySilder03'/>
                            </div>
                            <div className={classes.textWrapper}>
                                <div className={classes.textContainer}>
                                    <Text size={24} fw={700} color='#00145B'>{t('s3_why_03')}</Text>
                                </div>
                            </div>
                        </div>
                    </Carousel.Slide>
                </Carousel>
            </Container>
        </div>
    )
}
