import React, { useState, useEffect } from 'react'
import { createStyles, Text, TextInput, rem, ScrollArea, } from '@mantine/core'
import { IconSearch, IconMapPin, } from '@tabler/icons-react';
import { useDebouncedState, useMediaQuery  } from '@mantine/hooks';
import _ from 'lodash';

import { TranslateText as t } from '../components/Localization/translations';

import { HEADER_HEIGHT } from '../utils/Contants';

let HEADER_HEIGHT_HALF = HEADER_HEIGHT/2;


const useStyles = createStyles((theme) => ({

    root: {
        minHeight: `calc(100svh - ${rem(HEADER_HEIGHT)})`,
        display: 'flex',

        [theme.fn.smallerThan('sm')]: {
            flexDirection: 'column'
        },
    },

    listContainer: {
        width: '30%',
        height: `calc(100svh - ${rem(HEADER_HEIGHT)})`,


        [theme.fn.smallerThan('md')]: {
            width: '40%',
        },
        [theme.fn.smallerThan('sm')]: {
            width: '100%',
            height: `calc(45vh - ${rem(HEADER_HEIGHT_HALF)})`,
        },
    },

    mapContainer: {
        width: '70%',
        height: `calc(100svh - ${rem(HEADER_HEIGHT)})`,
        backgroundColor: '#e5e3df',

        [theme.fn.smallerThan('md')]: {
            width: '60%',
        },
        [theme.fn.smallerThan('sm')]: {
            width: '100%',
            height: `calc(55vh - ${rem(HEADER_HEIGHT_HALF)})`,
        },
    },

    textInput: {
        input:{
            borderBottom: '1px solid #BEBFC7',
            "::placeholder": {

                color: '#BEBFC7',
                fontWeight: 400,
            },

            '&:focus': {
                borderBottom: '1px solid #00145B',
            },
            color: '#00145B'
        },
        label: {
            color: '#00145B',
            fontWeight: 500,
        },
    },

    workshopBtn: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: 10, cursor: 'pointer',
        '&:hover': {
            backgroundColor: '#F2F2F2',
            
        },
    },

    workshopBtnActive: {
        backgroundColor: '#F2F2F2',
    }

}));

const WORKSHOPS = [
    {
        workshop_id: 'test1',
        state: 'Johor',
        name: 'Kow Hock General Auto Services Sdn Bhd',
        embedSrc: 'https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15953.854835035108!2d103.5242!3d1.493072!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31d09f0ea173105b%3A0x33058d468a041e0b!2zS293IEhvY2sgR2VuZXJhbCBBdXRvIFNlcnZpY2VzIFNkbiBCaGQg6auY56aP5py65Lu257u05L-u5pyJ6ZmQ5YWs5Y-4!5e0!3m2!1sen!2smy!4v1697709056290!5m2!1sen!2smy',
    },
    {
        workshop_id: 'test2',
        state: 'Johor',
        name: 'Protech Automaster Sdn Bhd',
        embedSrc: 'https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15952.848474996701!2d103.640593!3d1.625832!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31da714cf3d481ed%3A0xe71d879206c93dab!2sProtech%20Automaster%20Sdn%20Bhd!5e0!3m2!1sen!2smy!4v1697726091830!5m2!1sen!2smy',
    },
    {
        workshop_id: 'test3',
        state: 'Johor',
        name: 'SD Tyres & Car Services Sdn Bhd [1]',
        embedSrc: 'https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d31906.62009693929!2d103.611392!3d1.566338!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31da773a29a569cd%3A0xec33366a20b8e76!2sSD%20Tyres%20%26%20Car%20Services%20Sdn%20Bhd!5e0!3m2!1sen!2smy!4v1697730543796!5m2!1sen!2smy',
    },
    {
        workshop_id: 'test4',
        state: 'Johor',
        name: 'SD Tyres & Car Services Sdn Bhd [2]',
        embedSrc: 'https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d31906.99686358263!2d103.689678!3d1.541397!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31da73cb46ffa9cb%3A0x77e277c2d60799c5!2sSD%20Tyres%20%26%20Car%20Services%20Sdn%20Bhd!5e0!3m2!1sen!2smy!4v1697730611413!5m2!1sen!2smy',
    },
    {
        workshop_id: 'test5',
        state: 'Johor',
        name: 'KLH Auto (M) Sdn Bhd',
        embedSrc: 'https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d31906.986701443435!2d103.688858!3d1.542075!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31da72318e4151ff%3A0x82cc7b3c9d5dffa1!2sKLH%20Auto%20%7C%20Workshop%20%7C%20Car%20Repair%20%7C%20Hybrid%20%7C%20Corporate%20Vehicle!5e0!3m2!1sen!2smy!4v1697730733294!5m2!1sen!2smy',
    },
    {
        workshop_id: 'test6',
        state: 'Johor',
        name: 'Teoh Brothers Auto Service Centre',
        embedSrc: 'https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15950.900561121598!2d103.014053!3d1.856023!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31d0598ca02fc9d1%3A0x272d0db172cd71ab!2sTeoh%20Brothers%20Workshop!5e0!3m2!1sen!2smy!4v1697730815565!5m2!1sen!2smy',
    },
    {
        workshop_id: 'test7',
        state: 'Johor',
        name: 'G Pro Car Service Centre Sdn Bhd',
        embedSrc: 'https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d31907.65260764652!2d103.669172!3d1.496998!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31da73117b4bb0f9%3A0xd321cfab6c3f3904!2sG%20Pro%20Car%20Service%20Centre%20Sdn%20Bhd!5e0!3m2!1sen!2smy!4v1697732906358!5m2!1sen!2smy',
    },
    {
        workshop_id: 'test8',
        state: 'Johor',
        name: 'Auto Wheel Motorworks Sdn Bhd',
        embedSrc: 'https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15953.200590298253!2d103.75967700000001!3d1.580649!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31da6f6323f731bb%3A0xb6c7af124e0849e2!2sAuto%20wheel%20motorworks%20sdn%20bhd!5e0!3m2!1sen!2smy!4v1697732952019!5m2!1sen!2smy',
    },
    {
        workshop_id: 'test9',
        state: 'Johor',
        name: 'Topline Automotive Sdn Bhd',
        embedSrc: 'https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15953.557963296438!2d103.803739!3d1.533431!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31da6c4ffba79e17%3A0xd9db3737013266f!2sTopline%20Automotive%20Sdn%20Bhd!5e0!3m2!1sen!2smy!4v1697732997503!5m2!1sen!2smy',
    },
    {
        workshop_id: 'test10',
        state: 'Kuala Lumpur',
        name: 'Super Home Auto Service Sdn Bhd',
        embedSrc: 'https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15937.297896063292!2d101.568579!3d3.007109!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31cdb2e2eaefc065%3A0xec500529725e39b8!2sSuper%20Home%20Auto%20Service%20Sdn%20Bhd!5e0!3m2!1sen!2smy!4v1697733051142!5m2!1sen!2smy',
    },
    {
        workshop_id: 'test11',
        state: 'Kuala Lumpur',
        name: 'MB One Auto Trading',
        embedSrc: 'https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3983.614697693307!2d101.762085!3d3.195509!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31cc39d0c90defaf%3A0x1293b4a2f5e55e3!2sMB%20One%20Auto%20Trading!5e0!3m2!1sen!2smy!4v1697733089989!5m2!1sen!2smy',
    },
    {
        workshop_id: 'test12',
        state: 'Kuala Lumpur',
        name: 'Le Auto Services',
        embedSrc: 'https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3984.1832903256786!2d101.635329!3d3.045512!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31cc4b46e3e03037%3A0xfb14302714afbbdf!2sLe%20Auto%20Services!5e0!3m2!1sen!2smy!4v1697733137440!5m2!1sen!2smy',
    },
]


export default function Workshop() {
    const matches = useMediaQuery('(max-width: 48em)');

    const { classes, cx } = useStyles();

    const [records, setRecords] = useState([]);
    const [selectedWorkshop, setSelectedWorkshop] = useState(WORKSHOPS[0])

    const [query, setQuery] = useDebouncedState('', 400);

    const onClickWorkshop = (item) => {
        setSelectedWorkshop(item)
    }

    //  Filter
    useEffect(() => {
        setRecords(()=>{
            let filtered = WORKSHOPS.filter(({ state, name }) => {
                if (
                    query !== '' &&
                !`${state} ${name}`
                    .toLowerCase()
                    .includes(query.trim().toLowerCase())
                ) {
                return false;
                }
                return true;
            })
            let groupByState = _.groupBy(filtered, 'state')
            let states = _.keysIn(groupByState);
            let tempArr = [];
            states.forEach((state)=>{
                tempArr.push({
                    'state': state,
                    'workshops': groupByState[state]
                })
            })
            return tempArr;
        });
      }, [query]);

    const workshops = records.map((item, index)=>(
        <div key={index}>
            <div style={{backgroundColor: '#C7C7D0', marginTop: 10, padding: 10, paddingTop: 5, paddingBottom: 5}}>
                <Text size={14} fw={700} color='#001458'>{item.state}</Text>
            </div>
            {item.workshops.map((item) => (
                <div key={item.workshop_id} onClick={()=>onClickWorkshop(item)} className={cx(classes.workshopBtn, {[classes.workshopBtnActive] : selectedWorkshop.workshop_id === item.workshop_id})}>
                    <Text size={14} color='#001458'>{item.name}</Text>
                    {selectedWorkshop.workshop_id === item.workshop_id ?
                        <IconMapPin size={20} color='#174AFF'/>
                        : null
                    }
                </div>
            ))
            }
        </div>
    ))

    return (
        <div className={classes.root}>
            <div className={classes.listContainer}>
                <ScrollArea h={matches ? `calc(45vh - ${rem(HEADER_HEIGHT_HALF)})` : `calc(100svh - ${rem(HEADER_HEIGHT)})`}>
                    <div style={{padding: 20,}}>
                        <div style={{display: 'flex'}}>
                            <div style={{flex: 1}}>
                                <TextInput
                                    placeholder={t('find_workshops')}
                                    variant='unstyled'
                                    className={classes.textInput}
                                    rightSection={<IconSearch size="14" />} 
                                    onChange={(event) => setQuery(event.currentTarget.value)}
                                />
                            </div>
                            {/* <Button ml={'sm'} color='brandLBlue.0' variant="outline" radius="xl" >Filter</Button> */}
                        </div>
                        { workshops }
                    </div>
                </ScrollArea>
            </div>
            <div className={classes.mapContainer}>
                <iframe title='Google Map' src={selectedWorkshop?.embedSrc} width="100%" height="100%" style={{border:0}} allowFullScreen loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
            </div>
        </div>
    )
}
