import React from 'react'
import { createStyles, Container, TypographyStylesProvider, rem  } from '@mantine/core'

import { PRIVACY_POLICY } from '../utils/Terms'

import { HEADER_HEIGHT } from '../utils/Contants';

const useStyles = createStyles((theme) => ({
  
    docsWrapper: {
        backgroundColor: '#E6E6EF',
        minHeight: `calc(100vh - ${rem(HEADER_HEIGHT)})`,
    },

    docsContainer: {
        minHeight: `calc(100vh - ${rem(HEADER_HEIGHT)})`,
        padding: 40,
        backgroundColor: '#FFFFFF',
        boxShadow: '0px 1px 4px 0px rgba(0, 0, 0, 0.15);',
        [theme.fn.smallerThan('xs')]: {
            padding: 20,
        },
    }

}));

export default function PrivacyPolicy() {
    const { classes } = useStyles();
    return (
        <div className={classes.docsWrapper}>
            <Container size={'sm'} className={classes.docsContainer}>
                <TypographyStylesProvider>
                    <div dangerouslySetInnerHTML={{ __html: PRIVACY_POLICY }} />
                </TypographyStylesProvider>
            </Container>
        </div>
    )
}
