import { forwardRef } from 'react';
import { Group, Text, Image  } from '@mantine/core';

export const PaymentSelectItem = forwardRef(
    ({ image, label, description, ...others }, ref) => (
      <div ref={ref} {...others}>
        <Group noWrap>
          {/* <Avatar src={image} /> */}
          <Image
                height={40}
                width={100}
                fit='contain'
                src={image}
                alt={label}
                withPlaceholder
                placeholder={<Text align="center">No Image</Text>}
                style={{backgroundColor: '#FFF'}}
            />
          <div>
            <Text size="sm">{label}</Text>
          </div>
        </Group>
      </div>
    )
);