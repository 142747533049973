import axios from "axios";
import { API_URL, API_TOKEN } from "../utils/Contants";

axios.defaults.withCredentials = true

export default axios.create({
    baseURL: API_URL,
})

export const axiosPrivate = axios.create({
    baseURL: API_URL,
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${API_TOKEN}` },
    withCredentials: true
})