export const BASE_API_URL = 'http://localhost:1337/api'
export const WEBSITE_URL = 'https://xtenz.com.my/'

// export const BASE_URL = 'https://mycar-warranty.com'
export const API_URL = 'https://mycar-warranty.com/api'
export const API_TOKEN = 'my_0444b10b5be74146ab0497b109b00eac'

//PURCHASE
export const PURCHASE_URL = 'https://xtenzadmin.link/register-warranty'

export const PURCHASE_PROMO_URL = 'https://xtenzadmin.link/register'

//IOS
export const APP_STORE_URL = `https://apps.apple.com/us/app/xtenz-warranty/id6462845818`;
//ANDROID
export const PLAY_STORE_URL = `https://play.google.com/store/apps/details?id=com.XTENZ.Warranty`;

export const INSTAGRAM_URL = ``;
export const FACEBOOK_URL = ``;
export const TIKTOK_URL = ``;
export const LINKEDIN_URL = ``;

export const HEADER_HEIGHT = 70;

export const WA_URL = `https://bit.ly/xtenzwarrantybsnpromotion`

export const BSN_PAYMENT = 'BSN_PAYMENT'
export const MAIN_PAYMENT = 'MAIN_PAYMENT'