import { useState, useEffect } from 'react'
import {
    TextInput,
    Paper,
    Container,
    Group,
    Button,
    Stepper,
    Flex,
    Text,
    Box,
    Loader,
    Center,
    Alert,
    Select,
    SegmentedControl,
    Grid
} from '@mantine/core';
import { useForm } from '@mantine/form'
import axios from '../../api/axios';
import { IconAlertCircle, IconBrandWhatsapp, IconCrown } from "@tabler/icons-react";

import { useNavigate, useSearchParams } from 'react-router-dom';
import { useQuery, useMutation } from 'react-query';

import ConfirmationModal from '../../components/General/ConfirmationModal';
import usePaymentIDs from '../../components/Hooks/usePaymentIDs';
import TermsConditionsModal from '../../components/Register/TermsConditionsModal';

import LogoTagline from '../../assets/logo/LogoTagline.png';

import { WA_URL, MAIN_PAYMENT } from '../../utils/Contants';

export default function RegisterWarranty() {
    const [searchParams] = useSearchParams();
    const { setPaymentIDs } = usePaymentIDs();

    const [allPlan, setAllPlan] = useState([])
    const [openCModal, setOpenCModal] = useState(false);
    const [openDModal, setOpenDModal] = useState(false);
    const [active, setActive] = useState(0);
    const nextStep = () => setActive((current) => (current < 3 ? current + 1 : current));
    const prevStep = () => setActive((current) => (current > 0 ? current - 1 : current));

    const [plan, setPlan] = useState(null);

    const [userDetails, setUserDetails] = useState(null);

    const [message, setMessage] = useState(null);

    let navigate = useNavigate();

    const planDetails = useQuery(
        "planDetails",
        async ({signal}) => (await axios.get("/plan/get-all-plans?platform=1", {signal})).data.plans,
        {
            initialData: [],
            onSuccess: (res)=>{
                setAllPlan(res)
            }
        }
    );


    const registerMutation = useMutation(
        (data) => axios.post(`/web-be/register`, data),
        {
            onSuccess: (res) => {
                setPaymentIDs({uid: res.data.uid, vehicle_id: res.data.vehicle_id, type: MAIN_PAYMENT})
                navigate("/payment")
            },
            onError: (error) => {
                if (error.response) {
                    // prevStep()
                    // The request was made and the server responded with a status code
                    // that falls out of the range of 2xx
                    if (error.response.status !== 0) {
                        if(error.response.data.message !== undefined){
                            setMessage(error.response.data.message);
                        }
                        else{
                            setMessage('Something went wrong while connecting to the server');
                        }
                    }
                    else {
                        setMessage('Something went wrong while connecting to the server');
                    }
                } else {
                    setMessage('Something went wrong while connecting to the server');
                }
            },
        }
    );

    const onNextClick = async (value) => {
        if(active === 0){
            nextStep()
        }
        if(active === 1){
            setUserDetails(value)
            setOpenCModal(true)
        }
        if(active === 2){
            setOpenCModal(true)
        }
    };


    const onSubmit = async () => {
        setOpenCModal(false)
        // console.log(userDetails)
        const formData = new FormData();
        formData.append('fullname', userDetails.fullname.trim());
        formData.append('nric', userDetails.nric)
        formData.append('email', userDetails.email.trim())
        formData.append('phone_number', userDetails.phone_number.trim())
        formData.append('address_line_1', userDetails.address_line_1.trim())
        formData.append('address_line_2', userDetails.address_line_2.trim())
        formData.append('postal_code', userDetails.postal_code.trim())
        formData.append('city', userDetails.city.trim())
        formData.append('state', userDetails.state.trim())
        
        formData.append('plateNumber', userDetails.plateNumber.trim())
        formData.append('plan_id', plan?.plan_id)
        formData.append('isMyVehicle', userDetails.isMyVehicle === 'yes' ? 1 : 0)

        // console.log(submitData)
        registerMutation.mutate(formData)
    }

    const form = useForm({
        initialValues: { fullname: '', nric: '',  phone_number: '', email: '', plateNumber: '', address_line_1: '', address_line_2: '', postal_code: '', city: '', state: '', isMyVehicle: 'yes'},
    
        validate: {
            fullname: (value) => (value.trim().length < 1 ? 'Please fill in the empty field' : null),
            nric: (value) => (value.trim().length < 1 ? 'Please fill in the empty field' : value.trim().length < 12 ? 'Invalid Identification Card Number' : !/^\d*$/.test(value.trim()) ? 'Invalid Identification Card Number' : null),
            email: (value) => (value.trim().length < 1 ? 'Please fill in the empty field' : /^\S+@\S+$/.test(value.trim()) ? null : 'Invalid Email Address'),
            phone_number: (value) => (value.trim().length < 1 ? 'Please fill in the empty field' : value.trim().length < 9 ? 'Invalid Phone Number' : !/^\d*$/.test(value.trim()) ? 'Invalid Phone Number' : null),
            plateNumber: (value) => (value.trim().length < 1 ? 'Please fill in the empty field' : null),
            address_line_1: (value) => (value.trim().length < 1 ? 'Please fill in the empty field' : null),
            postal_code: (value) => (value.trim().length < 1 ? 'Please fill in the empty field' : null),
            city: (value) => (value.trim().length < 1 ? 'Please fill in the empty field' : null),
            state: (value) => (value.trim().length < 1 ? 'Please fill in the empty field' : null),
        },
    });

    useEffect(()=>{
        const checkParams = () => {
            let getPlan = searchParams.get("plan")
            if(getPlan){
                let index = allPlan.findIndex((plan)=>{return plan?.name.toLowerCase() === getPlan.toLowerCase()})
                if(index === -1){
                    setPlan(allPlan[0])
                    return
                }
                setPlan(allPlan[index])
                setActive(1)
            }
            else{
                setPlan(allPlan[0])
            }
        }
        if(allPlan.length > 0){
            checkParams()
        }
    }, [searchParams, allPlan])
    console.log(plan)

  return (
    <>
    {/*Delete Confirmation Modal */}
    <TermsConditionsModal opened={openCModal} onClose={() => setOpenCModal(false)} approveBtnText={'Proceed'} onClick={onSubmit} />
    {/*Disagree Confirmation Modal */}
    <ConfirmationModal opened={openDModal} onClose={() => setOpenDModal(false)} text="Are you sure? You cannot purchase warranty without agreeing to our terms and conditions" approveBtnText={'Yes'}  onClick={() => setOpenDModal(false)} />
    <a href={WA_URL} target="_blank" rel="noreferrer" style={{
        position: 'fixed',
        width: '50px',
        height: '50px',
        bottom: 15,
        right: 15,
        backgroundColor: '#1ad03f',
        borderRadius: '50px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        boxShadow: '2px 2px 3px #999',
        zIndex: 9999,
    }}>
        <IconBrandWhatsapp size={32} color={'#fff'}/>
    </a>
    <div style={{display: 'table',
                position: 'absolute',
                top: 0,
                left: 0,
                height: '100%',
                width: '100%'}}>
        <div style={{display: 'table-cell', verticalAlign: 'middle'}}>
            <Container size={700} my={40}>
                <div align="center">
                    <img src={LogoTagline} alt="Logo" width={350}/>
                </div>
                <Paper withBorder shadow="md" p={30} mt={30} >
                    <Stepper color="#54FF00" active={active} onStepClick={setActive} allowNextStepsSelect={false} breakpoint="sm" size="sm">
                        <Stepper.Step label="Warranty" description="Choose a Plan" allowStepSelect={false}>

                                <Text fw={500} pt={10}> Choose a plan </Text>
                                {/* <Box
                                    // key={data?.id}
                                    sx={(theme) => ({
                                        // backgroundColor: data?.plan === 'silver' ? "#A7B3BF" : data?.plan === 'gold' ? '#FDB83F' : data?.plan === 'platinum' ? '#3C317F' : '#A7B3BF',
                                        // padding: theme.spacing.xl,
                                        // cursor: 'pointer',
                                        marginTop: 10,
                                        boxShadow: theme.shadows.xs,
                                        borderStyle: 'solid',
                                        borderRadius: 10,
                                        borderWidth:  4,
                                        borderColor: '#54FF00',
                                        overflow: 'hidden'
                                        // maxWidth: 500,
                                    })} 
                                    // onClick={() => setPlan(data?.plan)}
                                >
                                    <Image src={BSNPackage}/>
                                </Box> */}
                                {planDetails.isFetching ? 
                                    <Center style={{ height: 200 }}>
                                        <Loader color="red" />
                                    </Center>
                                : planDetails.isError ?
                                    <Center style={{ height: 200 }}>
                                        <Text fz="xl" fw={500} color="red" align="center"> Could Not Retrieve Plan </Text>
                                    </Center>
                                :
                                    <Group pt={12} position="center">
                                        {planDetails.data.length > 0 ?
                                        <>
                                            {planDetails.data.map((data) =>
                                            // Only do this if items have no stable IDs
                                                <Box
                                                    key={data?.id}
                                                    sx={(theme) => ({
                                                        backgroundColor: data?.planColor,
                                                        padding: theme.spacing.xl,
                                                        cursor: 'pointer',
                                                        boxShadow: theme.shadows.xs,
                                                        borderStyle: 'solid',
                                                        borderRadius: 10,
                                                        borderWidth:  4,
                                                        borderColor: plan?.plan === data?.plan ? '#54FF00' : '#FFFFFF',
                                                        // maxWidth: 500,
                                                    })} 
                                                    onClick={() => setPlan(data)}
                                                >
                                                    <Flex
                                                        justify="center"
                                                        align="center"
                                                        direction="column"
                                                        wrap="wrap"
                                                    >
                                                        <IconCrown color="#FFFFFF"/>
                                                        <Text fz="xl" fw={700} color="#FFFFFF" tt="capitalize"> {data?.plan} </Text>
                                                        <Text fz="lg" fw={700} color="#FFFFFF" pb={10}> RM{data?.price}/year</Text>
                                                        {
                                                            data.description.map((desc, index) => 
                                                                <div key={index} style={{width: '95%', display: 'flex'}}>
                                                                    <Text color="#FFFFFF" pr={5}>•</Text>
                                                                    <Text color="#FFFFFF">{desc} </Text>
                                                                </div>
                                                            )
                                                        }
                                                    </Flex>
                                                </Box>
                                            )}
                                        </>
                                        :
                                        <Center style={{flexDirection: 'column', backgroundColor: '#FAFAFA', width: '100%', minHeight: 200}}>
                                            <Text fz="xl" fw={500}>Under Maintenance</Text>
                                            <Text fz="md" fw={500} color='gray' mt={12}>We are updating the plan details</Text>
                                        </Center>
                                        }
                                    </Group>
                                }     
                                {/* 
                                <Text fw={500} pt={10}> Enter Personal Details </Text>
                                <TextInput mt={20} label="Full Name" description="As Per NRIC"  {...form.getInputProps('full_name')} />
                                <TextInput mt="xs" label="NRIC" description="Eg: 990101141919" maxLength={12} {...form.getInputProps('nric')} />
                                <TextInput mt="xs" label="Email"  {...form.getInputProps('email')} />
                                <TextInput mt="xs" label="Phone Number" description="Eg: +60 193328617" maxLength={10} {...form.getInputProps('phone_no')} icon={<Text size={14} sx={(theme) => ({color: theme.colorScheme === 'light' ? '#000' : '#FFF'})}>+60</Text>}/>
                                */}
                                <Group position="center">
                                    <Button onClick={nextStep} mt="xl" color='XTENZBlue'
                                    disabled={planDetails.isError || planDetails.isFetching || !plan}
                                    > Next </Button>
                                </Group>
                        </Stepper.Step>
                        
                        <Stepper.Step loading={registerMutation.isLoading} label="Registration" description="Enter Details" allowStepSelect={false}>
                            <form onSubmit={form.onSubmit((values) => onNextClick(values))}> 
                                <Box sx={(theme) => ({
                                    position: 'relative',
                                    backgroundColor: plan?.plan_color,
                                    padding: theme.spacing.xs,
                                    paddingLeft: theme.spacing.md,
                                    paddingRight: theme.spacing.md,
                                    borderRadius: theme.radius.sm,
                                    marginBottom: 10,
                                    marginTop: 10,
                                })}>
                                    <Flex justify="space-between">
                                        <Text fw={500} color="#FFFFFF" tt="capitalize" fz="lg">Selected Plan</Text>
                                        <Text fw={500} color="#FFFFFF" tt="capitalize" fz="lg">{plan?.name}</Text>
                                    </Flex>
                                </Box>
                                {message && 
                                    <Alert mt={10} mb={10} icon={<IconAlertCircle size={16} />} title="Error" color="red" withCloseButton onClose={() => setMessage('')}>
                                        {message}
                                    </Alert>
                                }
                                <Text fw={500} pt={10}> Personal Details </Text>
                                <Text fw={500} pt={10} size='sm'> Did you purchase this warranty for your own vehicle? </Text>
                                <Grid>
                                    <Grid.Col xs={6} sm={6} md={4} lg={4} xl={4} pb={0}>
                                        <SegmentedControl
                                        color='XTENZBlue'
                                        // size='sm'
                                        data={[
                                            { label: 'Yes', value: 'yes' },
                                            { label: 'No', value: 'no' },
                                        ]}
                                        {...form.getInputProps('isMyVehicle')}
                                        fullWidth
                                        />
                                    </Grid.Col>
                                    <Grid.Col sm={6} md={6} lg={6} xl={6} pt={0}></Grid.Col>
                                </Grid>
                                
                                <TextInput mt={10} label="Full Name" description="As Per NRIC"  {...form.getInputProps('fullname')} />
                                <TextInput mt="xs" label="NRIC" description="Eg: 990101141919" maxLength={12} {...form.getInputProps('nric')} />
                                <TextInput mt="xs" label="Email"  {...form.getInputProps('email')} />
                                <TextInput mt="xs" label="Phone Number" description="Eg: +60 193328617" maxLength={10} {...form.getInputProps('phone_number')} icon={<Text size={14} sx={(theme) => ({color: theme.colorScheme === 'light' ? '#000' : '#FFF'})}>+60</Text>}/>
                                <TextInput mt="xs" label="Car Plate Number" description="Eg: WVW1234" {...form.getInputProps('plateNumber')} />
                                <Text fw={500} pt={20}> Shipping Address </Text>
                                <TextInput mt={10} label="Address Line 1"  {...form.getInputProps('address_line_1')} />
                                <TextInput mt="xs" label="Address Line 2" placeholder="Optional" {...form.getInputProps('address_line_2')} />
                                <Grid>
                                    <Grid.Col sm={6} md={6} lg={6} xl={6} pb={0}>
                                        <TextInput mt="xs" label="Post Code" maxLength={5} {...form.getInputProps('postal_code')} />
                                    </Grid.Col>
                                    <Grid.Col sm={6} md={6} lg={6} xl={6} >
                                        <TextInput mt="xs" label="City" {...form.getInputProps('city')} />
                                    </Grid.Col>
                                </Grid>
                                <Select
                                    mt="xs"
                                    label="State"
                                    placeholder="Pick one"
                                    data={[
                                        { value: 'Johor', label: 'Johor' },
                                        { value: 'Kedah', label: 'Kedah' },
                                        { value: 'Kelantan', label: 'Kelantan' },
                                        { value: 'Kuala Lumpur', label: 'Kuala Lumpur' },
                                        { value: 'Labuan', label: 'Labuan' },
                                        { value: 'Malacca', label: 'Malacca' },
                                        { value: 'Negeri Sembilan', label: 'Negeri Sembilan' },
                                        { value: 'Pahang', label: 'Pahang' },
                                        { value: 'Penang', label: 'Penang' },
                                        { value: 'Perak', label: 'Perak' },
                                        { value: 'Perlis', label: 'Perlis' },
                                        { value: 'Putrajaya', label: 'Putrajaya' },
                                        { value: 'Sabah', label: 'Sabah' },
                                        { value: 'Sarawak', label: 'Sarawak' },
                                        { value: 'Selangor', label: 'Selangor' },
                                        { value: 'Terengganu', label: 'Terengganu' },
                                    ]} {...form.getInputProps('state')}
                                />
                                 
                                {/* <PasswordInput mt={40} label="Password"  {...form.getInputProps('password')} />
                                <PasswordInput mt="xs" mb="sm" label="Re-enter Password"  {...form.getInputProps('confirm_password')} />  */}
                                <Group position="center">
                                    <Button onClick={prevStep} mt="xl" color='gray' disabled={registerMutation.isLoading}> Back </Button>
                                    <Button type="submit" mt="xl" disabled={registerMutation.isLoading} color='XTENZBlue'> Submit Details </Button>
                                </Group>
                            </form>
                        </Stepper.Step>
                        {/*
                        <Stepper.Step loading={registerMutation.isLoading} label="Agreement" description="Terms & Condition" allowStepSelect={false}>
                            <TypographyStylesProvider>
                                <div dangerouslySetInnerHTML={{__html: OVERVIEW + "</br>" + TERMS_AND_CONDITIONS}}/>
                            </TypographyStylesProvider>
                            <Group position="center">
                                <Button onClick={prevStep} mt="xl" disabled={registerMutation.isLoading} color='gray'> Back </Button>
                                <Button onClick={onDisagreeClick} mt="xl" disabled={registerMutation.isLoading} color='dark'> Disagree </Button>
                                <Button onClick={onNextClick} mt="xl" loading={registerMutation.isLoading}> Agree & Proceed </Button>
                            </Group>
                        </Stepper.Step>
                        */}
                        <Stepper.Step label="Payment" description="Payment Method" allowStepSelect={false}></Stepper.Step>
                    </Stepper>    
                </Paper>
            </Container>
        </div>
    </div>
    </>
  );
}
