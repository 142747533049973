import { createContext } from "react";

const PaymentIDsContext = createContext({})

export const PaymentIDsProvider = ({ paymentIDs, setPaymentIDs, children }) => {
    
    return (
        <PaymentIDsContext.Provider value={{ paymentIDs, setPaymentIDs }}>
            {children}
        </PaymentIDsContext.Provider>
    )
}

export default PaymentIDsContext