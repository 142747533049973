import React, { useState, useEffect } from 'react'
import { createStyles, Container, Text, Image, Space } from '@mantine/core'
import { IconCheck } from '@tabler/icons-react';

import IconHow01 from '../../assets/icons/IconHow01.webp'
import IconHow02 from '../../assets/icons/IconHow02.webp'
import IconHow03 from '../../assets/icons/IconHow03.webp'
import IconHow04 from '../../assets/icons/IconHow04.webp'
import IconHow05 from '../../assets/icons/IconHow05.webp'

import IconArrowBlueTR from '../../assets/icons/IconArrowBlueTR.png'

import { TranslateText as t } from '../Localization/translations';

const useStyles = createStyles((theme) => ({

    container: {
        padding: 100,

        [theme.fn.smallerThan('sm')]: {
            paddingLeft: 50,
            paddingRight: 50,
        },
    },

    section4ArrowImg: {
        width: 122,
        height: 122,
        position: 'absolute',
        bottom: 50,
        left: 50,

        [theme.fn.smallerThan('sm')]: {
            width: 61,
            height: 61,
            position: 'absolute',
            bottom: 25,
            left: 25,
        },
    },

    stepperContainer: {
        backgroundColor: '#82828B', height: 2, width: '100%', position: 'relative',
    },

    stepperProgress: {
        backgroundColor: '#80FB41', height: 2, transition: 'all 0.5s ease', 
    },

    stepperButtonContainer: {
        position: 'absolute', top: -25, marginLeft: -25, display: 'flex', flexDirection: 'column', alignItems: 'center',
        [theme.fn.smallerThan('xs')]: {
            top: -15, marginLeft: -15
        },
    },

    stepperButton: {
        width: 50, height: 50, borderRadius: 50, backgroundColor: '#FFFFFF', border: '2px solid #82828B', display: 'flex', justifyContent: 'center', alignItems: 'center', transition: 'all 0.5s ease', cursor: 'pointer',
        [theme.fn.smallerThan('xs')]: {
            width: 30, height: 30,
        },
    },

    stepperButtonActive: {
        backgroundColor: '#80FB41', border: '2px solid #80FB41',
    },

    howToContentContainer: {
        display: 'flex', flexDirection: 'column', alignItems: 'center'
    },
    
}));

export default function Section04() {
    const { classes, cx } = useStyles();

    const [step, setStep] = useState(0)
    
    useEffect(()=>{
        let timeout = setTimeout(()=> {
            setStep((prev)=>{
                if(prev === 4){
                    return 0
                }
                return prev+1
            })
        }, 5000)

        return () => {
            clearTimeout(timeout);
        };
    }, [step])

    return (
        <div style={{position: 'relative'}}>
            <img src={IconArrowBlueTR} alt='Icon Arrow' className={classes.section4ArrowImg}/>
            <Container size={'xl'} className={classes.container}>
                <Text align='center' sx={{fontFamily: 'Magistral', fontWeight: 400, color: '#3547F8'}} size={40}>{t('s4_title_01')} <span style={{fontWeight: 700, color: '#80FB41'}}>XTENZ</span> {t('s4_title_02')}</Text>
                <Space h={75}/>
                <div className={classes.stepperContainer}>
                    <div className={classes.stepperProgress} style={{width: `${step/4*100}%`}}/>
                    <div className={cx(classes.stepperButtonContainer)} style={{left: '0%',}}>
                        <div onClick={()=>setStep(0)} className={cx(classes.stepperButton, { [classes.stepperButtonActive]: step >= 0 })}>
                            <IconCheck size={30} color={step >= 0 ? '#FFFFFF' : '#82828B'}/>
                        </div>
                        <Space h={8}/>
                        <Text size={14} color={step >= 0 ? '#80FB41' : '#82828B'}>{t('s4_step')} 1</Text>
                    </div>
                    <div className={cx(classes.stepperButtonContainer)} style={{left: '25%',}}>
                        <div onClick={()=>setStep(1)} className={cx(classes.stepperButton, { [classes.stepperButtonActive]: step >= 1 })}>
                            <IconCheck size={30} color={step >= 1 ? '#FFFFFF' : '#82828B'}/>
                        </div>
                        <Space h={8}/>
                        <Text size={14} color={step >= 1 ? '#80FB41' : '#82828B'}>{t('s4_step')} 2</Text>
                    </div>
                    <div className={cx(classes.stepperButtonContainer)} style={{left: '50%',}}>
                        <div onClick={()=>setStep(2)} className={cx(classes.stepperButton, { [classes.stepperButtonActive]: step >= 2 })}>
                            <IconCheck size={30} color={step >= 2 ? '#FFFFFF' : '#82828B'}/>
                        </div>
                        <Space h={8}/>
                        <Text size={14} color={step >= 2 ? '#80FB41' : '#82828B'}>{t('s4_step')} 3</Text>
                    </div>
                    <div className={cx(classes.stepperButtonContainer)} style={{left: '75%',}}>
                        <div onClick={()=>setStep(3)} className={cx(classes.stepperButton, { [classes.stepperButtonActive]: step >= 3 })}>
                            <IconCheck size={30} color={step >= 3 ? '#FFFFFF' : '#82828B'}/>
                        </div>
                        <Space h={8}/>
                        <Text size={14} color={step >= 3 ? '#80FB41' : '#82828B'}>{t('s4_step')} 4</Text>
                    </div>
                    <div className={cx(classes.stepperButtonContainer)} style={{left: '100%',}}>
                        <div onClick={()=>setStep(4)} className={cx(classes.stepperButton, { [classes.stepperButtonActive]: step >= 4 })}>
                            <IconCheck size={30} color={step >= 4 ? '#FFFFFF' : '#82828B'}/>
                        </div>
                        <Space h={8}/>
                        <Text size={14} color={step >= 4 ? '#80FB41' : '#82828B'}>{t('s4_step')} 5</Text>
                    </div>
                </div>
                <Space h={100}/>
                <div className={classes.howToContentContainer}>
                    {step === 0 ?
                    <>
                        <Image width={120} height={120} fit='contain' src={IconHow01} alt="With default placeholder" withPlaceholder />
                        <Space h={50}/>
                        <Text size={28} color={'#134BF7'} fw={600} align='center'>{t('s4_step_title_01')}</Text>
                        <Text size={20} color={'#00145B'} align='center'>{t('s4_step_descr_01')}</Text>
                    </>
                    :
                    step === 1 ?
                    <>
                        <Image width={120} height={120} fit='contain' src={IconHow02} alt="With default placeholder" withPlaceholder />
                        <Space h={50}/>
                        <Text size={28} color={'#134BF7'} fw={600} align='center'>{t('s4_step_title_02')}</Text>
                        <Text size={20} color={'#FFF'} align='center'>{t('s4_step_descr_02')}</Text>
                    </>
                    :
                    step === 2 ?
                    <>
                        <Image width={120} height={120} fit='contain' src={IconHow03} alt="With default placeholder" withPlaceholder />
                        <Space h={50}/>
                        <Text size={28} color={'#134BF7'} fw={600} align='center'>{t('s4_step_title_03')}</Text>
                        <Text size={20} color={'#FFF'} align='center'>{t('s4_step_descr_03')}</Text>
                    </>
                    :
                    step === 3 ?
                    <>
                        <Image width={120} height={120} fit='contain' src={IconHow04} alt="With default placeholder" withPlaceholder />
                        <Space h={50}/>
                        <Text size={28} color={'#134BF7'} fw={600} align='center'>{t('s4_step_title_04')}</Text>
                        <Text size={20} color={'#00145B'} align='center'>{t('s4_step_descr_04')}</Text>
                    </>
                    :
                    <>
                        <Image width={120} height={120} fit='contain' src={IconHow05} alt="With default placeholder" withPlaceholder />
                        <Space h={50}/>
                        <Text size={28} color={'#134BF7'} fw={600} align='center'>{t('s4_step_title_05')}</Text>
                        <Text size={20} color={'#00145B'} align='center'>{t('s4_step_descr_05')}</Text>
                    </>
                    }
                </div>
            </Container>
        </div>
    )
}
