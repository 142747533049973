import React from 'react'
import { createStyles, Container, rem , Space, Text, Grid } from '@mantine/core'
import { useNavigate } from 'react-router-dom';

import { HEADER_HEIGHT } from '../utils/Contants';

import BSNBanner from '../assets/images/BSNBanner.webp'

import { formatMMMM_D_yyyy } from '../utils/ConvertDateFormat';

import { TranslateText as t } from '../components/Localization/translations';

const useStyles = createStyles((theme) => ({
  
    root: {
        backgroundColor: '#E6E6EF',
        minHeight: `calc(100vh - ${rem(HEADER_HEIGHT)})`,
    },

    promotionBtn: {
        display: 'flex', height: '100%', flexDirection: 'column', backgroundColor: '#FFFFFF', borderRadius: 20, boxShadow: '0px 0px 4px 2px rgba(0, 0, 0, 0.15)', overflow: 'hidden', cursor: 'pointer'
    },

    promotionContentContainer: {
        display: 'flex', flexDirection: 'column', justifyContent: 'space-between', flex: 1,padding: 20,
    }

}));

const PROMOTIONS = [
    {
        promotion_id: 'bsn',
        category: 'LATEST PROMOTIONS',
        title: 'BSN Cardmembers Special Promo RM299 Car Warranty Plan',
        imgUrl: BSNBanner,
        date: '2023-09-01T22:00:00.000+08:00'
    },
]

export default function Promotion() {
    const { classes } = useStyles();
    const navigate = useNavigate()

    const onClickPromotion = (id) => {
        navigate(`/promotion/${id}`)
    }

  return (
    <div className={classes.root}>
        <Container py={30}>
            <Space h={20}/>
                <Text sx={{fontFamily: 'Magistral', fontWeight: 700, color: '#3547F8'}} size={40} align='center'>{t('promotion_title')}</Text>
            <Space h={50}/>
            <Grid gutter={'xl'}>
                {PROMOTIONS.map((item, index)=>(
                    <Grid.Col onClick={()=>onClickPromotion(item.promotion_id)} key={index} span={12} xs={12} sm={6} md={6} lg={4}>
                        <div className={classes.promotionBtn}>
                            <img src={item.imgUrl} style={{width: '100%'}} alt={'Promotion'}/>
                            <div className={classes.promotionContentContainer}>
                                <Text size={12} fw={700} c={'#00145B'}>{item.category}</Text>
                                <Space h={20}/>
                                <Text size={16} c={'#00145B'} fw={800} lh={1}>{item.title}</Text>
                                <Space h={20}/>
                                <Text size={12} fw={700} c={'#00145B'} transform='uppercase'>{formatMMMM_D_yyyy(item.date)}</Text>
                            </div>
                        </div>
                    </Grid.Col>
                ))
                }
            </Grid>
        </Container>
    </div>
  )
}
