import React from 'react'
import { createStyles, Container, Text, Space, Grid, Group, ScrollArea } from '@mantine/core'

import ProfileBossJames from '../../assets/images/ProfileBossJames.webp'
import ProfileBossSimon from '../../assets/images/ProfileBossSimon.webp'
import ProfileCarlson from '../../assets/images/ProfileCarlson.webp'
import ProfileKevinOoi from '../../assets/images/ProfileKevinOoi.webp'
import ProfileNormanPang from '../../assets/images/ProfileNormanPang.webp'

import IconArrowBlueTL from '../../assets/icons/IconArrowBlueTL.png'

import { TranslateText as t } from '../Localization/translations'

const useStyles = createStyles((theme) => ({

    container: {
        position: 'relative',
        paddingTop: 50,
        paddingBottom: 100,

        [theme.fn.smallerThan('sm')]: {
            paddingLeft: 50,
            paddingRight: 50,
        },
        [theme.fn.smallerThan('xs')]: {
            paddingLeft: 25,
            paddingRight: 25,
        },
    },
    
    achievementsContainer: {
        position: 'absolute', width: '100%', height: '100%', backgroundColor: 'rgba(230, 230, 239, 0.90)', opacity: 0,
        transition: 'all 0.5s ease',
        cursor: 'pointer',
        '&:hover': {
            opacity: 1,
        },
    },

    profileImg: {
        width: '80%', objectFit: 'contain',
        // [theme.fn.smallerThan('md')]: {
        //     width: '80%',
        // },
        [theme.fn.smallerThan('sm')]: {
            width: '60%',
        },
        [theme.fn.smallerThan('xs')]: {
            width: '80%',
        },
    },

    arrowImg: {
        width: 122,
        height: 122,
        position: 'absolute',
        top: -50,
        right: 50,

        [theme.fn.smallerThan('sm')]: {
            width: 61,
            height: 61,
            position: 'absolute',
            top: -50,
            right: 25,
        },
    },

}));



export default function Section07({targetRef}) {
    const { classes } = useStyles();

    const TEAM_MEMBER = [
    {
        name: 'Carlson Chia',
        position: t('s7_founder'),
        profile: ProfileCarlson,
        achievements: [
            {
                year: '2021',
                awards: ['G Forty Top 40 Corporate Achievement','World Chinese Top Ten Young Entrepeneur Award']
            },
            {
                year: '2022',
                awards: ['Asia Legendary Young Entrepeneur Award','Nanyang Business Friendship Ambassador']
            }
        ]
    },
    {
        name: 'Kevin Ooi',
        position: t('s7_co_founder'),
        profile: ProfileKevinOoi,
        achievements: [
            {
                year: '2021',
                awards: ['G Forty Top 40 Corporate Achievement']
            },
            {
                year: '2022',
                awards: ['The 10th Malaysia Central Region']
            },
            {
                year: '2023',
                awards: ['Most Impactful Rising Star Award']
            }
        ]
    },
    {
        name: 'Boss Simon',
        position: t('s7_co_founder'),
        profile: ProfileBossSimon,
        achievements: [
            {
                year: '2014',
                awards: ['Logitech Best G Award First Runner Up']
            },
            {
                year: '2014-2015',
                awards: ['Asus Million Dollar Award – Networking']
            },
            {
                year: '2018',
                awards: ['Tech Titan Outstanding Award (Central Region)', '	Thermaltake Best Performance & Partner Award']
            },
            {
                year: '2022',
                awards: ['Malaysia Book Of Record – Longest After Sales PC Warranty By A Company']
            },
        ]
    },
    {
        name: 'Dato Sri Norman Pang',
        position: t('s7_ambassador'),
        profile: ProfileNormanPang,
        achievements: [
            {
                year: '2006-2020',
                awards: ['Produced impressive 400 MDRTs from 2006 to 2020 Personal achievement: 16 MDRT & 1 COT']
            },
            {
                year: '2009-2020',
                awards: ['NPG achieved 23 times (2009-2020) Consecutive']
            },
            {
                year: '2012',
                awards: ['2012 JCI Ten Outstanding Young Malaysian Award']
            },
            {
                year: '',
                awards: ['Champion of Allianz Life Insurance Malaysia Berhad', 'Owned 10 Insurance Agency in Malaysia']
            },

        ]
    },
    {
        name: 'Boss James',
        position: t('s7_ambassador'),
        profile: ProfileBossJames,
        achievements: [
            {
                year: '',
                awards: ['10 Malaysia Book of Records']
            },
            {
                year: '2021',
                awards: ['World Chinese Economic Forum – World Chinese Top Ten Young Entrepreneur Award']
            },
            {
                year: '2022',
                awards: ['Malaysia Top Achievers – Leadership Excellence In Automotive Service And Maintenance']
            },
            {
                year: '2023',
                awards: ['KNKV Viral award – Anugerah Khas (Content Creator)', 'ShangHai International Prestige Business Awards 2023 – Outstanding Entrepreneur of The Year']
            },
        ]
    },
]

    return (
        <div ref={targetRef} style={{position: 'relative'}}>
            <img src={IconArrowBlueTL} alt='Icon Arrow' className={classes.arrowImg}/>
            <Container size={'xl'} className={classes.container}>
                
                <Text align='center' sx={{fontFamily: 'Magistral', fontWeight: 400, color: '#3547F8'}} size={40}>{t('s7_title_01')} <span style={{fontWeight: 700, color: '#80FB41'}}>{t('s7_title_02')}</span></Text>
                <Space h={50}/>
                <Grid gutter={'xl'} justify="center">
                    {TEAM_MEMBER.map((data, index)=>(
                        <Grid.Col span={12} xs={12} sm={6} md={4} lg={4} key={data.name+index}>
                            <div style={{position: 'relative', display: 'flex', flexDirection: 'column', justifyContent: 'flex-end', alignItems: 'center', height: 450, borderTopRightRadius: 30, overflow: 'hidden', backgroundColor: '#E6E6EF',}}>
                                <img src={data.profile} className={classes.profileImg} alt={data.name}/>
                                <div className={classes.achievementsContainer}>
                                    <ScrollArea h={450}>
                                    <div style={{padding: 30, paddingRight: 35, paddingLeft: 35}}>
                                        <Text size={15} fw={700} color='#00145B'>{t('s7_achievements')}</Text>
                                        <Space h={20}/>
                                        {data.achievements.map((data, index)=>(
                                            <div style={{display: 'flex', }} key={data.year+index}>
                                                <div style={{width: '30%'}}>
                                                    <Text size={14} fw={500} color='#00145B'>{data.year}</Text>
                                                </div>
                                                <div style={{width: '70%'}}>
                                                {data.awards.map((data, index)=>(
                                                    <div key={index}>
                                                        <Text size={14} fw={500} color='#00145B'>{data}</Text>
                                                        <Space h={10}/>
                                                    </div>
                                                ))}
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                    </ScrollArea>
                                </div>
                            </div>
                            <Space h={10}/>
                            <Group>
                                <Text size={15} fw={700} color='#00145B'>{data.name}</Text>
                                <Text size={15} fw={600} color='#99a1bd'>{data.position}</Text>
                            </Group>
                        </Grid.Col>
                    ))}
                </Grid>
            </Container>
        </div>
    )
}
