import { createStyles, Container, Group, Anchor, Text, rem, Divider } from '@mantine/core';
import { Link } from 'react-router-dom';
// import { MantineLogo } from '@mantine/ds';
// import classes from './FooterSimple.module.css';

import { IconBrandInstagram, IconBrandFacebook, IconBrandTiktok, IconBrandLinkedin } from '@tabler/icons-react';

import IconGooglePlay from '../../assets/icons/IconGooglePlay.png'
import IconAppStore from '../../assets/icons/IconAppStore.png'

import { APP_STORE_URL, PLAY_STORE_URL, INSTAGRAM_URL, FACEBOOK_URL, TIKTOK_URL, LINKEDIN_URL } from '../../utils/Contants';

import { TranslateText as t } from '../Localization/translations';

const useStyles = createStyles((theme) => ({
    footer: {
        //marginTop: rem(120),
        // borderTop: rem(1px) solid light-dark(var(--mantine-color-gray-2), var(--mantine-color-dark-5));
        backgroundColor: '#0A1556',
    },
      
    inner: {
        paddingTop: rem(35),
        paddingBottom: rem(35),
        // @media (max-width: $mantine-breakpoint-xs) {
        //   flex-direction: column;
        // }
    },

    flexRow: {
        display: 'flex',
        justifyContent: 'space-between',
        // alignItems: 'center',
        [theme.fn.smallerThan('sm')]: {
            flexDirection: 'column',
            // alignItems: 'center',
        },
    },

    groupLeft: {
        display: 'flex',
        flexDirection: 'column',
        paddingBottom: rem(50),
        [theme.fn.smallerThan('md')]: {
            paddingBottom: rem(25),
        },
    },

    groupRight: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-end',
        paddingBottom: rem(50),
        [theme.fn.smallerThan('md')]: {
            alignItems: 'flex-start',
            paddingBottom: rem(50),
        },
    },

      
    links: {
        // @media (max-width: $mantine-breakpoint-xs) {
        //   margin-top: var(--mantine-spacing-md);
        // }
        [theme.fn.smallerThan('md')]: {
            paddingBottom: rem(25),
        },
    },

    downloadBtn: {
        width: 178,
        cursor: 'pointer',

        [theme.fn.smallerThan('xs')]: {
            width: '47%',
        },
    },

    socialBtn: {
        width: 53,
        height: 53,
        borderRadius: 53,
        backgroundColor: '#FFFFFF',
        cursor: 'pointer',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },

    flexRowReverse: {
        display: 'flex',
        justifyContent: 'space-between',
        // alignItems: 'center',
        [theme.fn.smallerThan('md')]: {
            flexDirection: 'column-reverse',
            alignItems: 'center',
        },
    },
}))


export default function XTENZFooter() {
    const { classes } = useStyles();

    const links = [
        { link: '/terms-conditions', label: t('terms_conditions') },
        { link: '/refund-policy', label: t('refund_policy') },
        { link: '/privacy-policy', label: t('privacy_policy') },
    ];

    const items = links.map((link, index) => (
        <div key={link.label} style={{display: 'flex',}}>
            <Anchor
                component={Link}
                to={link.link}
                c="#FFFFFF"
                size={12}
            >
            {link.label}
            </Anchor>
            {index+1 < links.length ?
                <Divider orientation="vertical" ml={'xs'} />
            : 
                null
            }
        </div>
    ));

    return (
        <div className={classes.footer}>
        <Container className={classes.inner} size={'xl'}>
                <div className={classes.flexRow}>
                    <div className={classes.groupLeft}>
                        <Text size={20} color='#FFFFFF' fw={600} pb={14}>XTENZ APP</Text>
                        <Group spacing="sm">
                            <a href={PLAY_STORE_URL} target='_blank' rel='noreferrer'>
                                <img src={IconGooglePlay} className={classes.downloadBtn} alt='Get it on Google Play'/>
                            </a>
                            <a href={APP_STORE_URL} target='_blank' rel='noreferrer'>
                                <img src={IconAppStore} className={classes.downloadBtn} alt='Get it on App Store'/>
                            </a>
                        </Group>
                    </div>
                    <div className={classes.groupRight}>
                        <Text size={20} color='#FFFFFF' fw={600} pb={14}>{t('connect_with_us')}</Text>
                        <Group>
                            <a href={INSTAGRAM_URL} target='_blank' rel='noreferrer'>
                                <div className={classes.socialBtn}>
                                    <IconBrandInstagram size={30} color='#0A1556'/>
                                </div>
                            </a>
                            <a href={FACEBOOK_URL} target='_blank' rel='noreferrer'>
                                <div className={classes.socialBtn}>
                                    <IconBrandFacebook size={30} color='#0A1556'/>
                                </div>
                            </a>
                            <a href={TIKTOK_URL} target='_blank' rel='noreferrer'>
                                <div className={classes.socialBtn}>
                                    <IconBrandTiktok size={30} color='#0A1556'/>
                                </div>
                            </a>
                            <a href={LINKEDIN_URL} target='_blank' rel='noreferrer'>
                                <div className={classes.socialBtn}>
                                    <IconBrandLinkedin size={30} color='#0A1556'/>
                                </div>
                            </a>
                        </Group>
                        
                    </div>
                </div>
                <div className={classes.flexRowReverse}>
                    <Text size={12} color='#FFFFFF' align='center'>© 2023 Xtenz Warranty Sdn Bhd (Co.Reg.No.: 201801008511 (1270525-K)). All Rights Reserved.</Text>
                    <Group className={classes.links} spacing="xs">{items}</Group>
                </div>
        </Container>
        </div>
    );
}