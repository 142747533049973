import { useEffect, useContext } from 'react';
import {
    createStyles,
    Header,
    Container,
    Group,
    Burger,
    Paper,
    Transition,
    rem,
    UnstyledButton,
    Text,
} from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { IconShoppingBag } from '@tabler/icons-react';

import XTENZLogo from '../../assets/logo/LogoLight.png'

import { HEADER_HEIGHT, PURCHASE_URL } from '../../utils/Contants';

import { AppContext } from '../Context/AppContext';

import { TranslateText as t } from '../Localization/translations';
//   import { useIsInViewport } from '../Hooks/useIsInViewport';
  
const useStyles = createStyles((theme) => ({
    root: {
        //position: 'relative',
        zIndex: 999,
        //   backgroundColor: 'rgba(0,0,0,0.2)',
        backdropFilter: 'blur(8px)',

        transition: 'background-color 0.25s ease',
        boxShadow: '0px 1px 4px 0px rgba(0, 0, 0, 0.15);'
    },
  
    dropdown: {
        position: 'absolute',
        top: HEADER_HEIGHT,
        left: 0,
        right: 0,
        zIndex: 999,
        borderTopRightRadius: 0,
        borderTopLeftRadius: 0,
        borderTopWidth: 0,
        overflow: 'hidden',
        height: `calc(100lvh - ${rem(HEADER_HEIGHT)})`,
        //   backgroundColor: 'rgba(255,255,255,0.5)',
        backdropFilter: 'blur(8px)',
    
        [theme.fn.largerThan('sm')]: {
            display: 'none',
        },
    },
  
    header: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        height: '100%',
    },
  
    links: {
        [theme.fn.smallerThan('md')]: {
            display: 'none',
        },
    },
  
    burger: {
        [theme.fn.largerThan('md')]: {
            display: 'none',
        },
    },
  
    link: {
        display: 'block',
        lineHeight: 1,
        padding: `${rem(8)} ${rem(20)}`,
            //   borderRadius: theme.radius.sm,
        textDecoration: 'none',
        color: theme.colors.brand[0],
        fontSize: 14,
        fontWeight: 500,
        cursor: 'pointer',
        textTransform: 'uppercase',
    
        '&:hover': {
            color: theme.colors.brandSecondary[0],
            //backgroundColor: 'rgba(125,125,125,0.2)',
            // borderWidth: 0,
            // borderBottomWidth: 1,
            
            // borderBlockStyle: 'dashed',
            // borderColor: theme.colors.brand[0]
        },
    
        [theme.fn.smallerThan('sm')]: {
            borderRadius: 0,
            fontSize: theme.fontSizes.md,
            padding: `${rem(18)} ${rem(16)}`,
        },
    
    },
  
    linkActive: {
        '&, &:hover': {
            // WebkitTextStroke: `0.5px ${theme.colors.brand[0]}`,
            // textShadow: `0px 1px 4px ${theme.colors.brand[0]}`, 
            fontWeight: 700,
            color: theme.colors.brandSecondary[0],
            // borderWidth: 0,
            // borderBottomWidth: 1,
            // borderBlockStyle: 'solid',
            // borderColor: theme.colors.brand[0]
        },
    },
  
    logo: {
        //   animation: `${glow} 8s linear infinite`,
        width: 145,
    },
  
    dropdownActive: {
        //   backgroundColor: 'rgba(0,0,0,0.5)',
        backdropFilter: 'blur(8px)',
    },

    languageBtn: {
        display: 'block',
        lineHeight: 1,
        // padding: `${rem(8)} ${rem(20)}`,
          //   borderRadius: theme.radius.sm,
        textDecoration: 'none',
        color: theme.colors.brand[0],
        fontSize: 14,
        fontWeight: 500,
        cursor: 'pointer',
    
        '&:hover': {
          color: theme.colors.brandSecondary[0],
          //backgroundColor: 'rgba(125,125,125,0.2)',
          // borderWidth: 0,
          // borderBottomWidth: 1,
          
          // borderBlockStyle: 'dashed',
          // borderColor: theme.colors.brand[0]
        },
    
        [theme.fn.smallerThan('sm')]: {
          borderRadius: 0,
          fontSize: theme.fontSizes.md,
          padding: `${rem(18)} ${rem(16)}`,
        },
    },

    languageBtnActive: {
        '&, &:hover': {
            // fontWeight: 700,
            textDecoration: 'underline',
            textDecorationThickness: 3,
            textDecorationColor: theme.colors.brandSecondary[0],
            // color: theme.colors.brandSecondary[0],
        },
    },

}));
  
// const links = [
//     { label: 'HOME', link: '/#home' },
//     { label: 'TESTIMONIAL', link: '/#testimonial' },
//     { label: 'PRODUCTS', link: '/#products' },
//     { label: 'WORKSHOPS', link: '/workshop' },
//     { label: 'TEAM', link: '/#team' },
//     { label: 'CONTACT US', link: '/#contact' },
// ]

const SCROLL_DELAY = 200;

export default function XTENZHeader({ homeScrollIntoView, reviewScrollIntoView, productsScrollIntoView, teamScrollIntoView, contactScrollIntoView }) {
    const { locale, changeLanguage } = useContext(AppContext)
    const [opened, { toggle, close }] = useDisclosure(false);
    const { classes, cx } = useStyles();
    const location = useLocation();
    const navigate = useNavigate();

    // const homeIsInViewport = useIsInViewport(homeScrollIntoView.targetRef);
    // const reviewIsInViewport = useIsInViewport(reviewScrollIntoView.targetRef);
    // const productsIsInViewport = useIsInViewport(productsScrollIntoView.targetRef);
    // const teamIsInViewport = useIsInViewport(teamScrollIntoView.targetRef);
    // const contactIsInViewport = useIsInViewport(contactScrollIntoView.targetRef);

    useEffect(()=>{
        if(`/${location.pathname.split("/")[1]}` === '/'){
            if(location.hash === '#home'){
                setTimeout(function () {
                    homeScrollIntoView.scrollIntoView()
                }, SCROLL_DELAY)
            }
            if(location.hash === '#testimonial'){
                setTimeout(function () {
                    reviewScrollIntoView.scrollIntoView()
                }, SCROLL_DELAY)
            }
            if(location.hash === '#products'){
                setTimeout(function () {
                    productsScrollIntoView.scrollIntoView()
                }, SCROLL_DELAY)
            }
            if(location.hash === '#team'){
                setTimeout(function () {
                    teamScrollIntoView.scrollIntoView()
                }, SCROLL_DELAY)
            }
            if(location.hash === '#contact'){
                setTimeout(function () {
                    contactScrollIntoView.scrollIntoView()
                }, SCROLL_DELAY)
            }
        }
    },[location.pathname, location.hash, homeScrollIntoView, reviewScrollIntoView, productsScrollIntoView, teamScrollIntoView, contactScrollIntoView])
    
    
  
    // const items = links.map((link) => (
    //     <Link
    //         key={link.label}
    //         to={link.link}
    //         className={cx(classes.link, { [classes.linkActive]: link.link === `/${location.pathname.split("/")[1]}` })}
    //         onClick={() => {
    //             close();
    //         }}
    //     >
    //         {link.label}
    //     </Link>
    // ));
  
    return (
        <Header height={HEADER_HEIGHT} className={cx(classes.root, { [classes.dropdownActive]: opened === true })} withBorder={false}>
            <Container className={classes.header} size={'xl'}>
            <UnstyledButton onClick={()=>navigate('/')}>
                <img src={XTENZLogo} className={classes.logo} alt='ZTR Solution'/>
            </UnstyledButton>
            
            <Group spacing={0} className={classes.links}>
                {`/${location.pathname.split("/")[1]}` === '/' ?
                <>
                    <Link onClick={() => {homeScrollIntoView.scrollIntoView();close();}} className={cx(classes.link, { [classes.linkActive]: false })}>
                        {t('home')}
                    </Link>
                    <Link onClick={() => {reviewScrollIntoView.scrollIntoView();close();}} className={cx(classes.link, { [classes.linkActive]: false })}>
                        {t('testimonial')}
                    </Link>
                    <Link onClick={() => {productsScrollIntoView.scrollIntoView();close();}} className={cx(classes.link, { [classes.linkActive]: false })}>
                        {t('products')}
                    </Link>
                    <Link
                        to={'/workshop'}
                        className={cx(classes.link, { [classes.linkActive]: false })}
                        onClick={() => {close();}}
                    >
                        {t('workshops')}
                    </Link>
                    <Link onClick={() => {teamScrollIntoView.scrollIntoView();close();}} className={cx(classes.link, { [classes.linkActive]: false })}>
                        {t('team')}
                    </Link>
                    <Link onClick={() => {contactScrollIntoView.scrollIntoView();close();}} className={cx(classes.link, { [classes.linkActive]: false })}>
                        {t('contact_us')}
                    </Link>
                </>
                : 
                <>
                    <Link
                        to={'/#home'}
                        className={cx(classes.link, { [classes.linkActive]: false })}
                        onClick={() => {close();}}
                    >
                        {t('home')}
                    </Link>
                    <Link
                        to={'/#testimonial'}
                        className={cx(classes.link, { [classes.linkActive]: false })}
                        onClick={() => {close();}}
                    >
                        {t('testimonial')}
                    </Link>
                    <Link
                        to={'/#products'}
                        className={cx(classes.link, { [classes.linkActive]: false })}
                        onClick={() => {close();}}
                    >
                        {t('products')}
                    </Link>
                    <Link
                        to={'/workshop'}
                        className={cx(classes.link, { [classes.linkActive]: false })}
                        onClick={() => {close();}}
                    >
                        {t('workshops')}
                    </Link>
                    <Link
                        to={'/#team'}
                        className={cx(classes.link, { [classes.linkActive]: false })}
                        onClick={() => {close();}}
                    >
                        {t('team')}
                    </Link>
                    <Link
                        to={'/#contact'}
                        className={cx(classes.link, { [classes.linkActive]: false })}
                        onClick={() => {close();}}
                    >
                        {t('contact_us')}
                    </Link>
                </>
                }
                {/* <Link 
                    to={'/register-warranty'} 
                    className={cx(classes.link, { [classes.linkActive]: false })}
                    onClick={() => {close();}}
                >
                    <IconShoppingBag size={15} />
                </Link> */}
                <Group spacing={0} align='center' ml={20}>
                    <UnstyledButton onClick={() => {changeLanguage('en');close();}}>
                        <div className={cx(classes.languageBtn, { [classes.languageBtnActive]: locale === 'en' })}>
                            <Text>EN</Text>
                        </div>
                    </UnstyledButton>
                    <div style={{width: 1, height: 12, backgroundColor: '#00145B', marginRight: 10, marginLeft: 10}}/>
                    <UnstyledButton onClick={() => {changeLanguage('bm');close();}}>
                        <div className={cx(classes.languageBtn, { [classes.languageBtnActive]: locale === 'bm' })}>
                            <Text>BM</Text>
                        </div>
                    </UnstyledButton>
                </Group>
            </Group>
    
            <Burger opened={opened} onClick={toggle} className={classes.burger} size="sm" />
    
            <Transition transition="pop-top-right" duration={200} mounted={opened}>
                {(styles) => (
                <Paper className={classes.dropdown} style={styles}>
                    {`/${location.pathname.split("/")[1]}` === '/' ?
                    <>
                        <Link onClick={() => {homeScrollIntoView.scrollIntoView();close();}} className={cx(classes.link, { [classes.linkActive]: false })}>
                            {t('home')}
                        </Link>
                        <Link onClick={() => {reviewScrollIntoView.scrollIntoView();close();}} className={cx(classes.link, { [classes.linkActive]: false })}>
                            {t('testimonial')}
                        </Link>
                        <Link onClick={() => {productsScrollIntoView.scrollIntoView();close();}} className={cx(classes.link, { [classes.linkActive]: false })}>
                            {t('products')}
                        </Link>
                        <Link
                            to={'/workshop'}
                            className={cx(classes.link, { [classes.linkActive]: false })}
                            onClick={() => {close();}}
                        >
                            {t('workshops')}
                        </Link>
                        <Link onClick={() => {teamScrollIntoView.scrollIntoView();close();}} className={cx(classes.link, { [classes.linkActive]: false })}>
                            {t('team')}
                        </Link>
                        <Link onClick={() => {contactScrollIntoView.scrollIntoView();close();}} className={cx(classes.link, { [classes.linkActive]: false })}>
                            {t('contact_us')}
                        </Link>
                    </>
                    : 
                    <>
                        <Link
                            to={'/#home'}
                            className={cx(classes.link, { [classes.linkActive]: false })}
                            onClick={() => {close();}}
                        >
                            {t('home')}
                        </Link>
                        <Link
                            to={'/#testimonial'}
                            className={cx(classes.link, { [classes.linkActive]: false })}
                            onClick={() => {close();}}
                        >
                            {t('testimonial')}
                        </Link>
                        <Link
                            to={'/#products'}
                            className={cx(classes.link, { [classes.linkActive]: false })}
                            onClick={() => {close();}}
                        >
                            {t('products')}
                        </Link>
                        <Link
                            to={'/workshop'}
                            className={cx(classes.link, { [classes.linkActive]: false })}
                            onClick={() => {close();}}
                        >
                            {t('workshops')}
                        </Link>
                        <Link
                            to={'/#team'}
                            className={cx(classes.link, { [classes.linkActive]: false })}
                            onClick={() => {close();}}
                        >
                            {t('team')}
                        </Link>
                        <Link
                            to={'/#contact'}
                            className={cx(classes.link, { [classes.linkActive]: false })}
                            onClick={() => {close();}}
                        >
                            {t('contact_us')}
                        </Link>
                    </>
                    }
                    {/* <a target='_blank' href={PURCHASE_URL} rel={'noreferrer'} onClick={() => {close();}} className={cx(classes.link, { [classes.linkActive]: false })}>
                        <IconShoppingBag size={15} />
                    </a> */}
                    <Group spacing={0} align='center' >
                        <UnstyledButton onClick={() => {changeLanguage('en');close();}}>
                            <div className={cx(classes.languageBtn, { [classes.languageBtnActive]: locale === 'en' })}>
                                <Text>EN</Text>
                            </div>
                        </UnstyledButton>
                        <div style={{width: 1, height: 12, backgroundColor: '#00145B', marginRight: 5, marginLeft: 5}}/>
                        <UnstyledButton onClick={() => {changeLanguage('bm');close();}}>
                            <div className={cx(classes.languageBtn, { [classes.languageBtnActive]: locale === 'bm' })}>
                                <Text>BM</Text>
                            </div>
                        </UnstyledButton>
                    </Group>
                </Paper>
                )}
            </Transition>
            </Container>
        </Header>
    );
}